import styled from "styled-components";

const StyledCategories = styled.div`
	.sb-categories-list {
		&__category {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&__reorder {
				margin-right: 6px;
				cursor: grab;
			}

			&__label {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 140px;
				text-transform: uppercase;
				margin: 0;
			}

			&__edit {
				margin-left: auto;
				cursor: pointer;
				width: 20px;
				height: 20px;
				color: ${({ theme }) => theme.colors.trout};
			}
		}
	}

	.ant-card {
		.ant-card-head {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0 16px;
			padding: 0;
			border-bottom: 1px solid ${({ theme }) => theme.colors.iron};

			.ant-card-head-title {
				padding: 0;

				p {
					margin: 0;
				}
			}
		}

		.ant-card-body {
			padding: 16px;
			display: flex;
			flex-direction: column;
			gap: 18px;
		}

		.ant-card-actions {
			border-top: 1px solid ${({ theme }) => theme.colors.iron};
			margin: 0 16px;
			padding: 0;

			> li {
				margin: 16px 0;
			}
		}
	}
`;

export default StyledCategories;
