// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import moment from "moment";
import React, { useState } from "react";
import { DatePicker } from "antd";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { MembersSubscribersDonutChart } from "components/Members/MembersSubscribersDonutChart";
import { MembersSubscribersStackedBarChart } from "components/Members/MembersSubscribersStackedBarChart";
import { MembersTable } from "components/Members/MembersTable";
import { Heading3 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------
import StyledCategories from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Members = () => {
	const { RangePicker } = DatePicker;
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf("month"),
		endDate: moment().endOf("month"),
	});

	const onDateRangeChange = (dates) => {
		if (dates) {
			setDateRange({
				startDate: dates[0],
				endDate: dates[1],
			});
		}
	};

	return (
		<StyledCategories className="sb-members-page">
			<div className="sb-members-page__subscribers">
				<div className="sb-members-page__subscribers__heading">
					<Heading3>Subscribers</Heading3>
					<div className="sb-members-page__subscribers__heading__date-filter">
						<RangePicker
							defaultValue={[dateRange.endDate, dateRange.startDate]}
							allowClear={false}
							popupStyle={{ zIndex: 99999 }}
							ranges={{
								"Last Month": [
									moment().startOf("month").subtract(1, "months"),
									moment().startOf("month").subtract(1, "days"),
								],
								"This Month": [moment().startOf("month"), moment().endOf("month")],
								"Last Year": [
									moment().startOf("year").subtract(1, "years"),
									moment().startOf("year").subtract(1, "days"),
								],
								"This Year": [moment().startOf("year"), moment().endOf("year")],
							}}
							onChange={onDateRangeChange}
						/>
					</div>
				</div>
				<div className="sb-members-page__subscribers__charts">
					<div className="sb-members-page__subscribers__charts__donut">
						<MembersSubscribersDonutChart dateRange={dateRange} />
					</div>
					<div className="sb-members-page__subscribers__charts__stacked-bar-chart">
						<MembersSubscribersStackedBarChart dateRange={dateRange} />
					</div>
				</div>
				<div className="sb-members-page__subscribers__table">
					<MembersTable dateRange={dateRange} />
				</div>
			</div>
		</StyledCategories>
	);
};

export default Members;
