import styled from "styled-components";

const StyledMembershipSelectionCard = styled.div`
	max-width: 250px;
	flex: 1;
	border-radius: 5px;

	.ant-card-body {
		padding: 18px;
		height: 100%;
	}

	.sb-membership-selection-card {
		height: 100%;
		border: 1px solid ${({ theme }) => theme.colors.iron};
		background-color: ${({ theme }) => theme.colors.iron};
		border-radius: 5px;

		&__circle-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 24px;
		}

		&__icon-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 96px;
			height: 96px;
			background-color: ${({ theme }) => theme.colors.athensGray};
			border-radius: 50%;
			color: ${({ theme }) => theme.colors.trout};
			margin: 0 auto 14px auto;
		}

		&__title,
		&__subtitle,
		&__description {
			text-align: center;
		}

		&__title {
			margin-bottom: 18px;
		}

		&__description {
			margin-top: 22px;
		}

		&--selected {
			border: 1px solid ${({ theme }) => theme.colors.coral};
			background-color: ${({ theme }) => theme.colors.white};
		}
	}
`;

export default StyledMembershipSelectionCard;
