import styled from "styled-components";

const StyledBrandingForm = styled.div`
	position: relative;

	.sb-branding-form {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.sb-loading-mask {
		width: calc(100% + 32px);
		height: calc(100% + 32px);
		top: -16px;
		left: -16px;
	}
`;

export default StyledBrandingForm;
