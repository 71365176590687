// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import YourInformationForm from "./YourInformationForm";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const YourInformation = () => {
	return (
		<div className="sb-creator-creation">
			<div className="sb-creator-creation__inner">
				<div className="sb-creator-creation__inner__title">
					<h1 className="sb-creator-creation__inner__title__title">Tell us about yourself</h1>
					<p className="sb-creator-creation__inner__title__subtitle">
						Provide some contact details so we can better tailor your brand.
					</p>
				</div>
				<div className="sb-creator-creation__inner__header">
					<p className="sb-creator-creation__inner__header__text">YOUR INFORMATION</p>
				</div>
				<YourInformationForm />
			</div>
		</div>
	);
};

export default YourInformation;
