import styled from "styled-components";

const StyledReleaseScheduleForm = styled.div`
	padding: 0 32px;
	.sb-release-form-input-group {
		display: flex;
		gap: 16px;
		width: 100%;
	}

	.sb-release-schedule-form {
		&__upload {
			margin: 32px 0;
			&__input {
				margin-bottom: 8px;
			}
		}
		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
			&__button {
				margin: 8px 0;
			}
		}
		&__row {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			gap: 8px;
			&__button {
				margin: 8px 0;
			}
		}
		&__notice {
			display: flex;
			align-items: start;
			justify-content: center;
			flex-direction: row;
			padding: 16px;
			padding-bottom: 0px;
			gap: 8px;
			background: rgba(255, 134, 92, 0.12);
			border-radius: 8px;
		}
		&__actions {
			width: 100%;
			display: flex;
			flex-grow: 1;
			align-items: start;
			justify-content: space-between;
			flex-direction: row;
			gap: 18px;
		}
	}
`;

export default StyledReleaseScheduleForm;

