// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledEarningsCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EarningsCard = ({ totalEarnings = 0, pendingBalance = 0 }) => {
	const formatAmount = (amount) => (amount / 100).toFixed(2);

	return (
		<StyledEarningsCard>
			<Card className="sb-earnings-card">
				<div className="sb-earnings-card__bottom-section">
					<h1>Your Lifetime Earnings: ${formatAmount(totalEarnings)}</h1>
				</div>
				<div className="sb-earnings-card__bottom-section">
					<h1>Your Pending Balance: ${formatAmount(pendingBalance)}</h1>
				</div>
			</Card>
		</StyledEarningsCard>
	);
};

export default EarningsCard;

