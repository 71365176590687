// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Tags } from "components/Common/Tags";
import { Heading4 } from "themes/default/_typography";
import StyledWorkoutCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutCard = ({ title, categories, headerImage }) => {
	return (
		<StyledWorkoutCard className="sb-workout-card" headerImage={headerImage}>
			<Card className="sb-workout-card__inner" hoverable>
				<div className="sb-workout-card__inner__header-section" />
				<div className="sb-workout-card__inner__body-section">
					<Heading4>{title}</Heading4>
					<div className="sb-workout-card__inner__body-section__categories">
						<Tags tags={categories.map(({ name }) => name)} />
					</div>
				</div>
			</Card>
		</StyledWorkoutCard>
	);
};

WorkoutCard.propTypes = {
	title: PropTypes.string.isRequired,
	headerImage: PropTypes.string,
	categories: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};

export default WorkoutCard;
