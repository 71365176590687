const updateAssetUploadProgress = (state, { payload: { percent, status } }) => {
	if (status === "START") {
		state.uploadProgress = 0;
	} else if (status === "ERROR") {
		state.uploadProgress = -1;
	} else if (status === "IN_PROGRESS") {
		state.uploadProgress = percent;
	} else if (status === "DONE") {
		state.uploadProgress = -1;
	}
};

export default updateAssetUploadProgress;
