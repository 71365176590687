export const secondsToTime = (seconds) => {
	let localSeconds = seconds;
	let hours = 0;
	let minutes = 0;

	if (localSeconds >= 3600) {
		hours = Math.floor(localSeconds / 3600);
		localSeconds = localSeconds - hours * 3600;
	}

	if (localSeconds >= 60) {
		minutes = Math.floor(localSeconds / 60);
		localSeconds = localSeconds - minutes * 60;
	}

	if (hours >= 0 && hours <= 9) {
		hours = `0${hours}`;
	}

	if (minutes >= 0 && minutes <= 9) {
		minutes = `0${minutes}`;
	}

	if (localSeconds >= 0 && localSeconds <= 9) {
		localSeconds = `0${localSeconds}`;
	}

	return `${hours}:${minutes}:${localSeconds}`;
};

export const convertFalsyToUndefined = (value) =>
	!value || value === "null" || value === "undefined" ? undefined : value;

export const formatNumber = (number, options = {}) =>
	new Intl.NumberFormat("en-US", options).format(parseFloat(number));

export const formatMoney = (value) =>
	formatNumber(value, {
		style: "currency",
		currency: "USD",
	});

export const hexToRGBA = (hex, opacity) => {
	let localHex = hex;

	if (hex.charAt(0) === "#") {
		localHex = localHex.substring(1);
	}

	localHex = `0x${localHex}`;

	const r = (localHex >> 16) & 0xff;
	const g = (localHex >> 8) & 0xff;
	const b = localHex & 0xff;

	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getVideoMetadata = (file) =>
	new Promise((resolve, reject) => {
		try {
			let video = document.createElement("video");
			video.preload = "metadata";

			video.onloadedmetadata = function () {
				resolve(this);
			};

			video.onerror = function () {
				reject("Invalid video. Please select a video file.");
			};

			video.src = window.URL.createObjectURL(file);
		} catch (e) {
			reject(e);
		}
	});
