import styled from "styled-components";

const StyledLegendCard = styled.div`
	max-width: 800px;
	min-width: 220px;
	margin: 16px auto;

	.sb-legend-card {
		&__indicator {
			margin-top: 8px;

			&__circle {
				width: 8px;
				height: 8px;
				border-radius: 50%;
			}
		}

		&__data {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.ant-card-body {
			background: ${({ theme }) => theme.colors.athensGray};
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
			border-radius: 4px;
			padding: 24px 16px;
			display: flex;
			align-items: flex-start;
			cursor: pointer;
			gap: 12px;
		}
	}
`;

export default StyledLegendCard;
