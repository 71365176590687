import styled from "styled-components";

const StyledSelectedColorCard = styled.div`
	background-color: ${(props) => (props.selectedColor ? props.selectedColor : props.theme.colors.black)};
	width: 168px;
	height: 85px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.colors.iron};

	p {
		text-shadow: 1px 1px black;
	}
`;

export default StyledSelectedColorCard;
