import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const categoriesSlice = createSlice({
	name: "categories",
	initialState,
	reducers,
});

const { actions, reducer } = categoriesSlice;

export const { updateCategories, getCategories, updateCategoriesErrors, updateCategoriesLoading, deleteCategory } =
	actions;
export default reducer;
