import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers,
});

const { actions, reducer } = authSlice;

export const { updateAuthErrors, updateAuthToken, updateAuthUser, updateLoginLoading, autoLogin, updateUserId } =
	actions;
export default reducer;

