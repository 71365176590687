import styled from "styled-components";

const StyledColorPickerCard = styled.div`
	max-width: 650px;

	.sb-color-picker-card {
		padding: 8px;
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 5px;
		min-height: 100px;
		display: flex;
		align-items: center;

		.ant-card-body {
			padding: 0;
			width: 100%;
			display: flex;
			align-items: stretch;
			justify-content: space-between;
		}

		&__left {
			width: calc(100% - 220px);
			padding: 8px 50px 6px 8px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&__title,
			&__subtitle {
				margin-bottom: 8px;
				text-align: center;
			}

			&__current-color {
				margin-top: 16px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&__right {
			width: 220px;
		}
	}

	@media (max-width: ${({ theme }) => theme.responsive.mobile}) {
		&__card {
			.ant-card-body {
				flex-direction: column-reverse;
			}

			&__left {
				width: 100%;
				padding: 16px;

				&__title,
				&__subtitle,
				&__description {
					text-align: center;
				}
			}

			&__right {
				width: 100%;
				margin-bottom: 16px;
				height: 310px;
			}
		}
	}
`;

export default StyledColorPickerCard;
