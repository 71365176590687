import React from "react";
const MusicIcon = (params) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18 10.24V11.5C18 11.6326 17.9473 11.7598 17.8536 11.8536C17.7598 11.9473 17.6326 12 17.5 12H16.5C16.3674 12 16.2402 11.9473 16.1464 11.8536C16.0527 11.7598 16 11.6326 16 11.5V11.23C15.9881 10.7374 15.8422 10.2574 15.5779 9.84159C15.3136 9.42576 14.9409 9.08985 14.5 8.87C14.32 8.77 14.16 8.65 14 8.54V17.54C13.9182 18.5253 13.4536 19.4393 12.7059 20.086C11.9581 20.7328 10.9868 21.0609 10 21C9.00681 21.0605 8.02982 20.727 7.28098 20.0717C6.53214 19.4165 6.07186 18.4924 6 17.5C6.07186 16.5076 6.53214 15.5835 7.28098 14.9283C8.02982 14.273 9.00681 13.9395 10 14C10.6966 13.9985 11.383 14.1667 12 14.49V3.5C12 3.36739 12.0527 3.24021 12.1464 3.14645C12.2402 3.05268 12.3674 3 12.5 3H13.5C13.6326 3 13.7598 3.05268 13.8536 3.14645C13.9473 3.24021 14 3.36739 14 3.5V3.67C13.9898 4.16645 14.1181 4.65592 14.3708 5.08342C14.6234 5.51091 14.9902 5.8595 15.43 6.09C16.2031 6.4728 16.8536 7.06442 17.3078 7.79787C17.762 8.53132 18.0018 9.3773 18 10.24Z"
			fill={params?.color ? params.color : "black"}
		/>
	</svg>
);

export default MusicIcon;

