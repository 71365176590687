import { useEffect, useState } from "react";

const LOGIN_STATE_KEY = "loginInProgress";

export function setLoginInProgress(status) {
	localStorage.setItem(LOGIN_STATE_KEY, status ? "true" : "false");
}

export function useLoginProgress() {
	const isLoginInProgress = localStorage.getItem(LOGIN_STATE_KEY) === "true";
	const [loginProgress, setLoginProgress] = useState(isLoginInProgress);
	useEffect(() => {
		function login(event) {
			if (event.key === LOGIN_STATE_KEY) {
				setLoginProgress(event.newValue === "true");
			}
		}
		window.addEventListener("storage", login);
		return () => {
			window.removeEventListener("storage", login);
		};
	}, []);
	return {
		inProgress: loginProgress,
	};
}

