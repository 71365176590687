import styled from "styled-components";

const StyledMemeberPayments = styled.div`
	width: 100%;
	max-width: 900px;

	.sb-member-payments {
		&__home-icon {
			height: 32px;
			width: 32px;
			margin-bottom: 40px;
			cursor: pointer;
		}

		&__header {
			&__image {
				max-width: 600px;
				max-height: 140px;
				margin-bottom: 60px;
			}

			&__title {
				margin-bottom: 16px;
			}

			&__subtitle {
				margin-bottom: 42px;
			}
		}

		&__payment-section {
			margin-top: 32px;

			&__payment-method {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&__dashboard {
			margin-top: 60px;
			width: 100%;
			text-align: center;
		}

		&__logout {
			margin-top: 16px;
			width: 100%;
			text-align: center;
		}
	}
`;

export default StyledMemeberPayments;
