import React from "react";
import { PlayCircleFilled } from "@ant-design/icons";

import "./index.scss";
import { useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";

function PlayCircle({ playbackId }) {
	if (playbackId) {
		return <PlayCircleFilled />;
	}
	return null;
}
function VideoPreviewContainer({ record }) {
	let { gym } = useParams();
	const { data: gymData } = useGetDomainGymQuery(gym);

	const renderVideoPreview = () => {
		if (record && record.image_url) {
			return (
				<div
					style={{
						backgroundImage: `url('${record.image_url}')`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircle playbackId={record?.playback_id} />
				</div>
			);
		} else if (gymData && gymData.light_logo) {
			return (
				<div
					style={{
						backgroundImage: `url('${gymData?.light_logo}')`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircle playbackId={record?.playback_id} />
				</div>
			);
		} else if (gymData && gymData.dark_logo) {
			return (
				<div
					style={{
						backgroundImage: `url('${gymData?.dark_logo}')`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircle playbackId={record?.playback_id} />
				</div>
			);
		} else {
			return (
				<div
					style={{
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="video-preview-container"
				>
					<PlayCircle playbackId={record?.playback_id} />
				</div>
			);
		}
	};

	return renderVideoPreview();
}

export default VideoPreviewContainer;
