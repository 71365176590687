import React from "react";

const VideoVerticalIcon = (params) => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M33.3337 33.3333V6.66659C33.3337 5.78253 32.9825 4.93468 32.3573 4.30956C31.7322 3.68444 30.8844 3.33325 30.0003 3.33325H10.0003C9.11627 3.33325 8.26842 3.68444 7.6433 4.30956C7.01818 4.93468 6.66699 5.78253 6.66699 6.66659V33.3333C6.66699 34.2173 7.01818 35.0652 7.6433 35.6903C8.26842 36.3154 9.11627 36.6666 10.0003 36.6666H30.0003C30.8844 36.6666 31.7322 36.3154 32.3573 35.6903C32.9825 35.0652 33.3337 34.2173 33.3337 33.3333ZM25.1003 21.0333L18.617 25.3666C18.4291 25.4936 18.2103 25.567 17.9839 25.5792C17.7575 25.5913 17.532 25.5416 17.3317 25.4355C17.1314 25.3293 16.9637 25.1707 16.8466 24.9765C16.7295 24.7823 16.6674 24.56 16.667 24.3333V15.6666C16.6674 15.4399 16.7295 15.2175 16.8466 15.0233C16.9637 14.8292 17.1314 14.6705 17.3317 14.5644C17.532 14.4582 17.7575 14.4085 17.9839 14.4207C18.2103 14.4328 18.4291 14.5063 18.617 14.6333L25.1003 18.9666C25.2723 19.0786 25.4136 19.2318 25.5113 19.4123C25.6091 19.5927 25.6604 19.7947 25.6604 19.9999C25.6604 20.2052 25.6091 20.4071 25.5113 20.5876C25.4136 20.768 25.2723 20.9212 25.1003 21.0333Z"
			fill="currentColor"
		/>
	</svg>
);

export default VideoVerticalIcon;
