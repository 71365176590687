// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState } from "react";
import { Card } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { CategoryFormModal } from "components/Modals/CategoryFormModal";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { PlusIcon, PencilIcon } from "components/Icons";
import { Button } from "components/Common/Button";
import { Heading4, Paragraph1, Paragraph2 } from "themes/default/_typography";
import StyledCategories from "./styles";

// -----------------------------------------------------------------------------
// Importing selectors
// -----------------------------------------------------------------------------
import { useGetCategoriesQuery, useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const CategoriesComponent = () => {
	const [showCategoryFormModal, setShowCategoryFormModal] = useState(false);
	const [categoryToEdit, setCategoryToEdit] = useState(null);
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const categoriesResponse = useGetCategoriesQuery(domainGymResponse.data?.id, { skip: !domainGymResponse.data });
	const categories = [...(categoriesResponse.data?.categories || [])].sort((a, b) => {
		if (a?.name?.trim?.()?.toLocaleLowerCase?.() > b?.name?.name?.trim?.()?.toLocaleLowerCase?.()) {
			return 1;
		}
		if (a?.name?.trim?.()?.toLocaleLowerCase?.() < b?.name?.trim?.()?.toLocaleLowerCase?.()) {
			return -1;
		}
		return 0;
	});

	const handleCloseCategoryModal = (status) => {
		setShowCategoryFormModal(status);
		setCategoryToEdit(null);
	};

	const handleEditCategory = (category) => {
		setCategoryToEdit(category);
		setShowCategoryFormModal(true);
	};

	return (
		<StyledCategories className="sb-categories-list">
			<Card
				title={<Heading4>Categories</Heading4>}
				actions={[
					<Button
						className="primary-outlined"
						data-cy="new-category-button"
						onClick={() => setShowCategoryFormModal(true)}
					>
						<PlusIcon />
						<Paragraph2>New Category</Paragraph2>
					</Button>,
				]}
			>
				{categories && !categoriesResponse.isLoading ? (
					categories.map((category, index) => (
						<div className="sb-categories-list__category" key={`category-key-${index}`}>
							<Paragraph1 className="sb-categories-list__category__label">{category.name}</Paragraph1>
							<PencilIcon onClick={() => handleEditCategory(category)} className="sb-categories-list__category__edit" />
						</div>
					))
				) : (
					<div className="sb-categories-list__category">
						<Paragraph1 className="sb-categories-list__category__label">No category found</Paragraph1>
					</div>
				)}
			</Card>
			{showCategoryFormModal && (
				<CategoryFormModal
					open={showCategoryFormModal}
					gymId={domainGymId}
					categoryToEdit={categoryToEdit}
					setShowCreateCategoryModal={handleCloseCategoryModal}
				/>
			)}
		</StyledCategories>
	);
};

export default CategoriesComponent;
