import styled from "styled-components";

const StyledMembersSubscribersStackedBarChart = styled.div`
	position: relative;
	width: 100%;
	height: 274px;
	border-radius: 6px;
	padding: 16px 30px;
	border: 1px solid ${({ theme }) => theme.colors.iron};
	background-color: ${({ theme }) => theme.colors.white};
	height: 100%;

	.sb-members-subscribers-stacked-bar-chart {
	}
`;

export default StyledMembersSubscribersStackedBarChart;

