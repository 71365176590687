import styled from "styled-components";

const StyledProfile = styled.div`
	.sb-profile-container {
		&__body {
			background: ${({ theme }) => theme.colors.athensGray};
			width: 100%;
			min-height: calc(100vh - 400px);
			border-top-left-radius: 35px;
			border-top-right-radius: 35px;
			margin-top: -80px;
			padding: 68px 64px;

			&__title {
				max-width: 800px;
				margin: 50px auto 20px auto;
			}

			&__logout {
				width: 100%;
				max-width: 800px;
				margin: 48px auto;
				display: block;
			}
		}

		@media (max-width: ${({ theme }) => theme.responsive.mobile}) {
			&__body {
				padding: 32px;

				&__title {
					margin: 70px auto 20px auto;
					text-align: center;
				}
			}
		}
	}
`;

export default StyledProfile;
