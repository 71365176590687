import React from "react";

const ArrowRightIcon = (params) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M1.33331 8.33331V7.66664C1.33331 7.57824 1.36843 7.49345 1.43094 7.43094C1.49346 7.36843 1.57824 7.33331 1.66665 7.33331H12V5.99998C12.0009 5.93473 12.0209 5.8712 12.0576 5.81724C12.0943 5.76328 12.146 5.72128 12.2064 5.69643C12.2667 5.67159 12.333 5.665 12.397 5.67748C12.4611 5.68996 12.5201 5.72096 12.5666 5.76664L14.5666 7.76664C14.6281 7.83008 14.6625 7.91496 14.6625 8.00331C14.6625 8.09166 14.6281 8.17654 14.5666 8.23998L12.5666 10.24C12.5196 10.286 12.4601 10.3172 12.3954 10.3294C12.3308 10.3417 12.2639 10.3345 12.2033 10.3089C12.1427 10.2832 12.0911 10.2402 12.0549 10.1852C12.0187 10.1302 11.9996 10.0658 12 9.99998V8.66664H1.66665C1.57824 8.66664 1.49346 8.63152 1.43094 8.56901C1.36843 8.5065 1.33331 8.42172 1.33331 8.33331Z"
			fill="currentColor"
		/>
	</svg>
);

export default ArrowRightIcon;
