// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Select, Form } from "antd";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormInput from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormSelect = ({ name, label, placeholder, rules, options, className, dark, disabled }) => {
	const { Option } = Select;

	return (
		<StyledFormInput className={dark ? "sb-form-select__dark" : `${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules} required={false}>
				<Select placeholder={placeholder} disabled={disabled}>
					{options.map((o, i) => (
						<Option key={`form-select-${name}-option-${i}`} value={o.value}>
							{o.label}
						</Option>
					))}
				</Select>
			</Form.Item>
		</StyledFormInput>
	);
};

export default FormSelect;

