// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Spin } from "antd";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledLoadingMask from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const LoadingMask = () => {
	return (
		<StyledLoadingMask className="sb-loading-mask">
			<Spin />
		</StyledLoadingMask>
	);
};

export default LoadingMask;
