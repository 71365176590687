// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState } from "react";
import { Form, Button, TimePicker } from "antd";
// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormTimeSelect from "./styles";
import { ClockIcon } from "components/Icons/ClockIcon";
import moment from "moment";
import "./styles.scss";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const FormTimeSelect = ({ name, label, rules, className, disabled, form, ...rest }) => {
	const format12 = "h:mm A";
	const format24 = "HH:mm";

	const [value, setValue] = useState(moment("00:00:00", format24));
	const [open, setOpen] = useState(false);

	const onChange = (time) => {
		if (moment(time).isValid()) {
			setValue(moment(time, format24));
		} else {
			setValue(moment(form.getFieldValue(name), format24));
		}
	};

	const onOpenChange = (open) => {
		if (!open && form && moment(value).isValid()) {
			form.setFieldValue(name, moment(value, format24));
		} else {
			setValue(moment(form.getFieldValue(name), format24));
		}
	};

	return (
		<StyledFormTimeSelect className={`${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules} required={false}>
				<TimePicker
					open={open}
					disabled={disabled}
					use12Hours
					showNow={false}
					style={{ width: "100%", height: "46px", borderRadius: "100px" }}
					minuteStep={5}
					format={format12}
					value={value}
					onChange={onChange}
					suffixIcon={<ClockIcon />}
					onOpenChange={onOpenChange}
					onOk={() => setOpen(false)}
					onFocus={() => setOpen(true)} // Open on focus
					needConfirm
					onClick={() => setOpen(true)}
					popupClassName="picker"
					size="large"
					renderExtraFooter={() => (
						<Button style={{ minWidth: 100 }} onClick={() => setOpen(false)}>
							Cancel
						</Button>
					)}
					{...rest}
				/>
			</Form.Item>
		</StyledFormTimeSelect>
	);
};

export default FormTimeSelect;

