// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { FullLogoIcon } from "components/Icons/FullLogoIcon";

// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------
import StyledHeader from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Header = ({ actionsSection }) => {
	return (
		<StyledHeader className="sb-header">
			<div className="sb-header__logo-section">
				<FullLogoIcon />
			</div>
			<div className="sb-header__actions-section">{actionsSection}</div>
		</StyledHeader>
	);
};

export default Header;
