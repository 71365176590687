import { atom } from "recoil";

export const landingPageImageAtom = atom({
	key: "landingPageImage",
	default: null,
});

export const signUpPageImageAtom = atom({
	key: "signUpPageImage",
	default: null,
});
