import styled from "styled-components";
import AddPaymentMethodModal from "./AddPaymentMethodModal";

const StyledAddPaymentMethodModal = styled(AddPaymentMethodModal)`
	.ant-modal-title {
		margin-bottom: 22px;

		p {
			text-align: center;
		}
	}

	.ant-modal-content {
		padding: 48px 88px;

		.ant-modal-header {
			padding: 0;
			border: none;

			.ant-modal-title {
				p {
					text-align: center;
				}
			}
		}

		.ant-modal-body {
			padding: 16px 0 0 0;
		}

		.ant-modal-footer {
			padding: 0;
		}
	}
`;

export default StyledAddPaymentMethodModal;
