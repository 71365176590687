// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Span3 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormErrorTag from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormErrorTag = ({ text }) => {
	return (
		<StyledFormErrorTag className="sb-form-error-tag">
			<Span3 className="sb-form-error-tag__error">{text}</Span3>
		</StyledFormErrorTag>
	);
};

export default FormErrorTag;
