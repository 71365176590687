import React from "react";
import Ajax from "./utils/api";
import ajaxRoutes from "./utils/ajaxRoutes";
import moment from "moment";
import { useSetRecoilState } from "recoil";
import { initializingAtom, userAtom, categoriesAtom } from "./globalAtoms";
import { jwtDecode } from "jwt-decode";

function useInit() {
	const setUser = useSetRecoilState(userAtom);
	const setCategories = useSetRecoilState(categoriesAtom);
	const setInit = useSetRecoilState(initializingAtom);

	React.useEffect(() => {
		async function init() {
			const token = localStorage.getItem("creator_jwt_token");

			if (token) {
				const decoded = jwtDecode(token);
				const currentTime = Date.now() / 1000;
				if (decoded.exp < currentTime) {
					localStorage.removeItem("creator_jwt_token");
					setUser(null);
					return setInit(false);
				}
				try {
					Ajax.headers["Authorization"] = `Bearer ${token}`;
					await getUser(setUser, setCategories);
					return setInit(false);
				} catch (e) {
					return setInit(false);
				}
			} else {
				return setInit(false);
			}
		}

		init();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export const getMembers = async (gymId, setUser) => {
	const response = await Ajax.call(ajaxRoutes.GET_GYM_MEMBER_LIST(gymId));
	if (response?.users) {
		const overTimeData = {};
		response.users.forEach((user) => {
			const format = moment(user.createdDate).format("MMMM-YYYY");
			overTimeData[format] = overTimeData[format] ? overTimeData[format] + 1 : 1;
		});

		return setUser((prev) => ({
			...prev,
			members: response.users,
			overTimeData,
		}));
	}
	return;
};

export const getUser = async (setUser, setCategories) => {
	const user = await Ajax.call(ajaxRoutes.GET_USER(), "get");
	const userAssociations = await Ajax.call(ajaxRoutes.GET_USER_ASSOCIATIONS(), "get");

	if (JSON.stringify(userAssociations) === "{}") {
		return setUser(user);
	} else {
		const hostname = window.location.hostname;
		const domainGymId = (await Ajax.call(ajaxRoutes.DOMAINS_LOOKUP(hostname)))?.gym_id;
		const gym = await Ajax.call(ajaxRoutes.GET_GYM(userAssociations.gym_id), "get");
		const domainGym = await Ajax.call(ajaxRoutes.GET_GYM(domainGymId), "get");
		const gymBranding = await Ajax.call(ajaxRoutes.GET_GYM_BRANDING(userAssociations.gym_id), "get");
		const pages = await Ajax.call(ajaxRoutes.GET_PAGES(userAssociations.gym_id), "get");
		const workoutsResponse = await Ajax.call(ajaxRoutes.GET_WORKOUTS_BY_GYM_ID(userAssociations.gym_id), "get");
		const domainWorkouts = await Ajax.call(ajaxRoutes.GET_WORKOUTS_BY_GYM_ID(domainGymId), "get");

		if (workoutsResponse && workoutsResponse.workouts) {
			workoutsResponse.workouts.map((workout) => {
				if (workout.tags === "undefined") {
					workout.tags = null;
				}
				return workout;
			});
			delete workoutsResponse.histogram.tags["undefined"];
		}

		if (gym?.id && setCategories) {
			const categoriesResponse = await Ajax.call(ajaxRoutes.GET_CATEGORIES(gym.id), "get");

			if (categoriesResponse.categories) {
				setCategories(categoriesResponse.categories);
			}
		}

		return setUser({
			...user,
			userAssociations,
			gym,
			domainGym,
			gymBranding,
			pages,
			workouts: workoutsResponse?.workouts,
			domainWorkouts: domainWorkouts?.workouts,
			workoutTags: workoutsResponse?.histogram?.tags,
		});
	}
};

export default useInit;
