import styled from "styled-components";

const StyledMembershipSelection = styled.div`
	.sb-membership-selection {
		&__cards {
			display: flex;
			justify-content: center;
			gap: 40px;
			margin-bottom: 16px;
		}

		&__details {
			background: ${({ theme }) => theme.colors.white};
			border: 1px solid ${({ theme }) => theme.colors.iron};
			border-radius: 5px;
			position: relative;

			&__up {
				display: flex;
				gap: 100px;
				padding: 20px 16px 20px 16px;
				justify-content: space-between;

				&__left {
					display: flex;
					flex-direction: column;
					gap: 4px;

					p {
						margin: 0;
					}
				}

				&__right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					gap: 8px;

					.ant-form-item-explain-error {
						font-size: 11px;
					}
				}
			}

			&__down {
				background-color: ${({ theme }) => theme.colors.athensGray};
				padding: 20px 16px;
				display: flex;
				gap: 40px;
				justify-content: space-between;

				&__left {
					display: flex;
					align-items: center;
					gap: 9px;

					&__collapse {
						padding: 0px;

						width: 100%;
						.ant-collapse-header {
							background-color: transparent;

							margin-left: -12px;
						}
					}
					&__icon {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 29px;
						height: 29px;
						background: ${({ theme }) => theme.colors.black};
						border-radius: 5px;
						color: ${({ theme }) => theme.colors.coral};

						svg {
							width: 24px;
						}
					}

					&__text {
						display: flex;
						align-items: center;
						flex-wrap: wrap;

						p:first-child {
							margin-right: 8px;
						}

						p:last-child {
							width: 100%;
						}
					}
				}

				&__right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					gap: 8px;
				}
			}

			&__mask {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(200, 200, 200, 0.8);
			}
		}

		&__navigation {
			margin-top: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 32px;
		}
	}
`;

export default StyledMembershipSelection;

