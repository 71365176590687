import React from "react";
import { Tooltip } from "antd";

const truncateText = (text, length = 30) => {
	if (text.length > length) {
		return {
			isTruncated: true,
			text: `${text.slice(0, length)}...`,
		};
	}

	return {
		isTruncated: false,
		text,
	};
};

const Categories = ({ values }) => {
	const maxVisibleCategories = 3;
	const sortedValues = [...values].sort((a, b) => a.length - b.length);
	const visibleCategories = sortedValues.slice(0, maxVisibleCategories);
	const additionalCategories = sortedValues.slice(maxVisibleCategories);

	return (
		<div style={{ display: "flex", flexWrap: "wrap" }}>
			{visibleCategories.map((value, key) => {
				const { isTruncated, text } = truncateText(value);
				return isTruncated ? (
					<Tooltip key={`categories-list-key-${key}`} title={value}>
						<div className="tag" style={{ margin: "2px" }}>
							{text}
						</div>
					</Tooltip>
				) : (
					<div key={`categories-list-key-${key}`} className="tag" style={{ margin: "2px" }}>
						{text}
					</div>
				);
			})}
			{additionalCategories.length > 0 && (
				<Tooltip
					title={
						<div>
							{additionalCategories.map((category, index) => (
								<div key={index}>- {category}</div>
							))}
						</div>
					}
				>
					<div className="tag" style={{ margin: "2px" }}>
						+{additionalCategories.length} more
					</div>
				</Tooltip>
			)}
		</div>
	);
};

export default Categories;
