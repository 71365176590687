import React from "react";
import PropTypes from "prop-types";

import "./NumberIcon.scss";

function NumberIcon({ number, disabled }) {
	return (
		<div style={{ opacity: disabled ? 0.5 : 1 }} className="number-icon">
			{number}
		</div>
	);
}

NumberIcon.propTypes = {
	number: PropTypes.string.isRequired,
};

export default NumberIcon;
