import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers,
});

const { actions, reducer } = usersSlice;

export const { getCurrentUser, updateCurrentUser, updateUsersErrors, updateUsersLoading, deleteUser } = actions;
export default reducer;
