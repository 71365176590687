// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback } from "react";
import { Card } from "antd";
import { SketchPicker } from "react-color";

// -----------------------------------------------------------------------------
// Components, utils and Styles
// -----------------------------------------------------------------------------
import { Heading4, Paragraph6, Paragraph7 } from "themes/default/_typography";
import { SelectedColorCard } from "components/Common/Cards/SelectedColorCard";
import StyledColorPickerCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ColorPickerCard = ({ title, subtitle, selectedColor, description, onChangeColor }) => {
	const handleChangeComplete = useCallback(
		(e) => {
			onChangeColor(e.hex);
		},
		[onChangeColor]
	);

	return (
		<StyledColorPickerCard>
			<Card hoverable className="sb-color-picker-card">
				<div className="sb-color-picker-card__left">
					<Heading4 className="sb-color-picker-card__left__title">{title}</Heading4>
					<Paragraph6 className="sb-color-picker-card__left__subtitle">{subtitle}</Paragraph6>
					<Paragraph7 className="sb-color-picker-card__left__description">{description}</Paragraph7>
					<div className="sb-color-picker-card__left__current-color">
						<SelectedColorCard selectedColor={selectedColor} />
					</div>
				</div>
				<div className="sb-color-picker-card__right">
					<SketchPicker color={selectedColor || ""} onChangeComplete={handleChangeComplete} />
				</div>
			</Card>
		</StyledColorPickerCard>
	);
};

export default ColorPickerCard;
