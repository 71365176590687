// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Form, Switch, Tooltip } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Label2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Styles
// -------------------------------------------------------------------------------
import StyledFormInput from "./styles";

const FormCheckbox = ({
	name,
	label,
	rules,
	autoComplete,
	placeholder = "",
	maxLength = null,
	isChecked,
	tooltipTitle = "",
}) => {
	return (
		<StyledFormInput className="sb-form-input">
			<Form.Item
				name={name}
				valuePropName="checked"
				label={<Label2>{label}</Label2>}
				rules={rules}
				tooltip={{ title: tooltipTitle }}
				required={false}
			>
				<Switch />
			</Form.Item>
		</StyledFormInput>
	);
};

export default FormCheckbox;

