// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Collapse, Tooltip } from "antd";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import YourInformationForm from "pages/CreatorCreationFlow/YourInformation/YourInformationForm";
import ContactInformationForm from "pages/CreatorCreationFlow/ContactInformation/ContactInformationForm";
import { BrandingForm } from "components/Forms/BrandingForm";
import { SubdomainForm } from "components/Forms/SubdomainForm";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledSettings from "./styles";
import MemberPagesForm from "pages/CreatorCreationFlow/MemberPages/MemberPagesForm";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Paragraph5 } from "themes/default/_typography";
import { useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Settings = () => {
	const { gym } = useParams();
	const { data: domainGymData, refetch: refetchDomainGymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const { Panel } = Collapse;

	const [finishedBranding, setFinishedBranding] = useState(false);
	const [finishedContact, setFinishedContact] = useState(false);
	const [finishedMemberPages, setFinishedMemberPages] = useState(false);

	useEffect(() => {
		if (domainGymData) {
			// Check domain gym data for branding
			if (domainGymData.brand_color1 && domainGymData.light_logo && domainGymData.dark_logo) {
				setFinishedBranding(true);
			}
			// Check domain gym data for contact information
			if (
				domainGymData.address1 !== "" &&
				domainGymData.city !== "" &&
				domainGymData.state !== "" &&
				domainGymData.zip !== "" &&
				domainGymData.phone_number !== "" &&
				domainGymData.email !== ""
			) {
				setFinishedContact(true);
			}
			// Check domain gym data for member pages
			if (domainGymData.pages.length > 1) {
				setFinishedMemberPages(true);
			}
		}
	}, [domainGymData]);

	const noticeColor = "salmon";

	return (
		<StyledSettings className="sb-settings">
			{finishedBranding && finishedContact && finishedMemberPages ? null : (
				<Paragraph5 style={{ color: noticeColor }}>
					<InfoCircleOutlined style={{ color: noticeColor, marginRight: "8px" }} />
					You'll need to finish setting up your gym before you can start accepting sign ups. Please make sure you fill
					out your payment information from the "Pricing Plan" tab as well.
				</Paragraph5>
			)}

			<Collapse accordion>
				<Panel header="Subdomain Settings" key="1">
					<SubdomainForm />
				</Panel>
				<Panel header="Your Information" key="2">
					<YourInformationForm update={true} />
				</Panel>
				<Panel
					header="Branding"
					key="3"
					extra={!finishedBranding ? <InfoCircleOutlined style={{ color: noticeColor }} /> : null}
				>
					<BrandingForm refetch={refetchDomainGymData} />
				</Panel>
				<Panel
					header="Contact Information"
					key="4"
					extra={!finishedContact ? <InfoCircleOutlined style={{ color: noticeColor }} /> : null}
				>
					<ContactInformationForm update={true} />
				</Panel>

				<Panel
					header={
						<Tooltip title={!finishedBranding ? "Complete the Branding Section before editing your Member Pages" : ""}>
							<div>Member Pages</div>
						</Tooltip>
					}
					key="5"
					extra={!finishedMemberPages ? <InfoCircleOutlined style={{ color: noticeColor }} /> : null}
					collapsible={!finishedBranding ? "disabled" : ""}
				>
					<MemberPagesForm source={null} />
				</Panel>
			</Collapse>
		</StyledSettings>
	);
};

export default Settings;
