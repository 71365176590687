// -------------------------------------------------------------------------------
// Library
// -------------------------------------------------------------------------------
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Space } from "antd";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { MainLayout } from "components/Layouts/MainLayout";
import { Heading13, Paragraph9 } from "themes/default/_typography";
import { Button } from "components/Common/Button";
import { GeneralInformationCard } from "components/Common/Cards/InformationCard";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledGymSearch from "./styles";
import { useLazySearchGymsQuery } from "store/api/api";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../../globalAtoms";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const GymSearch = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [trigger, result] = useLazySearchGymsQuery({});

	const load = async (term, zip) => {
		const data = {
			zip: zip ?? "",
			term: term ?? "",
		};
		await trigger(data).unwrap();
	};

	const onFinishForm = useCallback(
		({ mainSearch, zipCodeSearch }) => {
			load(mainSearch, zipCodeSearch);
		},
		[dispatch]
	);

	const setUser = useSetRecoilState(userAtom);

	const getStarted = async () => {
		await setUser(null);
		localStorage.removeItem("creator_jwt_token");
		navigate("/creator-creation");
	};

	return (
		<StyledGymSearch>
			<MainLayout
				actionsSection={
					<div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
						<div>
							<Button className="primary-filled--branding-primary-color" uppercase onClick={getStarted}>
								<Paragraph9>Get Started</Paragraph9>
							</Button>
						</div>
					</div>
				}
			>
				<div className="sb-gym-search__search">
					<div className="sb-gym-search__search__inner">
						<div className="sb-gym-search__search__inner__header">
							<Heading13>Find My Gym</Heading13>
							<div className="sb-gym-search__search__inner__header">
								<Form onFinish={onFinishForm} layout="inline">
									<Space.Compact style={{ width: "100%", borderRadius: "16px", gap: "0px", display: "flex" }}>
										<Form.Item
											name="mainSearch"
											style={{ margin: "0px", padding: "0px", width: "40%" }}
											required={false}
										>
											<Input
												style={{ width: "100%", borderRadius: "100px 0px 0px 100px ", height: "50px" }}
												placeholder="Enter to begin searching..."
											/>
										</Form.Item>
										<Form.Item name="zipCodeSearch" style={{ margin: "0px", padding: "0px", width: "20%" }}>
											<Input style={{ height: "50px" }} placeholder="Zip Code" maxLength={9} autoComplete={"off"} />
										</Form.Item>

										<Button
											className="primary-filled--branding-primary-color"
											type="submit"
											uppercase
											style={{ borderRadius: "0px 100px 100px 0px", padding: "10px" }}
										>
											<Paragraph9 style={{ padding: 0 }}>SEARCH</Paragraph9>
										</Button>
									</Space.Compact>
								</Form>
							</div>
						</div>
						<div className="sb-gym-search__search__inner__result">
							{result?.data?.gyms && (
								<Heading13>
									{result?.data?.gyms?.length} result{result?.data?.gyms?.length > 1 ? "s" : ""} found
								</Heading13>
							)}
							{result?.data?.gyms &&
								result?.data?.gyms.map(({ id, light_logo, dark_logo, name, zipcode, city, state, subdomain }) => (
									<GeneralInformationCard
										key={id}
										url={`/login/${subdomain}`}
										icon={<img src={light_logo} alt="gym-logo" />}
										header={name}
										text={`${city ? city + "," : ""} ${state || ""}${zipcode ? " | Zip Code: " : ""}${zipcode || ""}`}
										transparent
									/>
								))}
						</div>
					</div>
				</div>
			</MainLayout>
		</StyledGymSearch>
	);
};

export default GymSearch;

