// -----------------------------------------------------------------------------
// Library and utils imports
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { selectDomainGym } from "store/selectors";
import { variables } from "./_variables";

// -----------------------------------------------------------------------------
// Theme
// -----------------------------------------------------------------------------
const Default = ({ children }) => {
	const domainGym = useSelector(selectDomainGym);
	const [theme, setTheme] = useState({ colors: variables.colors, responsive: variables.responsive });

	useEffect(() => {
		if (domainGym) {
			const { brand_color1 } = domainGym;

			if (brand_color1 && CSS.supports("color", brand_color1)) {
				setTheme({
					colors: {
						...variables.colors,
						coral: brand_color1,
					},
					responsive: variables.responsive,
				});
			}
		}
	}, [domainGym]);

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Default;
