import styled from "styled-components";

const StyledWorkoutsList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 32px;
`;

export default StyledWorkoutsList;
