import React from "react";

const FlashIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M24.1203 13.4865L14.7869 28.5565C14.6968 28.7005 14.5713 28.8197 14.4221 28.9028C14.2729 28.9858 14.1048 29.0301 13.9336 29.0315H13.6669C13.5745 29.0314 13.4831 29.0125 13.3983 28.9762C13.3135 28.9399 13.237 28.8869 13.1736 28.8204C13.1105 28.7537 13.0625 28.6745 13.0326 28.588C13.0028 28.5016 12.9917 28.4099 13.0003 28.3189L13.7469 19.7943H8.98692C8.8109 19.7924 8.63841 19.7451 8.48644 19.6572C8.33447 19.5693 8.20827 19.4437 8.12026 19.2928L7.86692 18.8573C7.8045 18.756 7.77148 18.6395 7.77148 18.5208C7.77148 18.4021 7.8045 18.2857 7.86692 18.1843L17.2003 3.11438C17.2927 2.96647 17.4225 2.84491 17.5767 2.76162C17.731 2.67833 17.9045 2.63619 18.0803 2.63932H18.3469C18.4393 2.63949 18.5307 2.6583 18.6156 2.6946C18.7004 2.73091 18.7768 2.78394 18.8403 2.85046C18.9033 2.91718 18.9513 2.99637 18.9812 3.0828C19.0111 3.16924 19.0221 3.26095 19.0136 3.35191L18.2536 11.8766H23.0136C23.1896 11.8785 23.3621 11.9257 23.5141 12.0136C23.666 12.1016 23.7922 12.2272 23.8803 12.378L24.1336 12.8135C24.194 12.9161 24.2247 13.0332 24.2224 13.1519C24.22 13.2706 24.1847 13.3864 24.1203 13.4865Z"
			fill="currentColor"
		/>
	</svg>
);

export default FlashIcon;
