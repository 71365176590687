// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router";
// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { useGetDomainGymQuery, useGetGymMembersByDateQuery } from "store/api/api";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { StackedBarChart } from "components/Common/Charts/StackedBarChart";
import { LoadingMask } from "components/Common/LoadingMask";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { variables } from "themes/default/_variables";
import StyledMembersSubscribersStackedBarChart from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembersSubscribersStackedBarChart = ({ dateRange }) => {
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGym = domainGymResponse?.data;

	const gymMembersResponse = useGetGymMembersByDateQuery({
		startDate: dateRange.startDate.format("YYYY-MM-DD"),
		endDate: dateRange.endDate.format("YYYY-MM-DD"),
	});
	const [chartData, setChartData] = useState([]);
	const [subscribers, setSubscribers] = useState([]);

	const bars = [
		{
			name: "Renewing Subs",
			backgroundColor: variables.colors.trout,
		},
		{
			name: "New Subs",
			backgroundColor: domainGym?.brand_color1 || variables.colors.coral,
		},
	];

	useEffect(() => {
		const fetchSubscribers = async () => {
			if (dateRange && gymMembersResponse.data) {
				setSubscribers(gymMembersResponse.data.subscribers);
			} else {
				setSubscribers([]);
			}
		};

		fetchSubscribers();
	}, [dateRange, gymMembersResponse]);

	useEffect(() => {
		const updateChartData = () => {
			if (dateRange && gymMembersResponse?.data) {
				const labels = [];
				const startDate = dateRange.startDate.clone();
				const endDate = dateRange.endDate.clone();

				while (endDate.isAfter(startDate) || startDate.format("M") === endDate.format("M")) {
					labels.push(moment.tz(startDate.format("YYYY-MM-DD"), "UTC"));
					startDate.add(1, "month");
				}

				const tempChartData = labels.map((date) => {
					let currentNewSubs = 0;
					let currentRenewSubs = 0;

					subscribers.forEach((subscriber) => {
						if (subscriber.subscription_start_date) {
							const newItemStartDate = moment.unix(subscriber.subscription_start_date).utc();

							if (newItemStartDate.isBefore(date) && !date.isAfter(moment().utc())) {
								currentRenewSubs += 1;
							}

							if (
								newItemStartDate.month() === date.month() &&
								newItemStartDate.year() === date.year() &&
								!date.isAfter(moment().utc())
							) {
								currentNewSubs += 1;
							}
						}
					});

					return {
						name: date.format("MMM"),
						"Renewing Subs": currentRenewSubs,
						"New Subs": currentNewSubs,
					};
				});

				setChartData(tempChartData);
			} else {
				setChartData([]);
			}
		};

		updateChartData();
	}, [subscribers, dateRange, gymMembersResponse]);

	return (
		<StyledMembersSubscribersStackedBarChart className="sb-members-subscribers-donut-chart">
			{gymMembersResponse.isLoading || gymMembersResponse.isFetching ? (
				<LoadingMask />
			) : (
				<StackedBarChart data={chartData} bars={bars} />
			)}
		</StyledMembersSubscribersStackedBarChart>
	);
};

export default MembersSubscribersStackedBarChart;

