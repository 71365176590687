import React from "react";
import FullScreenWorkoutVideoMusicPlayer from "../../../components/Workouts/FullScreenWorkoutVideoMusicPlayer";

function ViewWorkout() {
	return (
		<div>
			<FullScreenWorkoutVideoMusicPlayer />
		</div>
	);
}

export default ViewWorkout;
