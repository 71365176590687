//import { Redirect } from "react-router-dom";

import { redirect } from "react-router";

function LogoutButton() {
	// const [loggedOut, setLoggedOut] = useState(false)
	// const logout = () => {
	//localStorage.removeItem("creator_jwt_token");
	//   setLoggedOut(true)
	// };
	// if (loggedOut) {
	//return redirect("/login");
	// }
	//
	// return <Button onClick={logout}>LogOut</Button>;
}

export default LogoutButton;
