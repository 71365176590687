import { useSetRecoilState } from "recoil";
import { creatorCreationFlowState } from "./atoms";

export function useFormSubmissionHooks() {
	const updateCreatorCreationFlowState = useSetRecoilState(creatorCreationFlowState);

	const mountUpdatesAfterFormSubmission = (formObj) => {
		updateCreatorCreationFlowState((prev) => {
			return {
				...prev,
				...formObj,
			};
		});
	};

	return {
		mountUpdatesAfterFormSubmission,
	};
}
