import axios from "axios";

export default class Ajax {
	static headers = {};

	static async call(url, method = "get", body, errFunc) {
		let response = null;
		let token = localStorage.getItem("creator_jwt_token");
		if (token) {
			this.headers["Authorization"] = token;
		}

		try {
			const options = {
				url,
				method,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					...this.headers,
				},
			};

			if (method === "upload") {
				delete options.headers["Content-Type"];
				options.data = body;
			} else if (body !== null) {
				options.data = JSON.stringify(body);
			}

			response = await axios(options);
			return response.data;
		} catch (e) {
			if (errFunc) return errFunc(e?.response || e);
		}
	}
}
