// -----------------------------------------------------------------------------
// Importing libraries
// -----------------------------------------------------------------------------
import { create } from "apisauce";

// -----------------------------------------------------------------------------
// Creating the base api object with the needed headers and url
// -----------------------------------------------------------------------------
export default create({
	transformRequest: [
		(data) => {
			if (data instanceof FormData) return data;
			return JSON.stringify(data);
		},
	],
});
