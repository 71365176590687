import styled from "styled-components";

const StyledFormInputNumber = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	.sb-form-input-number {
		&__addon-right {
			position: absolute;
			opacity: 0.5;
			top: 16px;
			right: 14px;
		}
	}

	&.sb-form-input-number--addonRight {
		position: relative;

		input {
			padding-right: 60px;
		}
	}

	input {
		background: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 100px;
		height: 46px;
		padding: 16px;
	}

	.ant-form-item-label {
		label {
			font-family: "DM Sans", sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			text-transform: uppercase;
			color: ${({ theme }) => theme.colors.trout};
		}
	}

	.ant-input-number {
		width: 100%;
		border: none;
	}

	.ant-input-number {
		border-radius: 100px;
	}

	.ant-input-number-handler-wrap {
		display: none;
	}

	.ant-form-item-explain-error {
		padding-left: 12px;
	}
`;

export default StyledFormInputNumber;
