// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { landingPageImageAtom, signUpPageImageAtom } from "./atoms";
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { MEMBER_PAGES_FORM } from "../constants";
import { required } from "helpers/Form/commonFormValidations";
import HeaderImage from "./HeaderImage";
import LandingPagePreview from "./LandingPagePreview";
import { userAtom } from "../../../globalAtoms";
import {
	useCreatePageAssetMutation,
	useCreatePageMutation,
	useEditPageMutation,
	useGetDomainGymQuery,
	useGetPagesQuery,
} from "store/api/api";
import { useParams } from "react-router";
import SignupPagePreview from "./SignupPagePreview";
import { notification } from "antd";
import { Button } from "components/Common/Button";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const MemberPagesForm = (props) => {
	const { gym } = useParams(); //GYM FROM URL PARAMS

	// Should we keep using recoil for this?
	const landingPageImage = useRecoilValue(landingPageImageAtom);
	const signUpPageImage = useRecoilValue(signUpPageImageAtom);
	const setLandingPageImage = useSetRecoilState(landingPageImageAtom);
	const setSignUpPageImage = useSetRecoilState(signUpPageImageAtom);
	const [user] = useRecoilState(userAtom);

	// RTKQuery
	const { data: domainGymData, refetch: refetchDomainGymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const gymId = domainGymData?.id || null;
	const { data: pageData, refetch: refetchPages } = useGetPagesQuery(gymId, { skip: !gymId });
	const [pages, setPages] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [editPageTrigger, editPageResponse] = useEditPageMutation();
	const [addAssetToPageTrigger, addAssetToPageResponse] = useEditPageMutation();
	const [createPageTrigger, createPageResponse] = useCreatePageMutation();
	const [createPageAssetTrigger, createPageAssetResponse] = useCreatePageAssetMutation();

	useEffect(() => {
		if (pageData) {
			setPages(pageData);
			const newValues = pageData.reduce((acc, page) => {
				if (page.type === "sign_up") {
					return {
						...acc,
						signUpSubText: page.sub_text,
						signUpHeaderText: page.header_text,
					};
				}
				if (page.type === "landing") {
					return {
						...acc,
						landingSubText: page.sub_text,
						landingHeaderText: page.header_text,
					};
				}
				return acc;
			}, {});
			setInitialValues((initiaValuesCopy) => {
				return {
					...initiaValuesCopy,
					...newValues,
				};
			});
		}
	}, [pageData]);

	const handleFormSubmit = async (formObj) => {
		// Landing Page Info
		if (
			initialValues.landingHeaderText !== formObj.landingHeaderText ||
			initialValues.landingSubText !== formObj.landingSubText
		) {
			const landingSubmissionObj = {
				header_text: formObj.landingHeaderText,
				sub_text: formObj.landingSubText,
				type: "landing",
				header_image: null,
				gym_id: user?.userAssociations[0]?.gym_id,
			};
			await submitPageInfo(landingSubmissionObj);
		}
		// Sign Up Info
		if (
			initialValues.signUpHeaderText !== formObj.signUpHeaderText ||
			initialValues.signUpSubText !== formObj.signUpSubText
		) {
			const signUpSubmissionObj = {
				header_text: formObj.signUpHeaderText,
				sub_text: formObj.signUpSubText,
				type: "sign_up",
				header_image: null,
				gym_id: user?.userAssociations[0]?.gym_id,
			};
			await submitPageInfo(signUpSubmissionObj);
		}
		const { data: pages } = await refetchPages();

		// This is weird, we should probably refactor this
		const uploadPromises = pages.map(async (page) => {
			if (page.type === "sign_up" && signUpPageImage) {
				return await uploadPageAsset(page, signUpPageImage, "sign_up");
			}
			if (page.type === "landing" && landingPageImage) {
				return await uploadPageAsset(page, landingPageImage, "landing");
			}
			return null;
		});
		await Promise.all(uploadPromises);
		//DO WE NEED THIS?
		refetchDomainGymData();
	};

	// If the user doesn't have any pages, create them. Otherwise, edit them.
	const submitPageInfo = async (submissionObj) => {
		try {
			pages.length === 0
				? await createPageTrigger(submissionObj)
				: await editPageTrigger({ gymId: domainGymData.id, params: submissionObj });
			const { data } = await refetchPages();
			return data;
		} catch (error) {
			console.error("try create/edit page error: ", error);
		}
	};

	useEffect(() => {
		if (createPageResponse.isSuccess) {
			notification.success({
				message: "Page saved successfully",
			});
		} else if (createPageResponse.isError) {
			notification.error({
				message: "Something went wrong saving your page",
			});
		}
	}, [createPageResponse]);

	useEffect(() => {
		if (editPageResponse.isSuccess) {
			notification.success({
				message: "Page saved successfully",
			});
		} else if (editPageResponse.isError) {
			notification.error({
				message: "Something went wrong saving your page",
			});
		}
	}, [editPageResponse]);

	// Upload the image to the server
	const uploadPageAsset = async (page, image, type) => {
		const jwt = localStorage.getItem("creator_jwt_token");
		const formData = new FormData();
		formData.append("image", image);
		formData.append("page_id", page.id);
		formData.append("type", type);
		formData.append("gymId", domainGymData?.id);
		try {
			await createPageAssetTrigger({ formData, jwt });
			const resetFunc = type === "sign_up" ? setSignUpPageImage : setLandingPageImage;
			resetFunc(null);
		} catch (error) {
			console.error("error: ", error);
			notification.error({
				message: "Something went wrong saving your image",
			});
		}
	};

	// Check for image uploading success or error and notify user
	useEffect(() => {
		if (createPageAssetResponse.isSuccess) {
			notification.success({
				message: "Image saved successfully",
			});
			// If the image was saved successfully, update the page with the location of the image
			if (createPageAssetResponse.data.page_assets.location) {
				const location = createPageAssetResponse.data.page_assets.location;
				const submissionObj = {
					gym_id: domainGymData.id,
					header_text: null,
					sub_text: null,
					header_image: location,
					type: createPageAssetResponse.data.page_assets.page_type,
				};
				addAssetToPageTrigger({ gymId: domainGymData.id, params: submissionObj });
				//editPageTrigger({ gymId: domainGymData.id, params: submissionObj });
			}
		} else if (createPageAssetResponse.isError) {
			notification.error({
				message: "Something went wrong saving your image",
			});
		}
	}, [createPageAssetResponse, addAssetToPageTrigger, domainGymData.id]);

	useEffect(() => {
		if (addAssetToPageResponse.isSuccess) {
			notification.success({
				message: "Image Added To Page successfully",
			});
			refetchPages();
		} else if (addAssetToPageResponse.isError) {
			notification.error({
				message: "Something went wrong adding page to image",
			});
		}
	}, [addAssetToPageResponse, refetchPages]);

	return (
		<Form initialValues={initialValues} onSubmit={(formObj) => handleFormSubmit(formObj)}>
			{({ handleSubmit }) => {
				return (
					<form id={MEMBER_PAGES_FORM} onSubmit={handleSubmit} className="sb-members-page-form">
						<div className="sb-creator-creation__inner__header">
							<p className="sb-creator-creation__inner__header__text">Set up your SWEATBASE Landing Page</p>
						</div>
						<div className="sb-members-page-form__body">
							<div className="sb-members-page-form__body__left">
								<Label>Header Text</Label>
								<Field name="landingHeaderText" validate={required}>
									{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
								</Field>
								<Label>Sub Text</Label>
								<Field name="landingSubText" validate={required}>
									{({ input, meta }) => <InputField type="text" textarea={true} input={input} meta={meta} />}
								</Field>
								<HeaderImage type="landing" user={user} pageData={pages.find((page) => page.type === "landing")} />
							</div>
							<div className="sb-members-page-form__body__right" style={{ justifyContent: "center" }}>
								<div className="sb-members-page-form__body__right__title">LANDING PAGE EXAMPLE</div>
								<div
									className="sb-members-page-form__body__right__preview"
									style={{
										display: "flex",
										width: "100%",
										height: "100%",
										borderRadius: "5px",
									}}
								>
									<LandingPagePreview
										type="landing"
										gym={domainGymData}
										pageData={pages.find((page) => page.type === "landing")}
									/>
								</div>
							</div>
						</div>
						<div className="sb-creator-creation__inner__header">
							<p className="sb-creator-creation__inner__header__text">Sign up page for your members</p>
						</div>
						<div className="sb-members-page-form__body">
							<div className="sb-members-page-form__body__left">
								<Label>Header Text</Label>
								<Field name="signUpHeaderText" validate={required}>
									{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
								</Field>
								<Label>Sub Text</Label>
								<Field name="signUpSubText" validate={required}>
									{({ input, meta }) => <InputField type="text" textarea={true} input={input} meta={meta} />}
								</Field>
								<HeaderImage type="sign_up" user={user} pageData={pages.find((page) => page.type === "sign_up")} />
							</div>
							<div className="sb-members-page-form__body__right">
								<div className="sb-members-page-form__body__right__title">Sign Up Page Example</div>
								<div className="sb-members-page-form__body__right__preview">
									<SignupPagePreview
										style={{
											backgroundColor: "#1d2023",
											display: "flex",
											width: "100%",
											height: "100%",
											borderRadius: "5px",
										}}
										type="signUp"
										gym={domainGymData}
										pageData={pages.find((page) => page.type === "sign_up")}
									/>
								</div>
							</div>
						</div>
						<Button
							className="primary-filled"
							saveIcon
							uppercase
							loading={
								createPageResponse.isLoading ||
								editPageResponse.isLoading ||
								addAssetToPageResponse.isLoading ||
								createPageAssetResponse.isLoading
							}
							disabled={
								createPageResponse.isLoading ||
								editPageResponse.isLoading ||
								addAssetToPageResponse.isLoading ||
								createPageAssetResponse.isLoading
							}
							onClick={handleSubmit}
						>
							Save Pages
						</Button>
					</form>
				);
			}}
		</Form>
	);
};

export default MemberPagesForm;
