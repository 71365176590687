// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { TwoColumnsLayout } from "components/Layouts/TwoColumnsLayout";
import { MemeberPayments } from "components/Payments/MemeberPayments";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectDomainGymSignUpPage } from "store/selectors";

// -----------------------------------------------------------------------------
// Styles and helpers
// -----------------------------------------------------------------------------
import StyledAddPaymentMethod from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AddPaymentMethod = () => {
	const signUpPage = useSelector(selectDomainGymSignUpPage);

	return (
		<StyledAddPaymentMethod>
			<div className="sb-add-payment-method">
				<TwoColumnsLayout leftColumn={<MemeberPayments />} rightColumnImage={signUpPage.header_image} />
			</div>
		</StyledAddPaymentMethod>
	);
};

export default AddPaymentMethod;

