// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";
import { Form } from "antd";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { FormInput } from "components/Common/Form/FormInput";
import { FormTextArea } from "components/Common/Form/FormTextArea";
import { validateRequiredEncodedLenth } from "helpers/Form/commonFormValidations";
// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledScheduleLiveForm from "./styles";
import { useGetDomainGymQuery } from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { useParams } from "react-router";
import { decodeString, encodeString } from "utils";
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ScheduleLiveForm = ({ nextStep, values, loading }) => {
	const [form] = Form.useForm();
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const [error, setError] = useState();

	const onFinish = useCallback(async () => {
		if (error) {
			return;
		}
		const formData = form.getFieldsValue();
		const { workoutCoach, workoutDescription, workoutTitle } = formData;
		const payload = {
			gym_id: domainGymId,
			user_id: user?.id,
			name: encodeString(workoutTitle),
			coach: workoutCoach?.trim(),
			description: encodeString(workoutDescription),
			is_free: 0,
		};
		await nextStep(payload);
	}, [domainGymId, form, nextStep, user?.id, error]);

	const onChangeTitle = (e) => {
		const value = e.target?.value;
		const error = validateRequiredEncodedLenth(value, 255);
		setError(error || "");
	};

	return (
		<StyledScheduleLiveForm>
			<div className="sb-schedule-live-form">
				<Form
					onFinish={onFinish}
					layout="vertical"
					className="sb-schedule-live-form__form"
					form={form}
					initialValues={{
						workoutTitle: decodeString(values.name),
						workoutDescription: decodeString(values.description),
						workoutCoach: values.coach,
					}}
				>
					<FormInput
						name="workoutTitle"
						rules={[{ required: true, message: "This field is required" }]}
						onChange={onChangeTitle}
						label="Workout Title"
						hasFeedback={!!error}
						validateStatus={error ? "error" : undefined}
						help={error}
						autoFocus
					/>
					<FormTextArea
						name="workoutDescription"
						label="Workout Description"
						rules={[{ required: true, message: "This field is required" }]}
						rows={4}
					/>
					<FormInput
						name="workoutCoach"
						rules={[{ required: true, message: "This field is required" }]}
						label="Workout Coach"
					/>
					<Button
						type="submit"
						className="sb-schedule-live-form__form__button primary-filled"
						saveIcon
						uppercase
						disabled={loading}
						loading={loading}
					>
						<Paragraph2>Next</Paragraph2>
					</Button>
				</Form>
			</div>
		</StyledScheduleLiveForm>
	);
};

export default ScheduleLiveForm;

