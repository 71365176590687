import React from "react";

const ChevronRightIcon = (params) => (
	<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M1.21638 0.550007C1.17764 0.510954 1.13156 0.479956 1.08079 0.458802C1.03001 0.437648 0.97555 0.426758 0.920545 0.426758C0.86554 0.426758 0.811079 0.437648 0.760305 0.458802C0.70953 0.479956 0.663447 0.510954 0.624712 0.550007L0.458045 0.716674C0.417783 0.755459 0.385914 0.802098 0.364412 0.853702C0.342911 0.905306 0.332234 0.960775 0.333045 1.01667V6.98334C0.332234 7.03924 0.342911 7.09471 0.364412 7.14631C0.385914 7.19792 0.417783 7.24456 0.458045 7.28334L0.624712 7.45001C0.663447 7.48906 0.70953 7.52006 0.760305 7.54121C0.811079 7.56237 0.86554 7.57326 0.920545 7.57326C0.97555 7.57326 1.03001 7.56237 1.08079 7.54121C1.13156 7.52006 1.17764 7.48906 1.21638 7.45001L4.37471 4.29167C4.41457 4.25427 4.44634 4.20908 4.46806 4.15892C4.48977 4.10875 4.50098 4.05467 4.50098 4.00001C4.50098 3.94534 4.48977 3.89126 4.46806 3.84109C4.44634 3.79093 4.41457 3.74575 4.37471 3.70834L1.21638 0.550007Z"
			fill="currentColor"
		/>
	</svg>
);

export default ChevronRightIcon;
