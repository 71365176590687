import styled from "styled-components";

const StyledEditWorkoutModal = styled.div`
	padding: 32px;

	.sb-edit-workout-modal {
		&__header {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 16px;
			margin-bottom: 32px;
		}
	}
`;

export default StyledEditWorkoutModal;
