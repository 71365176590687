import React from "react";

const LocationIcon = (params) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M9.99992 1.6665C8.23181 1.6665 6.53612 2.36888 5.28587 3.61913C4.03563 4.86937 3.33325 6.56506 3.33325 8.33317C3.33325 13.5165 7.54158 13.4748 8.54992 16.4832L8.97492 17.7665C9.0329 17.939 9.14575 18.0878 9.29622 18.1901C9.4467 18.2924 9.62653 18.3427 9.80825 18.3332H10.2749C10.4566 18.3427 10.6365 18.2924 10.7869 18.1901C10.9374 18.0878 11.0503 17.939 11.1083 17.7665L11.5333 16.4832C12.4999 13.4748 16.6666 13.5165 16.6666 8.33317C16.6666 6.56506 15.9642 4.86937 14.714 3.61913C13.4637 2.36888 11.768 1.6665 9.99992 1.6665ZM9.99992 10.8332C9.50546 10.8332 9.02212 10.6865 8.61099 10.4118C8.19987 10.1371 7.87944 9.74669 7.69022 9.28988C7.501 8.83306 7.45149 8.3304 7.54795 7.84545C7.64442 7.36049 7.88252 6.91504 8.23215 6.5654C8.58178 6.21577 9.02724 5.97767 9.51219 5.88121C9.99714 5.78474 10.4998 5.83425 10.9566 6.02347C11.4134 6.21269 11.8039 6.53312 12.0786 6.94425C12.3533 7.35537 12.4999 7.83872 12.4999 8.33317C12.4999 8.99621 12.2365 9.6321 11.7677 10.1009C11.2988 10.5698 10.663 10.8332 9.99992 10.8332Z"
			fill="currentColor"
		/>
	</svg>
);

export default LocationIcon;
