import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

import "./index.scss";

function Collapse({ header, p, icon, onClick, centerContent }) {
	return (
		<div onClick={onClick} className="fit-card">
			<Row className="fit-card-section-container">
				{!centerContent && (
					<Col className="fit-card-section">
						<div className="fit-card-logo">
							<img src={icon} alt="card" />
						</div>
					</Col>
				)}
				<Col className={`fit-card-details ${centerContent ? "fit-card-details-centered" : ""}`}>
					<h1>{header}</h1>
					<p>{p}</p>
				</Col>
			</Row>
		</div>
	);
}

export default Collapse;
