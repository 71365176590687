// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import notification from "antd/lib/notification";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import ImageUpload from "components/ImageUpload";
import { Paragraph2 } from "themes/default/_typography";
// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledUploadAssetsForm from "./styles";
import { useGetS3SignedUrlMutation, useGetDomainGymQuery } from "store/api/api";
import axios from "axios";
import { useParams } from "react-router";
import { maxImageAssetSize, imageSizeLimitError } from "helpers/constants";
import "./styles.scss";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const UploadThumbnailForm = ({ nextStep, previousStep, values, setValues, loading }) => {
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const [thumbnailFile, setThumbnailFile] = useState(values?._thumbnail || null);
	const [nextLoading, setNextLoading] = useState(false);
	const [getS3SignedUrlTrigger] = useGetS3SignedUrlMutation();
	const blobToFile = async (blob) => {
		const blobToFile = new File([blob], "workoutThumbnail.png", {
			type: "image/png",
		});

		return blobToFile;
	};

	const uploadAsset = async (response, thumbnailFile) => {
		const formData = new FormData();

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		for (const field in response.fields) {
			formData.append(field, response.fields[field]);
		}

		formData.append("file", thumbnailFile);

		try {
			await axios.post(response.url, formData, { headers });
		} catch (error) {
			notification.error({
				message: "Error Uploading Image",
				description: "Please try again",
			});
		}
	};

	const uploadImageToS3 = async () => {
		const mimeMap = {
			"image/jpeg": "jpg",
			"image/png": "png",
		};
		const fileType = mimeMap[thumbnailFile.type] ?? "png";
		const s3KeyThumbnail = `assets/${domainGymId}/thumbnail-${new Date().getTime()}.${fileType}`;
		const s3SignedResponse = await getS3SignedUrlTrigger({ key: s3KeyThumbnail, type: "image" });
		await uploadAsset(s3SignedResponse.data.response, thumbnailFile);
		const fullS3ThumbNail = `${s3SignedResponse.data.response.url}/${s3KeyThumbnail}`;
		return fullS3ThumbNail;
	};

	const handleClickNext = async () => {
		setNextLoading(true);
		const thumbnail = await uploadImageToS3();
		await nextStep({ image_url: thumbnail, thumbnail });
		setNextLoading(false);
	};

	const onImageCancel = () => {
		setValues((v) => ({ ...v, _thumbnail: null }));
		setThumbnailFile(null);
	};

	const onSelectImage = async (blob) => {
		const file = await blobToFile(blob);
		setThumbnailFile(file);
		setValues((v) => ({ ...v, _thumbnail: file }));
	};

	return (
		<StyledUploadAssetsForm>
			<div className="sb-upload-assets-form">
				<div className="sb-upload-assets-form__upload">
					<ImageUpload
						className="sb-upload-assets-form__upload__input"
						onSave={onSelectImage}
						onCancel={onImageCancel}
						type="image"
						selected={thumbnailFile}
						title="Upload Thumbnail"
						aspectRatio={300 / 180}
						sizeLimit={{
							limit: maxImageAssetSize,
							error: imageSizeLimitError,
						}}
					/>
					{thumbnailFile === undefined && <FormErrorTag text="Thumbnail required" />}
				</div>
				<div className="sb-workout-music-form__actions">
					<Button
						type="button"
						onClick={() => previousStep()}
						className="sb-release-schedule-form__form__button secondary-filled"
						chevronLeft
						uppercase
					>
						<Paragraph2>Previous</Paragraph2>
					</Button>
					{thumbnailFile && (
						<Button
							type="submit"
							onClick={handleClickNext}
							className="sb-upload-assets-form__form__button primary-filled"
							saveIcon
							uppercase
							loading={loading || nextLoading}
							disabled={loading}
						>
							<Paragraph2>Next</Paragraph2>
						</Button>
					)}
				</div>
			</div>
		</StyledUploadAssetsForm>
	);
};

export default UploadThumbnailForm;

