import React from "react";
import Logo from "components/UI/Logo";
import { Link, useParams } from "react-router-dom";

function Header() {
	const { gym } = useParams();
	return (
		<div className="side-panel-header">
			<Link to={`/${gym}/dashboard`}>
				<Logo />
			</Link>
		</div>
	);
}

export default Header;
