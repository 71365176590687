import React, { useState } from "react";
import { Layout, Drawer, Button, Grid } from "antd";
import { MenuFoldOutlined, CloseOutlined } from "@ant-design/icons";

import "./SidePanel.scss";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const ResponsiveSider = ({ children }) => {
	const [drawerVisible, setDrawerVisible] = useState(false);
	const { lg } = useBreakpoint();
	const isSmallScreen = !lg;

	return (
		<>
			{!isSmallScreen && (
				<Sider collapsedWidth="0" width={220} className="sb-base-layout__sider">
					{children}
				</Sider>
			)}

			{/* Drawer for small screens */}
			{isSmallScreen && (
				<div className="sider">
					<Button
						className={`drawer-toggle-button`}
						icon={<MenuFoldOutlined />}
						onClick={() => setDrawerVisible(true)}
					></Button>
					<Drawer
						placement="left"
						closable
						onClose={() => setDrawerVisible(false)}
						open={drawerVisible}
						bodyStyle={{ padding: 0 }}
						width={220}
						headerStyle={{ display: "none" }}
					>
						<div className="close">
							<CloseOutlined onClick={() => setDrawerVisible(false)} />
						</div>
						{children}
					</Drawer>
				</div>
			)}
		</>
	);
};

export default ResponsiveSider;

