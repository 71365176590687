import styled from "styled-components";

const StyledUpcomingClasses = styled.div`
	flex: 1;
	padding: 16px;
    
	.sb-upcoming-card {
		padding: 16px;
		&__inner {
			border-radius: 12px;

			&__body-section {
				display: flex;
				flex-direction: column;
				gap: 8px;
				background-color: "#decaaf";

				h1 {
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
				}

				p {
					margin-bottom: 0;
				}

				&__categories {
				}
			}

			&__classes-container {
				display: flex;
				flex-direction: column;
                height: 60vh;
                overflow-y: scroll;
				h1 {
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
				}

				p {
					margin-bottom: 0;
				}

				&__time-list {
					flex-direction: row;
					width: 100%;

					display: flex;

					&__time {
						width: 100px;
						padding: 8px;

						p {
							font-style: normal;
							font-weight: 700;
							font-size: 16px;
							line-height: 18px;
                            align-self: flex-start;

						}
                        
                        &__line {
                            width: 100%;
                            height: 2px;
                            background: #FF865C;    
                        }

                        &__dot {
                            height: 10px;
                            width: 10px;
                            margin-top: -6px;
                            border-radius: 50%;
                            background: #FF865C;    
                        }
                        
                        &__current-time {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: end;
                            padding-top: 8px;
                            p {
                                color: #4F5661;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 22px;
                                opacity: 0.75;
                            }
                        }
					}

					&__classes-container {
						flex: 1;
						display: flex;
						flex-direction: column;
					}

					&__classes {
                        background: #FFFFFF;
						padding: 8px;
						box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
						border-radius: 12px;
						margin-bottom: 8px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 8px;
                        
                        &__details {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            padding: 8px;
                            h1 {
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 18px;
                            }

                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 16px;
                                color: #4F5661;
                            }
                        }

                        &__scheduling {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            justify-content: space-around;
                            align-items: flex-end;
                            height: 100%;
                            padding-right: 8px;

                            p {
                                color: #EB5757;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                            }
                            
                            h6 {
                                font-family: 'DM Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 20px;
                                color: #219653;
                            }
                        }

                        &__tags {
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                            
                            &__tag {
                                border-radius: 12px;
                                padding: 4px 8px;
                                font-size: 12px;
                                line-height: 14px;
                                color: #000000;
                                background: #F4F4F6;

                                p {
                                    margin-bottom: 0;
                                    color: #4F5661;
                                }
                            }
					}
				}
			}
		}
	}

	.ant-card-body {
		padding: 0;
	}
`;

export default StyledUpcomingClasses;

