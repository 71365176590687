import styled from "styled-components";

const StyledTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;

	.sb-tags {
		&__tag {
			padding: 4px 6px;
			background: rgba(230, 230, 235, 0.5);
			border-radius: 100px;
			white-space: nowrap;
		}
	}
`;

export default StyledTags;
