import React from "react";

const HotIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M16.173 2.86651C15.9608 2.73501 15.716 2.66571 15.4663 2.66651H15.333C15.1562 2.66651 14.9866 2.73675 14.8616 2.86177C14.7366 2.9868 14.6663 3.15637 14.6663 3.33318V9.49318C14.666 9.63694 14.6191 9.77673 14.5328 9.8917C14.4465 10.0067 14.3254 10.0907 14.1874 10.1311C14.0495 10.1716 13.9021 10.1664 13.7674 10.1163C13.6326 10.0662 13.5177 9.97394 13.4397 9.85318C12.5321 8.8545 11.4233 8.05928 10.1863 7.51984C10.0977 7.47423 9.99939 7.45043 9.89967 7.45043C9.79996 7.45043 9.70168 7.47423 9.61301 7.51984C9.5251 7.58103 9.45359 7.66289 9.40476 7.75822C9.35594 7.85355 9.33129 7.95942 9.33301 8.06651C8.91967 11.8398 5.33301 13.3998 5.33301 18.7332C5.33301 21.5622 6.45681 24.2753 8.4572 26.2756C10.4576 28.276 13.1707 29.3998 15.9997 29.3998C18.8286 29.3998 21.5418 28.276 23.5421 26.2756C25.5425 24.2753 26.6663 21.5622 26.6663 18.7332C26.6663 10.3598 19.3863 4.87984 16.173 2.86651ZM15.9997 26.6665C14.5852 26.6665 13.2286 26.1046 12.2284 25.1044C11.2282 24.1042 10.6663 22.7477 10.6663 21.3332C10.6663 17.7332 14.293 15.6532 14.6663 13.6265C14.6826 13.5427 14.7304 13.4683 14.7998 13.4187C14.8693 13.3691 14.9551 13.348 15.0397 13.3598C16.7851 13.8737 18.328 14.9167 19.4553 16.345C20.5825 17.7732 21.2387 19.5161 21.333 21.3332C21.333 22.7477 20.7711 24.1042 19.7709 25.1044C18.7707 26.1046 17.4142 26.6665 15.9997 26.6665Z"
			fill="currentColor"
		/>
	</svg>
);

export default HotIcon;
