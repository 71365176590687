// -----------------------------------------------------------------------------
// Library and utils imports
// -----------------------------------------------------------------------------
import React from "react";
import { ThemeProvider } from "styled-components";
import { variables } from "./_variables";

// -----------------------------------------------------------------------------
// Theme
// -----------------------------------------------------------------------------
const NoStore = ({ children }) => {
	const theme = {
		colors: variables.colors,
		responsive: variables.responsive,
	};

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default NoStore;
