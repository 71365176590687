// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Components and Styles
// -----------------------------------------------------------------------------
import StyledBasicCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const BasicCard = ({ icon, children }) => {
	return (
		<StyledBasicCard>
			<Card className="sb-basic-card">
				<div className="sb-basic-card__icon">{icon}</div>
				<div className="sb-basic-card__body">{children}</div>
			</Card>
		</StyledBasicCard>
	);
};

export default BasicCard;
