import styled from "styled-components";

const StyledEarningsCard = styled.div`
	width: 100%;

	.sb-earnings-card {
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 6px;

		&__top-section {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			border-bottom: 1px solid ${({ theme }) => theme.colors.iron};
			padding-bottom: 16px;

			&__left {
				&__card {
					width: 218px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					gap: 8px;
					background: ${({ theme }) => theme.colors.athensGray};
					border-radius: 4px;
					padding: 16px;
				}
			}

			&__right {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 8px;
				width: 220px;
			}
		}

		&__bottom-section {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 16px;
			gap: 52px;

			button {
				max-width: 280px;
			}
		}

		.ant-card-body {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 32px;
		}
	}
`;

export default StyledEarningsCard;
