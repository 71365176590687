import React from "react";

const DiamondIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M28.987 10.88L23.4537 4.86667C23.2047 4.5945 22.902 4.37695 22.5646 4.22778C22.2272 4.0786 21.8626 4.00104 21.4937 4H10.507C10.1382 4.00104 9.7735 4.0786 9.43614 4.22778C9.09877 4.37695 8.79602 4.5945 8.54703 4.86667L3.0137 10.88C2.78833 11.1279 2.66456 11.4516 2.66703 11.7867V12.1867C2.66642 12.4982 2.77492 12.8001 2.9737 13.04L14.9737 27.52C15.0991 27.6705 15.256 27.7915 15.4335 27.8745C15.6109 27.9575 15.8045 28.0003 16.0004 28C16.1962 28.0003 16.3898 27.9575 16.5672 27.8745C16.7447 27.7915 16.9017 27.6705 17.027 27.52L29.027 13.04C29.2258 12.8001 29.3343 12.4982 29.3337 12.1867V11.7867C29.3362 11.4516 29.2124 11.1279 28.987 10.88ZM14.907 23.2933L6.04036 12.6267C5.99921 12.61 5.96175 12.5855 5.9301 12.5543C5.89845 12.5232 5.87324 12.4862 5.8559 12.4453C5.82089 12.3628 5.8201 12.2698 5.85369 12.1867C5.88729 12.1036 5.95252 12.0372 6.03504 12.0022C6.11755 11.9672 6.21059 11.9664 6.29369 12H11.027C11.3195 11.9857 11.6085 12.068 11.8494 12.2343C12.0904 12.4006 12.27 12.6415 12.3604 12.92L15.547 22.9867C15.5877 23.0715 15.593 23.1691 15.5617 23.2578C15.5305 23.3466 15.4652 23.4193 15.3804 23.46C15.2955 23.5007 15.1979 23.506 15.1092 23.4747C15.0204 23.4434 14.9477 23.3782 14.907 23.2933ZM25.6537 12H17.427C17.3614 12.0018 17.2967 11.9841 17.2411 11.9493C17.1855 11.9145 17.1413 11.864 17.1143 11.8042C17.0872 11.7444 17.0784 11.6779 17.089 11.6131C17.0996 11.5483 17.129 11.4881 17.1737 11.44L21.2537 6.93333C21.2849 6.89896 21.323 6.8715 21.3655 6.8527C21.408 6.83391 21.4539 6.8242 21.5004 6.8242C21.5468 6.8242 21.5927 6.83391 21.6352 6.8527C21.6777 6.8715 21.7158 6.89896 21.747 6.93333L25.8937 11.44C25.9376 11.4873 25.9668 11.5463 25.9778 11.6098C25.9888 11.6734 25.9811 11.7387 25.9557 11.798C25.9303 11.8573 25.8882 11.9079 25.8346 11.9438C25.781 11.9796 25.7182 11.9992 25.6537 12Z"
			fill="currentColor"
		/>
	</svg>
);

export default DiamondIcon;
