import styled from "styled-components";

const StyledEarningsTable = styled.div`
	.sb-earnings-table {
		&__card {
			width: 100%;
			height: auto;
			border-radius: 6px;
			padding: 40px 36px 24px 36px;
			background-color: ${({ theme }) => theme.colors.white};
			thead {
				th {
					text-align: left;
					padding: 8px;
					background-color: transparent;
					border-bottom: 2px solid ${({ theme }) => theme.colors.trout};
					&:first-child {
						border-top-left-radius: 6px;
					}
					&:last-child {
						border-top-right-radius: 6px;
					}
				}
			}
			td {
				padding: 8px;
				height: 50px;
				vertical-align: middle;
			}
			tr:nth-child(even) {
				background-color: ${({ theme }) => theme.colors.iron};
			}
			tr:last-child {
				td:first-child {
					border-bottom-left-radius: 6px;
				}
				td:last-child {
					border-bottom-right-radius: 6px;
				}
			}
		}
	}
`;

export default StyledEarningsTable;

