import styled from "styled-components";

const StyledMembersTable = styled.div`
	width: 100%;

	.sb-members-table {
		&__actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			flex-wrap: wrap;
			gap: 16px;

			&__filters {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 16px;

				&__filter-header {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				button {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 8px;
					background-color: ${({ theme }) => theme.colors.white};
					border: 1px solid ${({ theme }) => theme.colors.coral};
					border-radius: 100px;
					padding: 8px 16px;
					cursor: pointer;
				}
			}
		}
	}
`;

export default StyledMembersTable;

