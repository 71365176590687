// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import Modal from "antd/lib/modal";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { EditWorkoutForm } from "components/Forms/Workout/EditWorkoutForm";
import { Heading10, Paragraph12 } from "themes/default/_typography";
import { CloseIcon } from "components/Icons";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledEditWorkoutModal from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EditWorkoutModal = ({ open, setOpen, isEditingLive, setIsEditingLive, liveStream }) => {
	const [uploading, setUploading] = useState(false);
	const handleCloseModal = () => {
		if (uploading) {
			return;
		}
		setOpen(false);

		if (setIsEditingLive) {
			setIsEditingLive(false);
		}
	};

	return (
		<Modal
			maskClosable={false}
			key={liveStream ? liveStream.id : null}
			width={656}
			closeIcon={<CloseIcon data-cy="close-edit-workout-modal" />}
			open={open}
			onCancel={handleCloseModal}
			footer={null}
			destroyOnClose
		>
			<StyledEditWorkoutModal data-cy="edit-workout-modal" className="sb-edit-workout-modal">
				<div className="sb-edit-workout-modal__header">
					<Heading10 className="sb-edit-workout-modal__header__title">Edit Workout</Heading10>
					<Paragraph12>Update your workout information below</Paragraph12>
				</div>
				<EditWorkoutForm
					isEditingLive={isEditingLive}
					liveStream={liveStream}
					handleCloseModal={handleCloseModal}
					uploading={uploading}
					setUploading={setUploading}
				/>
			</StyledEditWorkoutModal>
		</Modal>
	);
};

export default EditWorkoutModal;

