// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import ProfileBanner from "components/UI/ProfileBanner";
import { MemeberPayments } from "components/Payments/MemeberPayments";

// -----------------------------------------------------------------------------
// Styles and helpers
// -----------------------------------------------------------------------------
import StyledClientMembership from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ClientMembership = () => {
	return (
		<StyledClientMembership>
			<div className="sb-client-membership">
				<ProfileBanner title="Membership Info" picture={true} />
				<div className="sb-client-membership__body">
					<div className="sb-client-membership__body__inner">
						<MemeberPayments />
					</div>
				</div>
			</div>
		</StyledClientMembership>
	);
};

export default ClientMembership;

