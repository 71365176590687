import React from "react";
import Logo from "../../../images/logo.svg";

function SignupPagePreview({ type, gym, pageData, aspectRatio }) {
	return (
		<div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", borderRadius: "5px" }}>
			<div
				style={{
					display: "flex",
					height: "100%",
					width: "100%",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						height: "100%",
						flexDirection: "column",
						justifyContent: "space-around",
						paddingLeft: "5%",
					}}
				>
					<div style={{ height: "8px" }}>
						<img
							className="sb-login__container__form-container__header__image"
							src={Logo}
							alt="logo"
							style={{ height: "8px" }}
						/>
					</div>

					<div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
						<span style={{ fontWeight: 700, fontSize: "16px" }}>{pageData?.header_text}</span>
						<span style={{ fontWeight: 700, fontSize: "8px", color: "#4f5661" }}>{pageData?.sub_text}</span>
					</div>
					<div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
						<span style={{ fontSize: "8px", fontWeight: 700, color: "#4f5661" }}>PHONE NUMBER OR EMAIL ADDRESS</span>
						<div
							style={{
								width: "90%",
								border: ".5px solid #e3e5e7",
								borderRadius: "100px",
								height: "16px",
								boxSizing: "border-box",
							}}
						></div>
						<span style={{ fontSize: "8px", fontWeight: 700, color: "#4f5661" }}>PASSWORD</span>
						<div
							style={{
								width: "90%",
								border: ".5px solid #e3e5e7",
								borderRadius: "100px",
								height: "16px",
								boxSizing: "border-box",
							}}
						></div>{" "}
					</div>
					<div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
						<div
							style={{
								width: "90%",
								backgroundColor: "black",
								borderRadius: "100px",
								height: "20px",
								boxSizing: "border-box",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "white",
								fontSize: "8px",
								fotWeight: 700,
							}}
						>
							<span>LOGIN</span>
						</div>
						<div
							style={{
								width: "90%",
								backgroundColor: gym?.brand_color1 ? gym?.brand_color1 : "black",
								borderRadius: "100px",
								height: "20px",
								boxSizing: "border-box",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "white",
								fontSize: "8px",
								fotWeight: 700,
							}}
						>
							<span>SIGN UP</span>
						</div>
					</div>
					<div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
						<div
							style={{
								backgroundColor: "#f7f7f8",
								borderRadius: "3px",
								width: "90%",
								padding: "4px",
								paddingLeft: "8px",
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
							}}
						>
							<span style={{ fontSize: "6px" }}>
								Are you a gym, trainer, or studio and want to offer content to your members? <b>LEARN MORE</b>
							</span>
						</div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						backgroundColor: gym?.brand_color1 ? gym?.brand_color1 : "black",
						height: "480px",
						minWidth: "270px",
						backgroundImage: `url(${pageData?.header_image})`,
						backgroundSize: "cover",
						borderRadius: "5px",
					}}
				></div>
			</div>
		</div>
	);
}

export default SignupPagePreview;

