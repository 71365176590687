import React from "react";
import { useNavigate } from "react-router-dom";
import getStartedHero from "../../images/get-started-hero.png";
import logo from "../../images/logo.svg";
import { Link } from "react-router-dom";

import { LocationIcon } from "components/Icons";
import { Button } from "components/Common/Button";
import { Paragraph9 } from "themes/default/_typography";

function Header() {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "20px",
				paddingLeft: "50px",
				paddingRight: "50px",
				zIndex: 3,
			}}
		>
			<div style={{ zIndex: 3 }}>
				<Link to="/">
					<img src={logo} alt="logo" />
				</Link>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					zIndex: 3,
					gap: "16px",
				}}
			>
				<p
					style={{
						color: "white",
						padding: "10px",
						display: "flex",
						alignItems: "center",
						gap: "8px",
						fontFamily: "DM Sans",
						marginBottom: "0px",
						cursor: "pointer",
					}}
					onClick={() => navigate("/gym-search")}
				>
					<LocationIcon /> FIND MY GYM
				</p>
			</div>
		</div>
	);
}
function Footer() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-around",
				alignItems: "center",
				flexDirection: "column",
				zIndex: 3,
				paddingBottom: "0px",
				backgroundColor: "#1D2023",
				paddingTop: "100px",
			}}
		>
			<img src={logo} alt="logo" />
			<div
				style={{
					backgroundColor: "white",
					height: "1px",
					width: "90%",
					opacity: 0.1,
					marginTop: "100px",
					marginBottom: "32px",
				}}
			></div>
			<p style={{ color: "white", paddingBottom: "24px", opacity: 0.5 }}>
				© Copyright 2012 - 2018. All Rights Reserved.
			</p>
		</div>
	);
}

function NotFound() {
	const navigate = useNavigate();

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				backgroundColor: "#141618",
				minHeight: "100vh",
				flexDirection: "column",
			}}
		>
			<Header />
			<div
				style={{
					flex: 1,
					backgroundImage: `url(${getStartedHero})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					padding: 24,
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						marginTop: "10vh",
						textAlign: "center",
						zIndex: 3,
					}}
				>
					<p style={{ color: "white", fontSize: "36px", fontWeight: 400 }}>
						Oops. <br /> That page doesn’t exist
					</p>
					<Button className="primary-filled--branding-primary-color" uppercase onClick={() => navigate("/")}>
						<Paragraph9>Go Home</Paragraph9>
					</Button>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default NotFound;
