// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState, useEffect } from "react";
import { Form, Switch, Tooltip, notification, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import moment from "moment-timezone";
import axios from "axios";
import MuxUploader from "@mux/mux-uploader-react";
import { UndoOutlined } from "@ant-design/icons";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectAssetUploadId, selectSelectedWorkout } from "store/selectors";
import { uploadAssetToMux, editWorkout } from "store/slices";
import {
	useDeleteWorkoutMutation,
	useEditScheduledStreamMutation,
	useEditWorkoutMutation,
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetScheduledStreamsQuery,
	useGetMuxAuthenticatedUrlMutation,
	useGetS3SignedUrlMutation,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Button as AntButton } from "antd";
import { Heading4, Paragraph2, Span4 } from "themes/default/_typography";
import { FormInput } from "components/Common/Form/FormInput";
import { FormTextArea } from "components/Common/Form/FormTextArea";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import { FormTagSelect } from "components/Common/Form/FormTagSelect";
import { GenreSelector } from "components/Common/GenreSelector";
import ImageUpload from "components/ImageUpload";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import { FormTimeSelect } from "components/Common/Form/FormTimeSelect";
import { FormSelect } from "components/Common/Form/FormSelect";
import { FormCheckbox } from "components/Common/Form/FormCheckbox";
import { FormTimezoneSelect } from "components/Common/Form/FormTimezoneSelect";
import { validateRequiredEncodedLenth } from "helpers/Form/commonFormValidations";
import VideoPlayer from "components/UI/VideoPlayer/VideoPlayer";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import { getVideoMetadata } from "helpers/generic";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import ajaxRoutes from "utils/ajaxRoutes";
import Ajax from "utils/api";
import { maxImageAssetSize, imageSizeLimitError, defaultTimezone, durationOptions } from "helpers/constants";
import StyledEditWorkoutForm from "./styles";
import { decodeString, encodeString } from "utils";
import { Label2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EditWorkoutForm = ({ isEditingLive, liveStream, handleCloseModal, uploading, setUploading }) => {
	const dispatch = useDispatch();
	useRecoilValue(userAtom);
	const [videoFile, setVideoFile] = useState(null);
	const [thumbnailFile, setThumbnailFile] = useState(null);
	const [selectedGenre, setSelectedGenre] = useState({});
	const [muxDirectUploadUrl, setMuxDirectUploadUrl] = useState(null);
	const [addingNewVideo, setAddingNewVideo] = useState(false);
	const [isScheduledWorkout, setIsScheduledWorkout] = useState(false);
	const uploadId = useSelector(selectAssetUploadId);
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const [signedMuxPreviewUrl, setSignedMuxPreviewUrl] = useState(null);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const isPartnerGym = !!domainGymResponse.data?.is_partner_gym;
	const { data: categoriesData, refetch: refetchCategories } = useGetCategoriesQuery(domainGymId);
	const getWorkoutsResponse = useGetGymWorkoutsQuery({ gymId: domainGymId }, { skip: !domainGymResponse.data });
	const [editWorkoutTrigger] = useEditWorkoutMutation();
	const [editWorkoutSaveTrigger] = useEditWorkoutMutation();
	const [deleteWorkoutTrigger] = useDeleteWorkoutMutation();
	const [editScheduledStreamTrigger] = useEditScheduledStreamMutation();
	const { refetch: refetchStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const [cancellingScheduledRelease, setCancellingScheduledRelease] = useState(false);
	const [getMuxSignedUrlTrigger] = useGetMuxAuthenticatedUrlMutation();
	const [form] = Form.useForm();
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [currentDatetime, setCurrentDatetime] = useState(null);
	const [getS3SignedUrlTrigger] = useGetS3SignedUrlMutation();
	const [loading, setLoading] = useState(false);
	const [active, setIsActive] = useState(undefined);
	const [error, setError] = useState();

	const editWorkoutData = async (workoutData) => {
		try {
			if (workoutData.duration && uploadId) {
				workoutData.upload_id = uploadId;
			}
			await editWorkoutSaveTrigger({ workoutId: selectedWorkout.id, ...workoutData }).unwrap();
			notification.success({
				message: "Workout Updated",
			});
			await refetchCategories();
			dispatch(
				editWorkout({
					workoutId: selectedWorkout.id,
					...workoutData,
				})
			);
			if (isEditingLive) {
				refetchStreams();
			}
			handleCloseModal();
		} catch (error) {
			notification.error({
				message: "An error occurred while updating workout",
			});
		}
	};

	useEffect(() => {
		setSelectedGenre({ id: parseInt(selectedWorkout?.playlist_id) || undefined, title: selectedWorkout?.music_genre });
	}, [selectedWorkout?.music_genre, selectedWorkout?.playlist_id]);

	useEffect(() => {
		if (
			selectedWorkout &&
			!isEditingLive &&
			selectedWorkout.playback_id &&
			selectedWorkout.playback_id !== "null" &&
			selectedWorkout.start_time &&
			moment
				.tz(selectedWorkout.start_time, "YYYY-MM-DDTHH:mm:ss", selectedWorkout?.time_zone || defaultTimezone)
				.isAfter(moment()) &&
			selectedWorkout.is_scheduled
		) {
			setIsScheduledWorkout(true);
		}

		if (selectedWorkout) {
			setIsActive(selectedWorkout.draft === 0);
		}
	}, [selectedWorkout, isEditingLive]);

	// TODO (koechkevin) Fix this crap
	const onFinish = useCallback(
		async ({
			workoutCoach,
			workoutDescription,
			workoutTitle,
			workoutCategories,
			workoutEquipment,
			workoutIsFree,
			workoutDate,
			workoutTime,
			workoutTimezone,
			workoutDuration,
		}) => {
			if (loading || uploading || error) {
				return null;
			}
			const workoutIsFreeInt = workoutIsFree ? 1 : 0;
			const dataToUpdate = {};
			setLoading(true);

			if (videoFile && videoFile !== selectedWorkout.id) {
				try {
					const { duration } = await getVideoMetadata(videoFile.original);
					dispatch(uploadAssetToMux({ file: videoFile }));

					dataToUpdate.duration = duration;
				} catch (error) {
					console.error("error uploading video to mux", error);
				}
			}

			if (thumbnailFile && thumbnailFile !== selectedWorkout.id) {
				try {
					const s3KeyThumbnail = await uploadImageToS3();
					dataToUpdate.image_url = s3KeyThumbnail;
				} catch (error) {
					console.error("error uploading thumbnail to s3", error);
				}
			}

			if (workoutCoach && workoutCoach?.trim() !== selectedWorkout.coach) {
				dataToUpdate.coach = workoutCoach?.trim();
			}

			if (active !== undefined) {
				dataToUpdate.draft = +!active;
			}

			if (workoutIsFreeInt !== selectedWorkout.is_free && isPartnerGym) {
				dataToUpdate.is_free = workoutIsFreeInt;
			}

			if (workoutDescription && workoutDescription !== selectedWorkout.description) {
				dataToUpdate.description = encodeString(workoutDescription);
			}

			if (workoutTitle && workoutTitle !== selectedWorkout.name) {
				dataToUpdate.name = encodeString(workoutTitle);
			}

			if (workoutCategories) {
				const stringCategories = workoutCategories?.filter(Boolean)?.join(",")?.trim();
				const selectedWorkoutCategories = selectedWorkout.categories.map((c) => c.name).join(",");

				if (stringCategories !== selectedWorkoutCategories) {
					dataToUpdate.categories = stringCategories;
				}
			}

			if (workoutEquipment !== selectedWorkout.equipment) {
				dataToUpdate.equipment = workoutEquipment;
			}

			if (selectedWorkout.playlist_id?.toString() !== (selectedGenre.id?.toString() ?? null)) {
				dataToUpdate.musicGenre = selectedGenre.title || null;
				dataToUpdate.playlistId = selectedGenre.id || null;
			}

			if (workoutDate && workoutTime && workoutTimezone && isEditingLive) {
				const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
				const time = moment(workoutTime).format("HH:mm");
				const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
				const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
				await editScheduledStreamTrigger({
					workoutId: selectedWorkout.id,
					gym_id: domainGymId,
					start_time: iso,
					time_zone: workoutTimezone,
					currently_streaming: false,
				}).unwrap();
			}

			if (workoutDate && workoutTime && workoutTimezone && selectedWorkout.start_time) {
				const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
				const time = moment(workoutTime).format("HH:mm");
				const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
				const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
				dataToUpdate.start_time = iso;
				dataToUpdate.time_zone = workoutTimezone;
			}

			if (
				workoutDate &&
				workoutTime &&
				workoutTimezone &&
				isScheduledWorkout &&
				!cancellingScheduledRelease &&
				!active
			) {
				const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
				const time = moment(workoutTime).format("HH:mm");
				const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
				const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
				dataToUpdate.is_scheduled = 1;
				dataToUpdate.start_time = iso;
				dataToUpdate.time_zone = workoutTimezone;
			}

			if (selectedWorkout.start_time && cancellingScheduledRelease) {
				dataToUpdate.draft = 0;
				dataToUpdate.is_scheduled = 0;
				dataToUpdate.start_time = null;
				dataToUpdate.time_zone = null;
			}

			if (workoutDuration && workoutDuration !== selectedWorkout.duration) {
				dataToUpdate.duration = workoutDuration;
			}

			if (Object.keys(dataToUpdate).length || addingNewVideo) {
				dataToUpdate.gym_id = domainGymId;
				await editWorkoutData(dataToUpdate);
			} else {
				handleCloseModal();
			}
			//Check if only streaming time has changes, and if so, close Modal and refetch streams
			if (isEditingLive && workoutDate && workoutTime && workoutTimezone) {
				handleCloseModal();
				refetchStreams();
			}
			await getWorkoutsResponse.refetch();
			setLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			videoFile,
			selectedWorkout,
			thumbnailFile,
			selectedGenre.title,
			selectedGenre.id,
			isEditingLive,
			cancellingScheduledRelease,
			dispatch,
			editScheduledStreamTrigger,
			domainGymId,
			editWorkoutData,
			handleCloseModal,
			refetchStreams,
			loading,
			active,
		]
	);

	const uploadAsset = async (response, thumbnailFile) => {
		const formData = new FormData();

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		for (const field in response.fields) {
			formData.append(field, response.fields[field]);
		}

		formData.append("file", thumbnailFile);

		try {
			await axios.post(response.url, formData, { headers });
		} catch (error) {
			notification.error({
				message: "Error Uploading Image",
				description: "Please try again",
			});
		}
	};
	const uploadImageToS3 = async () => {
		const mimeMap = {
			"image/jpeg": "jpg",
			"image/png": "png",
		};
		let fileType = mimeMap[thumbnailFile.type];
		const s3KeyThumbnail = `assets/${domainGymId}/thumbnail-${new Date().getTime()}.${fileType}`;
		const s3SignedResponse = await getS3SignedUrlTrigger({ key: s3KeyThumbnail, type: "image" });
		await uploadAsset(s3SignedResponse.data.response, thumbnailFile);
		const fullS3ThumbNail = `${s3SignedResponse.data.response.url}/${s3KeyThumbnail}`;
		return fullS3ThumbNail;
	};

	const onApply = useCallback(() => {
		if (!videoFile) {
			setVideoFile(null);
		}
		if (!thumbnailFile) {
			setThumbnailFile(null);
		}
	}, [videoFile, thumbnailFile]);

	const onSaveThumbnail = useCallback((file) => {
		const blobToFile = new File([file], "workoutThumbnail.png", {
			type: "image/png",
		});
		setThumbnailFile(blobToFile);
	}, []);

	const onDeleteWorkout = async () => {
		const { error, data } = await deleteWorkoutTrigger({
			workoutID: selectedWorkout.id,
			gymID: selectedWorkout.gym_id,
		});
		setConfirmOpen(false);
		if (data) {
			getWorkoutsResponse.refetch();
			await refetchCategories();
			notification.success({ message: "Workout deleted successfully" });
			setTimeout(() => {
				handleCloseModal();
			}, 1000);
		}
		if (error) {
			notification.success({ message: "An error occurred while deleting a workout. Please try again" });
		}
	};

	const onCancelScheduledReleased = async () => {
		setCancellingScheduledRelease(true);
		setIsScheduledWorkout(false);
	};

	useEffect(() => {
		setSignedMuxPreviewUrl(null);

		if (selectedWorkout?.playback_id && selectedWorkout?.playback_id !== "null") {
			setPreviewVideo(selectedWorkout?.playback_id);
		}
	}, [selectedWorkout]);

	const setPreviewVideo = async (playbackId) => {
		try {
			const videoUrlResponse = await Ajax.call(ajaxRoutes.GET_MUX_VIDEO_URL(playbackId));
			setSignedMuxPreviewUrl(videoUrlResponse.url);
		} catch (error) {
			console.error("error: ", error);
		}
	};

	const getMuxSignedUrl = async () => {
		try {
			const { data } = await getMuxSignedUrlTrigger();
			setMuxDirectUploadUrl(data.url);
			// Make edit workout call to save the upload id so mux webhook can find and update workout
			editWorkoutUploadId(data.id);
		} catch (error) {
			console.error("error", error);
		}
	};

	const editWorkoutUploadId = async (uploadId) => {
		try {
			await editWorkoutTrigger({
				workoutId: selectedWorkout.id,
				gym_id: domainGymId,
				upload_id: uploadId,
			}).unwrap();
			getWorkoutsResponse.refetch();
			await refetchCategories();
		} catch (error) {
			console.error("error", error);
		}
	};

	const handleClickNewVideo = () => {
		setAddingNewVideo(true);
		getMuxSignedUrl();
	};

	const onChangeTitle = (e) => {
		const value = e.target?.value;
		const error = validateRequiredEncodedLenth(value, 255);
		setError(error || "");
	};

	return (
		<>
			<Modal
				title="Confirm"
				open={confirmOpen}
				onOk={onDeleteWorkout}
				onCancel={() => setConfirmOpen(false)}
				okText="Yes"
				cancelText="Go Back"
				maskClosable={false}
				destroyOnClose
			>
				Are you sure you want to delete this workout?
			</Modal>
			<StyledEditWorkoutForm>
				{selectedWorkout?.id && (
					<div className="sb-edit-workout-form">
						{!isEditingLive && (
							<div className="sb-edit-workout-form__upload">
								{/*
						Should make this a mux uploader component that loads if there is no video or if user selects something like "change video"
						*/}
								{signedMuxPreviewUrl && !addingNewVideo && (
									<VideoPlayer
										styleConfig={{
											objectFit: "cover",
											margin: "0 auto 20px auto",
											height: 200,
											width: "100%",
										}}
										options={{
											autoplay: false,
											controls: true,
											errorDisplay: false,
											sources: [
												{
													src: signedMuxPreviewUrl,
												},
											],
										}}
									/>
								)}
								{!addingNewVideo && (
									<div style={{ display: "flex", justifyContent: "center" }}>
										<Button autoRenewIcon className="primary-outlined--delete" uppercase onClick={handleClickNewVideo}>
											<Paragraph2>Change Workout Video</Paragraph2>
										</Button>
									</div>
								)}
								{muxDirectUploadUrl && addingNewVideo && (
									<MuxUploader
										onUploadStart={() => setUploading(true)}
										onSuccess={() => setUploading(false)}
										onError={() => setUploading(false)}
										endpoint={muxDirectUploadUrl}
									/>
								)}
								{videoFile === undefined && <FormErrorTag text="This field is required" />}
							</div>
						)}
						<div className="sb-edit-workout-form__upload">
							<ImageUpload
								className="sb-edit-workout-form__upload__input"
								onSave={onSaveThumbnail}
								onCancel={() => setThumbnailFile(null)}
								type="image"
								title="Upload Thumbnail"
								preImage={selectedWorkout.image_url}
								aspectRatio={300 / 180}
								sizeLimit={{
									limit: maxImageAssetSize,
									error: imageSizeLimitError,
								}}
							/>
							{thumbnailFile === undefined && <FormErrorTag text="This field is required" />}
						</div>
						{!isEditingLive && (
							<div className="sb-edit-workout-form__form__genres" style={{ paddingBottom: "8px" }}>
								<Heading4>Active:</Heading4>
								<Switch
									disabled={selectedWorkout.duration === 0 || selectedWorkout.on_demand === 3 || isScheduledWorkout}
									defaultChecked={selectedWorkout?.draft === 0}
									onChange={(checked) => setIsActive(checked)}
								/>
							</div>
						)}
						<Form
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									//Makes sure text field can still add new lines
									if (e?.target?.type !== "textarea") {
										e.preventDefault();
									}
								}
							}}
							form={form}
							onFinish={onFinish}
							layout="vertical"
							className="sb-edit-workout-form__form"
							initialValues={{
								workoutTitle: decodeString(selectedWorkout.name),
								workoutDescription: decodeString(selectedWorkout.description),
								workoutCoach: selectedWorkout.coach,
								workoutIsFree: selectedWorkout.is_free ? true : false,
								workoutCategories: selectedWorkout.categories
									? selectedWorkout.categories.map((c) => c.name.toString())
									: [],
								workoutEquipment: selectedWorkout.equipment,
								workoutDate: selectedWorkout?.start_time
									? moment.tz(
											selectedWorkout.start_time,
											"YYYY-MM-DDTHH:mm:ss",
											isEditingLive && liveStream
												? liveStream.time_zone
												: selectedWorkout.is_scheduled
												? selectedWorkout.time_zone || defaultTimezone
												: defaultTimezone
									  )
									: null,
								workoutTime: selectedWorkout.start_time
									? moment(
											moment.tz(
												selectedWorkout?.start_time,
												"YYYY-MM-DDTHH:mm:ss",
												isEditingLive && liveStream
													? liveStream.time_zone
													: selectedWorkout.is_scheduled
													? selectedWorkout.time_zone || defaultTimezone
													: defaultTimezone
											),
											"h:mm A"
									  )
									: null,
								workoutTimezone:
									isEditingLive && liveStream
										? liveStream.time_zone
										: selectedWorkout.is_scheduled
										? selectedWorkout.time_zone || defaultTimezone
										: defaultTimezone,
								workoutDuration:
									isEditingLive && liveStream
										? durationOptions.find(({ value }) => selectedWorkout.duration === value)?.value
										: null,
							}}
						>
							<FormInput
								name="workoutTitle"
								rules={[{ required: true, message: "This field is required" }]}
								label="Workout Title"
								hasFeedback={!!error}
								onChange={onChangeTitle}
								validateStatus={error ? "error" : undefined}
								help={error}
								autoFocus
							/>
							<FormTextArea
								name="workoutDescription"
								label="Workout Description"
								rules={[{ required: true, message: "This field is required" }]}
								rows={4}
							/>
							<FormInput
								name="workoutCoach"
								rules={[{ required: true, message: "This field is required" }]}
								label="Workout Coach"
							/>
							{categoriesData?.categories?.length > 0 && (
								<FormTagSelect
									name="workoutCategories"
									label="Workout Categories"
									placeholder="Enter Categories"
									options={categoriesData?.categories?.map((cat) => ({
										value: cat.name,
										id: cat.id,
									}))}
								/>
							)}
							<FormInput
								name="workoutEquipment"
								label={
									<span className="sb-workout-details-form__form__field__label">
										<span className="sb-workout-details-form__form__field__label__main">Equipment</span>
										<Span4 className="sb-workout-details-form__form__field__label__extra">(Separate With Commas)</Span4>
									</span>
								}
							/>

							<div style={{ display: "flex", width: "100%" }}>
								{isPartnerGym && (
									<FormCheckbox
										name="workoutIsFree"
										tooltipTitle="This is only for use by API Partners."
										label="Is Free"
									/>
								)}

								{!active && !selectedWorkout.is_scheduled && !isEditingLive && (
									<div className="sb-edit-workout-form__form__genres">
										<Label2 style={{ paddingBottom: "16px" }}>Schedule For Later?</Label2>
										<Tooltip title="Your workout will be activated for your members at the selected time">
											<Switch
												defaultChecked={isScheduledWorkout}
												onChange={(checked) => setIsScheduledWorkout(checked)}
											/>
										</Tooltip>
									</div>
								)}
							</div>

							{!isEditingLive &&
								selectedWorkout.start_time &&
								moment
									.tz(selectedWorkout.start_time, "YYYY-MM-DDTHH:mm:ss", selectedWorkout?.time_zone || defaultTimezone)
									.isAfter(moment()) && (
									<Span4 className="sb-workout-details-form__form__field__label__extra">Scheduled For Release</Span4>
								)}

							{(isEditingLive ||
								isScheduledWorkout ||
								(selectedWorkout.start_time &&
									moment
										.tz(
											selectedWorkout.start_time,
											"YYYY-MM-DDTHH:mm:ss",
											liveStream?.time_zone || selectedWorkout?.time_zone || defaultTimezone
										)
										.isAfter(moment()))) && (
								<div className="edit-time">
									<FormDateSelect
										name="workoutDate"
										label={<span style={{ whiteSpace: "nowrap" }}>Workout Date</span>}
										disabled={cancellingScheduledRelease}
										required={false}
										rules={[
											{ required: true, message: "This field is required" },
											() => ({
												validator(_, value) {
													const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
													const now = moment.tz(timezone).startOf("day");
													const selectedDate = moment(value).tz(timezone).startOf("day");

													if (selectedDate.isBefore(now)) {
														return Promise.reject(new Error("The selected date cannot be in the past."));
													}
													return Promise.resolve();
												},
											}),
										]}
										disabledDate={(currentDate) => {
											const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
											const now = moment.tz(timezone).startOf("day");
											const current = currentDate && currentDate.tz(timezone).startOf("day");

											return current && current.isBefore(now);
										}}
										onOpenChange={(open) => {
											if (!open) {
												const workoutDate = form.getFieldValue("workoutDate");
												const workoutTime = form.getFieldValue("workoutTime");

												if (moment(workoutDate).isValid()) {
													setCurrentDatetime(moment(workoutDate).format("YYYY-MM-DD"));
												}

												if (workoutTime) {
													form.validateFields(["workoutTime"]);
												}
											}
										}}
									/>
									<FormTimeSelect
										disabled={cancellingScheduledRelease}
										name="workoutTime"
										label="Time"
										form={form}
										rules={[
											{ required: true, message: "This field is required" },
											() => ({
												validator(_, value) {
													const workoutDate = form.getFieldValue("workoutDate");
													const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
													const now = moment.tz(timezone);

													if (workoutDate) {
														const selectedDateTime = moment.tz(
															`${moment(workoutDate).format("YYYY-MM-DD")} ${moment(value).format("HH:mm")}`,
															timezone
														);

														if (selectedDateTime.isBefore(now)) {
															return Promise.reject(new Error("The selected time cannot be in the past."));
														}
													}

													return Promise.resolve();
												},
											}),
										]}
										disabledTime={() => {
											const workoutDate = form.getFieldValue("workoutDate") || moment().startOf("day");
											const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;

											if (workoutDate) {
												const now = moment.tz(timezone);
												const selectedDate = moment(workoutDate).tz(timezone).startOf("day");

												if (selectedDate.isSame(now, "day")) {
													return {
														disabledHours: () => [...Array(now.hour()).keys()],
														disabledMinutes: (selectedHour) => {
															if (selectedHour === now.hour()) {
																return [...Array(now.minute()).keys()];
															}
															return [];
														},
													};
												}
											}
											return {
												disabledHours: () => [],
												disabledMinutes: () => [],
											};
										}}
										onOpenChange={(open) => {
											if (!open) {
												const workoutDate = form.getFieldValue("workoutDate");

												if (workoutDate) {
													form.validateFields(["workoutDate"]);
												}
											}
										}}
									/>{" "}
									<FormTimezoneSelect
										disabled={cancellingScheduledRelease}
										name="workoutTimezone"
										rules={[{ required: true, message: "This field is required" }]}
										label="Timezone"
										currentDatetime={currentDatetime}
										onChange={(newTimezone) => {
											const workoutDate = form.getFieldValue("workoutDate");
											const workoutTime = form.getFieldValue("workoutTime");

											if (workoutDate) {
												const formattedDate = moment(workoutDate).tz(newTimezone, true).startOf("day");
												form.setFieldsValue({
													workoutDate: formattedDate,
												});
											}

											if (workoutDate) {
												form.validateFields(["workoutDate"]);
											}
											if (workoutTime) {
												form.validateFields(["workoutTime"]);
											}
										}}
									/>
									{isEditingLive && (
										<FormSelect
											disabled={selectedWorkout.on_demand === 3}
											name="workoutDuration"
											placeholder="Workout Length"
											rules={[{ required: true, message: "This field is required" }]}
											label={<span style={{ whiteSpace: "nowrap" }}>Workout Length</span>}
											options={durationOptions}
											showSearch={false}
											mode="multiple"
										/>
									)}
								</div>
							)}
							{!isEditingLive &&
								selectedWorkout.start_time &&
								moment
									.tz(selectedWorkout.start_time, "YYYY-MM-DDTHH:mm:ss", selectedWorkout?.time_zone || defaultTimezone)
									.isAfter(moment()) && (
									<div style={{ width: "100%" }}>
										{!cancellingScheduledRelease && (
											<Tooltip placement="right" title="Your workout's active status will be applied immediately">
												<Button className="primary-outlined--delete" uppercase onClick={onCancelScheduledReleased}>
													<Paragraph2>Cancel Scheduled Release</Paragraph2>
												</Button>
											</Tooltip>
										)}
										{cancellingScheduledRelease && (
											<div style={{ gap: "16px", display: "flex", alignItems: "center" }}>
												<Span4>Your workout will be updated once changes are applied</Span4>
												<Tooltip title="Undo Schedule Cancellation">
													<AntButton
														onClick={() => setCancellingScheduledRelease(false)}
														shape="circle"
														icon={<UndoOutlined />}
													/>
												</Tooltip>
											</div>
										)}
									</div>
								)}
							<div className="sb-edit-workout-form__form__genres">
								<div className="sb-edit-workout-form__form__genres__title">
									<Heading4>Choose a Playlist</Heading4>
								</div>
								<GenreSelector initialSelectedGenreId={selectedGenre?.id} setSelectedGenre={setSelectedGenre} />
							</div>
							<div className="sb-edit-workout-form__form__actions">
								<Button
									type="button"
									className="primary-outlined--delete"
									uppercase
									onClick={() => setConfirmOpen(true)}
								>
									<Paragraph2>Delete Workout</Paragraph2>
								</Button>
								<Tooltip title={uploading ? "Video Upload in progress" : ""}>
									<Button
										loading={loading}
										type="submit"
										className="primary-filled"
										saveIcon
										uppercase
										onClick={onApply}
									>
										<Paragraph2>Apply Changes</Paragraph2>
									</Button>
								</Tooltip>
							</div>
						</Form>
					</div>
				)}
			</StyledEditWorkoutForm>
		</>
	);
};

export default EditWorkoutForm;

