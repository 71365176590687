import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

function Label({ children, tooltip, ...rest }) {
	return (
		<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
			<label className="form-label">{children}</label>
			{tooltip && (
				<Tooltip placement="right" title={tooltip}>
					<InfoCircleOutlined style={{ marginBottom: "4px" }} />
				</Tooltip>
			)}
		</div>
	);
}

export default Label;
