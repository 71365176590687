// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useRecoilValue } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { userAtom } from "../../../../globalAtoms";
import defaultHeader from "../../../../images/default-header.jpeg";
import userIcon from "../../../../images/user-icon.png";
import broadcastSVG from "../../../../images/broadcast.svg";
import playIcon from "../../../../images/play-icon.svg";
import { useNavigate } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function Banner({ title }) {
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const headerImage = user?.pages?.[0]?.header_image;
	const gymImage = user?.gymBranding?.light_logo;
	const gymName = user?.gym?.name;

	const renderWelcomeSection = () => {
		if (title) {
			return (
				<div className="sb-banner-container__inner__welcome__container">
					<h1 className="sb-banner-container__inner__welcome__container__title">{title}</h1>
				</div>
			);
		} else {
			return (
				<div className="sb-banner-container__inner__welcome__container">
					<h3 className="sb-banner-container__inner__welcome__container__sub-title">Welcome To</h3>
					<h1 className="sb-banner-container__inner__welcome__container__title">{gymName}</h1>
					<div className="sb-banner-container__inner__welcome__container__actions">
						<button className="sb-banner-container__inner__welcome__container__actions__on-demand">
							<img
								className="sb-banner-container__inner__welcome__container__actions__on-demand__icon"
								src={playIcon}
								alt="play"
							/>
							<span className="sb-banner-container__inner__welcome__container__actions__on-demand__text">
								On Demand
							</span>
						</button>
						<button className="sb-banner-container__inner__welcome__container__actions__live">
							<img
								className="sb-banner-container__inner__welcome__container__actions__on-demand__icon"
								src={broadcastSVG}
								alt="live"
							/>
							<span className="sb-banner-container__inner__welcome__container__actions__on-demand__text">Live</span>
						</button>
					</div>
				</div>
			);
		}
	};

	return (
		<div
			className="sb-banner-container"
			style={{
				backgroundImage: `url("${headerImage ? headerImage : defaultHeader}")`,
			}}
		>
			<div className="sb-banner-container__inner">
				<div className="sb-banner-container__inner__gym-profile">
					<div className="sb-banner-container__inner__gym-profile__gym" onClick={() => navigate("/dashboard")}>
						{gymImage && (
							<img className="sb-banner-container__inner__gym-profile__gym__icon" src={gymImage} alt="gym" />
						)}
						<span className="sb-banner-container__inner__gym-profile__gym__name">{gymName}</span>
					</div>
					<div className="sb-banner-container__inner__gym-profile__profile">
						<img className="sb-banner-container__inner__gym-profile__profile__icon" src={userIcon} alt="user" />
						<span
							className="sb-banner-container__inner__gym-profile__profile__text"
							onClick={() => navigate("../profile")}
						>
							My Profile
						</span>
					</div>
				</div>
				<div className="sb-banner-container__inner__welcome">{renderWelcomeSection()}</div>
			</div>
		</div>
	);
}

export default Banner;
