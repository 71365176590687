import React from "react";

const TimerIcon = (params) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19 7.37L20.13 6.24C20.2216 6.14654 20.2729 6.02088 20.2729 5.89C20.2729 5.75912 20.2216 5.63346 20.13 5.54L19.42 4.83C19.3735 4.78314 19.3182 4.74594 19.2573 4.72055C19.1964 4.69517 19.131 4.6821 19.065 4.6821C18.999 4.6821 18.9336 4.69517 18.8727 4.72055C18.8118 4.74594 18.7565 4.78314 18.71 4.83L17.6 6C16.5484 5.14667 15.3179 4.54164 14 4.23V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H11C10.7348 2 10.4804 2.10536 10.2929 2.29289C10.1054 2.48043 10 2.73478 10 3V4.23C8.01247 4.683 6.23759 5.79732 4.96592 7.39054C3.69425 8.98376 3.00112 10.9615 3 13C3.02627 15.3343 3.94764 17.5694 5.57397 19.2441C7.2003 20.9189 9.40748 21.9053 11.74 22C13.4564 22.046 15.1501 21.6 16.6212 20.7148C18.0924 19.8295 19.2797 18.5419 20.0429 17.0039C20.8061 15.4659 21.1135 13.7416 20.9287 12.0346C20.7439 10.3276 20.0747 8.70906 19 7.37ZM12 20C10.6155 20 9.26215 19.5895 8.11101 18.8203C6.95986 18.0511 6.06266 16.9579 5.53284 15.6788C5.00303 14.3997 4.86441 12.9922 5.1345 11.6344C5.4046 10.2765 6.07128 9.02922 7.05025 8.05025C8.02922 7.07129 9.2765 6.4046 10.6344 6.1345C11.9922 5.86441 13.3997 6.00303 14.6788 6.53284C15.9579 7.06266 17.0511 7.95986 17.8203 9.11101C18.5895 10.2622 19 11.6155 19 13C19 14.8565 18.2625 16.637 16.9497 17.9497C15.637 19.2625 13.8565 20 12 20ZM12.53 8C12.4615 7.99437 12.3926 8.00321 12.3278 8.02594C12.2629 8.04867 12.2036 8.08478 12.1536 8.13193C12.1036 8.17908 12.0641 8.23622 12.0377 8.29963C12.0112 8.36304 11.9984 8.43131 12 8.5V13L8.84 16.16C8.7901 16.2119 8.75177 16.2739 8.72753 16.3417C8.70329 16.4095 8.69368 16.4817 8.69935 16.5535C8.70502 16.6253 8.72583 16.6951 8.76042 16.7582C8.795 16.8214 8.84257 16.8765 8.9 16.92C9.4513 17.3546 10.088 17.6683 10.7685 17.8406C11.4491 18.0129 12.1583 18.04 12.85 17.92C14.0558 17.732 15.1512 17.1095 15.9298 16.1699C16.7084 15.2302 17.1165 14.0382 17.0771 12.8185C17.0378 11.5988 16.5537 10.4356 15.7162 9.54802C14.8786 8.66048 13.7454 8.10989 12.53 8Z"
			fill="black"
		/>
	</svg>
);

export default TimerIcon;

