import styled from "styled-components";

const StyledFooter = styled.div`
	background: ${({ theme }) => theme.colors.shark};

	.sb-footer {
		&__logo-section {
			width: 80%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 60px 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		}

		&__copyright-section {
			opacity: 0.5;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 24px 0;
		}
	}
`;

export default StyledFooter;
