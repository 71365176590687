import { getApiHost } from "helpers/constants";

const endpoint = getApiHost();

const verifyToken = () => {
	try {
		const token = localStorage.getItem("creator_jwt_token");
		const split = token.split(".")[1];
		const payload = JSON.parse(atob(split));
		const exp = payload.exp;
		const now = +new Date() / 1000;
		const hours = exp - now;
		if (hours > 0) {
			return payload;
		} else {
			localStorage.removeItem("creator_jwt_token");
			return null;
		}
	} catch (e) {
		return null;
	}
};

const ajaxRoutes = {
	SIGN_UP: `${endpoint}/users/creator`,
	EDIT_USER: `${endpoint}/users/edit`,
	GET_USER: () => {
		let user = verifyToken();
		if (user) return `${endpoint}/users/${user.id}`;
		return "";
	},
	CREATE_GYM: `${endpoint}/gyms`,
	EDIT_GYM: `${endpoint}/gyms/edit`,
	GET_USER_ASSOCIATIONS: () => {
		let user = verifyToken();
		if (user) return `${endpoint}/users/associations/${user.id}`;
		return "";
	},
	GET_GYM: (gymId) => `${endpoint}/gyms/${gymId}`,
	UPLOAD_GYM_ASSET: `${endpoint}/gyms/assets`,
	PROCESS_VIDEO: `https://upload.sweatbase.com/upload/process-video`,
	GET_GYM_BRANDING: (gymId) => `${endpoint}/gyms/branding/${gymId}`,
	GET_SIGNED_URL: `${endpoint}/workouts/get-signed-url`,
	GET_MUX_AUTHENTICATED_URL: `${endpoint}/mux/get-authenticated-mux-url`,
	GET_MUX_VIDEO_URL: (playback_id) => `${endpoint}/mux/get-mux-video-url/${playback_id}`,
	SET_USER_ASSOCIATION: `${endpoint}/users/associations`,
	EDIT_GYM_BRANDING: `${endpoint}/gyms/branding?`,
	UPLOAD_PAGE_ASSET: `${endpoint}/pages/assets`,
	CREATE_PAGE: `${endpoint}/pages`,
	GET_PAGES: (gymId) => `${endpoint}/pages/${gymId}`,
	FORGOT_PASSWORD: `${endpoint}/users/reset-password`,
	EDIT_PAGE: (gymId) => `${endpoint}/pages/${gymId}`,
	LOGIN: `${endpoint}/users/login`,
	UPDATE_SUBDOMAIN: `${endpoint}/gyms/update-subdomain`,
	UPLOAD_WORKOUT_ASSET: `${endpoint}/workouts/assets`,
	UPLOAD_WORKOUT_ASSET_NEW: `https://upload.sweatbase.com/upload/assets`,
	CREATE_WORKOUT: `${endpoint}/workouts`,
	GET_WORKOUTS_BY_GYM_ID: (gymId) => `${endpoint}/workouts/${gymId}?histogram=true`,
	GET_WORKOUT_BY_ID: (workoutId) => `${endpoint}/workout/${workoutId}`,
	EDIT_WORKOUT: (workoutId) => `${endpoint}/workouts/${workoutId}`,
	DELETE_WORKOUT: `${endpoint}/workouts`,
	CHECK_TWO_FACTOR_AUTHENTICATION: `${endpoint}/check-twofa`,
	EDIT_WORKOUT_CATEGORIES: `${endpoint}/edit-workout-categories`,
	CREATE_CATEGORY: `${endpoint}/categories/create`,
	EDIT_CATEGORY: `${endpoint}/categories/edit`,
	DELETE_CATEGORY: `${endpoint}/categories/delete`,
	GET_CATEGORIES: (gymId) => `${endpoint}/categories/get-all/${gymId}`,
	GET_GENRES: `${endpoint}/fit/genres`,
	GET_MUSIC: (playlistID) => `${endpoint}/music/playlist/${playlistID}`,
	RESET_PASSWORD: `${endpoint}/users/reset-password`,
	RESET_PASSWORD_CONFIRMATION: `${endpoint}/users/reset-password/confirmation`,
	SIGN_UP_CONSUMER_AT_GYM: (gymId) => `${endpoint}/users/gym/${gymId}`,
	GET_GYM_MEMBER_LIST: (gymId) => `${endpoint}/gyms/users/${gymId}`,
	START_WORKOUT: `${endpoint}/workout/start`,
	SCHEDULE_WORKOUT: `${endpoint}/workout/schedule`,
	DOMAINS_LOOKUP: (domain) => `${endpoint}/domains/${domain}`,
	DOMAINS_LOOKUP_EXACT: (domain) => `${endpoint}/domains/lookup/${domain}`,
	PAYMENTS_CREATE_SUBSCRIPTION: () => `${endpoint}/payments/create-subscription`,
	PAYMENTS_CONFIRM_SUBSCRIPTION: `${endpoint}/payments/creator/complete`,
	PAYMENTS_GET_CREATOR_PRODUCTS: `${endpoint}/payments/creator/products`,
	PAYMENTS_COMPLETE_CONNECT_ACCOUNT: `${endpoint}/payments/connect-account/complete`,
};

export default ajaxRoutes;
