// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";
// import { useSelector } from "react-redux";
import { PieChart, Pie, Sector } from "recharts";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
// import { selectDomainGym } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledDonutChart from "./styles";
// import { variables } from "themes/default/_variables";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const DonutChart = ({ data, size, labelSize }) => {
	const [activeIndex, setActiveIndex] = useState(
		data.indexOf(data.reduce((max, x) => (x.value > max.value ? x : max)))
	);

	// const domainGym = useSelector(selectDomainGym);

	// const data = [
	// 	{ name: "Group A", value: 400, fill: "#0088FE" },
	// 	{ name: "Group B", value: 300, fill: "#00C49F" },
	// 	{ name: "Group C", value: 300, fill: "#FFBB28" },
	// 	{ name: "Group D", value: 200, fill: "#FF8042" },
	// ];
	const renderCustomizedLabel = useCallback(
		({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
			const RADIAN = Math.PI / 180;
			const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + 16;
			const x = cx + radius * Math.cos(-midAngle * RADIAN);
			const y = cy + radius * Math.sin(-midAngle * RADIAN);

			return (
				<>
					<circle cx={x} cy={y} r={labelSize} />
					<text x={x} y={y} textAnchor="middle" dominantBaseline="central">
						{`${(percent * 100).toFixed(0)}%`}
					</text>
				</>
			);
		},
		[labelSize]
	);

	const renderActiveShape = useCallback(
		(props) => (
			<g>
				<Sector {...props} outerRadius={props.outerRadius * 1.1} />
			</g>
		),
		[]
	);

	const onPieEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);

	const onPieLeave = useCallback(() => {
		setActiveIndex(data.indexOf(data.reduce((max, x) => (x.value > max.value ? x : max))));
	}, [setActiveIndex, data]);

	return (
		<StyledDonutChart className="sb-donut-chart">
			<PieChart width={size + 5} height={size + 5} data={data}>
				<Pie
					activeIndex={activeIndex}
					data={data}
					width={size}
					height={size}
					cx={size / 2}
					cy={size / 2}
					activeShape={renderActiveShape}
					innerRadius={size * 0.24}
					outerRadius={size * 0.4}
					paddingAngle={0}
					label={renderCustomizedLabel}
					dataKey="value"
					onMouseEnter={onPieEnter}
					onMouseLeave={onPieLeave}
					minAngle={40}
				/>
			</PieChart>
		</StyledDonutChart>
	);
};

export default DonutChart;

