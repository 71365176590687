const initialState = {
	products: [],
	selectedProduct: null,
	selectedMembership: null,
	creatorAccount: null,
	creatorMemberships: null,
	creatorMembershipsLoading: true,
	memberMemberships: null,
	memberMembershipsLoading: true,
	productsLoading: true,
	creatorProduct: null,
	oneTimeLoginLink: null,
	errors: null,
	currentMembershipDetails: null,
};

export default initialState;

