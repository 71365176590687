import styled from "styled-components";

const StyledFormErrorTag = styled.div`
	.sb-form-error-tag {
		&__error {
			background: ${({ theme }) => theme.colors.forgetMeNot};
			width: auto;
			margin-top: 8px;
			padding: 5px 10px;
			border-radius: 100px;
		}
	}
`;

export default StyledFormErrorTag;
