import { userAtom } from "../globalAtoms";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export function useAutoLogout(gym) {
	const setUser = useSetRecoilState(userAtom);
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem("creator_jwt_token");
		const logout = () => {
			setUser(null);
			localStorage.removeItem("creator_jwt_token");
		};

		if (token) {
			try {
				const decodedToken = jwtDecode(token);
				const currentTime = Date.now() / 1000;

				if (decodedToken.exp < currentTime) {
					logout();
				} else {
					const timeUntilExpiration = decodedToken.exp * 1000 - Date.now();
					setTimeout(() => {
						logout();
					}, timeUntilExpiration);
				}
			} catch (error) {
				logout();
			}
		}
	}, [navigate, setUser]);
}

function useLogout(history, gym) {
	const setUser = useSetRecoilState(userAtom);
	const navigate = useNavigate();
	const logout = () => {
		setUser(null);
		localStorage.removeItem("creator_jwt_token");
	};

	useEffect(() => {
		// Set up an event listener for `storage` events
		const handleStorageChange = (event) => {
			if (event.key === "creator_jwt_token") {
				if (!event.newValue) {
					window.location.href = gym ? `/login/${gym}` : "/login";
					return;
				}
				if (event.newValue !== event.oldValue) {
					window.location.reload();
				}
			}
		};

		window.addEventListener("storage", handleStorageChange);

		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, [navigate, gym]);
	return {
		logout,
	};
}

export default useLogout;
