import React from "react";

const ChevronLeftIcon = (params) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M14.3083 16.7333C14.3474 16.7721 14.3784 16.8182 14.3995 16.8689C14.4207 16.9197 14.4316 16.9742 14.4316 17.0292C14.4316 17.0842 14.4207 17.1386 14.3995 17.1894C14.3784 17.2402 14.3474 17.2863 14.3083 17.325L13.425 18.2083C13.3876 18.2482 13.3424 18.28 13.2922 18.3017C13.2421 18.3234 13.188 18.3346 13.1333 18.3346C13.0787 18.3346 13.0246 18.3234 12.9744 18.3017C12.9243 18.28 12.8791 18.2482 12.8417 18.2083L5.18333 10.55C5.06609 10.4329 5.00015 10.274 5 10.1083V9.89167C5.00015 9.72597 5.06609 9.5671 5.18333 9.45L12.8417 1.79167C12.8791 1.75181 12.9243 1.72004 12.9744 1.69833C13.0246 1.67661 13.0787 1.66541 13.1333 1.66541C13.188 1.66541 13.2421 1.67661 13.2922 1.69833C13.3424 1.72004 13.3876 1.75181 13.425 1.79167L14.3083 2.675C14.3474 2.71374 14.3784 2.75982 14.3995 2.8106C14.4207 2.86137 14.4316 2.91583 14.4316 2.97084C14.4316 3.02584 14.4207 3.0803 14.3995 3.13108C14.3784 3.18185 14.3474 3.22794 14.3083 3.26667L7.575 10L14.3083 16.7333Z"
			fill="currentColor"
		/>
	</svg>
);

export default ChevronLeftIcon;
