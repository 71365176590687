import styled from "styled-components";

const StyledEarningsPage = styled.div`
	padding: 44px 40px;
	min-height: calc(100vh - 56px);
	max-width: 1400px;
	margin: 0 auto;

	.sb-earnings {
		&__top-section {
			margin-bottom: 40px;
			gap: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	@media (max-width: 1320px) {
		.sb-earnings {
			&__top-section {
				flex-direction: column;
			}
		}
	}
`;

export default StyledEarningsPage;
