import createSagaMiddleware from "redux-saga";
import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import {
	domainGymReducer as domainGym,
	authReducer as auth,
	gymsReducer as gyms,
	membersReducer as members,
	categoriesReducer as categories,
	assetsReducer as assets,
	workoutsReducer as workouts,
	fitReducer as fit,
	usersReducer as users,
	paymentsReducer as payments,
	filtersReducer as filters,
	uiReducer as ui,
} from "./slices";
import rootSaga from "store/sagas";
import { apiSlice } from "./api/api";
import Sentry from "../instrument";

const logger = (store) => (next) => (action) => {
	return next(action);
};
const rtkQueryErrorLogger = (api) => (next) => (action) => {
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
	if (isRejectedWithValue(action)) {
		//api.dispatch(uiReducer.setErrorAlert(action.payload.data.error));
		//toast.warn({ title: 'Async error!', message: action.error.data.message })
	}

	return next(action);
};

//const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
	reducer: {
		domainGym,
		auth,
		gyms,
		members,
		categories,
		assets,
		workouts,
		fit,
		users,
		payments,
		filters,
		ui,
		[apiSlice.reducerPath]: apiSlice.reducer,
	},
	enhancers: (getDefaultEnhancers) => {
		return getDefaultEnhancers.concat(sentryReduxEnhancer);
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			// thunk: false,
			// serializableCheck: {
			// 	ignoredActions: ["gyms/saveGymBranding", "assets/uploadAssetToS3", "assets/uploadAssetToMux"],
			// },
		})
			//.concat(sagaMiddleware)
			.concat(apiSlice.middleware)
			.concat(logger),
	devTools: true,
});

setupListeners(store.dispatch);

//sagaMiddleware.run(rootSaga);

export default store;
