// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import CreateAccount from "../CreateAccount";
import YourInformation from "../YourInformation";
import SetupProfile from "../SetupProfile";
import ContactInformation from "../ContactInformation";
import MemberPages from "../MemberPages";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function SwitchForm({ match, user }) {
	return (
		<div>
			<Routes>
				<Route exact path={"/"} element={<CreateAccount />} />
				<Route path={`your-information`} element={<YourInformation />} />
				<Route path={`setup-profile`} element={<SetupProfile />} />
				<Route path={`contact-information`} element={<ContactInformation />} />
				<Route path={"/member-pages"} element={<MemberPages />} />
				<Route path="*" element={<Navigate to="/" replace />} />
				{/* {!user && redirect("path")} */}
			</Routes>
		</div>
	);
}

export default SwitchForm;
