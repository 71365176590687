import styled from "styled-components";

const StyledSubdomainForm = styled.div`
	.sb-subdomain-form {
		&__alert {
			margin-bottom: 16px;
		}

		&__label {
			font-weight: bold;
		}

		&__input {
			margin-bottom: 16px;
		}
	}
`;

export default StyledSubdomainForm;
