import React from "react";

const PencilIcon = (params) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M16.81 10.0001C16.9016 10.0936 16.9529 10.2192 16.9529 10.3501C16.9529 10.481 16.9016 10.6067 16.81 10.7001L8.75 18.7901C8.55664 18.9852 8.32517 19.1384 8.07 19.2401L3.73 21.0001C3.63912 21.0367 3.53948 21.0458 3.44348 21.0262C3.34748 21.0067 3.25934 20.9593 3.19 20.8901C3.08983 20.8262 3.01568 20.7287 2.98087 20.6151C2.94605 20.5015 2.95283 20.3792 3 20.2701L4.72 15.9301C4.82172 15.675 4.9749 15.4435 5.17 15.2501L13.24 7.18011C13.2865 7.13325 13.3418 7.09605 13.4027 7.07067C13.4636 7.04528 13.529 7.03222 13.595 7.03222C13.661 7.03222 13.7264 7.04528 13.7873 7.07067C13.8482 7.09605 13.9035 7.13325 13.95 7.18011L16.81 10.0001ZM20.56 4.10011L19.9 3.44011C19.619 3.15873 19.2377 3.00047 18.84 3.00011H18.08C17.8797 2.99764 17.681 3.03529 17.4955 3.11086C17.31 3.18643 17.1415 3.29838 17 3.44011L15.19 5.27011C15.0984 5.36358 15.0471 5.48924 15.0471 5.62011C15.0471 5.75099 15.0984 5.87665 15.19 5.97011L18 8.82011C18.0465 8.86698 18.1018 8.90418 18.1627 8.92956C18.2236 8.95494 18.289 8.96801 18.355 8.96801C18.421 8.96801 18.4864 8.95494 18.5473 8.92956C18.6082 8.90418 18.6635 8.86698 18.71 8.82011L20.56 7.00011C20.7017 6.85859 20.8137 6.69011 20.8893 6.50463C20.9648 6.31914 21.0025 6.12039 21 5.92011V5.16011C20.9997 4.76242 20.8414 4.38115 20.56 4.10011Z"
			fill="currentColor"
		/>
	</svg>
);

export default PencilIcon;
