// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Table, Popover, Checkbox } from "antd";
import { FilterOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { useGetGymMembersByDateQuery } from "store/api/api";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Paragraph30 } from "themes/default/_typography";
import { LoadingMask } from "components/Common/LoadingMask";
import { SearchInput } from "components/Common/Form/SearchInput";
import { Button } from "components/Common/Button";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembersTable from "./styles";

const plainOptions = {
	ACTIVE: "Members",
	TRIAL: "Free",
	CANCELLED: "Canceled",
	CREATOR: "Creator",
};
const CheckboxGroup = Checkbox.Group;

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembersTable = (props) => {
	const { dateRange } = props;
	const [checkedList, setCheckedList] = useState(Object.values(plainOptions));
	const [checkAll, setCheckAll] = useState(true);
	const [tableData, setTableData] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);
	const gymMembersResponse = useGetGymMembersByDateQuery({
		startDate: dateRange.startDate.format("YYYY-MM-DD"),
		endDate: dateRange.endDate.format("YYYY-MM-DD"),
	});
	const members = gymMembersResponse?.data;
	const membersLoading = gymMembersResponse.isLoading || gymMembersResponse.isFetching;

	useEffect(() => {
		if (members?.subscribers) {
			setTableColumns([
				{
					title: "Member",
					dataIndex: "member",
					key: "member",
				},
				{
					title: "Email",
					dataIndex: "email",
					key: "email",
				},
				{
					title: "Member Type",
					dataIndex: "memberType",
					key: "memberType",
				},
				{
					title: "Last Active",
					dataIndex: "lastLoginDate",
					key: "lastLoginDate",
				},
				{
					title: "Joined",
					dataIndex: "joined",
					key: "joined",
				},
			]);

			setTableData(
				members.members.map((m) => ({
					key: m.user_id,
					member: `${m.first_name ?? ""} ${m.last_name ?? ""}`.trim(),
					email: m.email_address,
					memberType: m.subscription_status,
					lastLoginDate: moment(m.last_login_at, "YYYY-MM-DD").format("MMM D, YYYY"),
					joined: moment(m.created_date, "YYYY-MM-DD").format("MMM D, YYYY"),
				}))
			);
		}
	}, [members]);

	useEffect(() => {
		if (members?.subscribers) {
			setTableData(
				members.subscribers
					.map((m) => {
						return {
							key: m.user_id,
							member: `${m.first_name ?? ""} ${m.last_name ?? ""}`.trim(),
							email: m.email_address,
							memberType: m.type === "trainer" ? "CREATOR" : m.subscription_status,
							lastLoginDate: moment(m.last_login_at, "YYYY-MM-DD").format("MMM D, YYYY"),
							joined: moment(m.created_date, "YYYY-MM-DD").format("MMM D, YYYY"),
						};
					})
					.filter((m) => (checkAll ? true : checkedList.includes(plainOptions[m.memberType])))
			);
		}
	}, [checkedList, members, checkAll]);

	const tableHeaders = useMemo(
		() =>
			tableColumns?.map(
				(column) =>
					({
						label: column.title,
						key: column.key,
					} || [])
			),
		[tableColumns]
	);

	const handleSearchTermEnter = useCallback(
		(value) => {
			setTableData(
				members.subscribers
					.map((m) => ({
						key: m.user_id,
						member: `${m.first_name ?? ""} ${m.last_name ?? ""}`.trim(),
						email: m.email_address,
						memberType: m.subscription_status,
						lastLoginDate: moment(m.last_login_at, "YYYY-MM-DD").format("MMM D, YYYY"),
						joined: moment(m.created_date, "YYYY-MM-DD").format("MMM D, YYYY"),
					}))
					.filter((m) =>
						value.trim()
							? m.member.toLowerCase().includes(value.toLowerCase()) ||
							  m.email.toLowerCase().includes(value.toLowerCase())
							: true
					)
			);
		},
		[members]
	);

	const onFilterChange = (list) => {
		setCheckedList(list);
		setCheckAll(list.length === Object.values(plainOptions).length);
	};

	const onCheckAllChange = (e) => {
		setCheckedList(e?.target?.checked ? Object.values(plainOptions) : []);
		setCheckAll(e?.target?.checked);
	};

	return (
		<StyledMembersTable className="sb-members-table">
			{membersLoading || !tableData || !tableColumns ? (
				<LoadingMask />
			) : (
				<>
					<div className="sb-members-table__actions">
						<div className="sb-members-table__actions__filters">
							<SearchInput placeholder="Search Members" onEnter={handleSearchTermEnter} />
							<Popover
								placement="bottomRight"
								title={
									<div className="sb-popover-filter-header">
										<Paragraph30>Filters</Paragraph30>
										<Checkbox onChange={onCheckAllChange} checked={checkAll}>
											Check all
										</Checkbox>
									</div>
								}
								content={
									<CheckboxGroup options={Object.values(plainOptions)} value={checkedList} onChange={onFilterChange} />
								}
								trigger="click"
							>
								<button>
									<FilterOutlined />
									Filters
								</button>
							</Popover>
						</div>
						<div className="sb-members-table__actions__download">
							<CSVLink
								data={tableData}
								headers={tableHeaders}
								filename={`members-${new Date().getTime()}.csv`}
								target="_blank"
							>
								<Button className="primary-filled" uppercase downloadIcon>
									Download
								</Button>
							</CSVLink>
						</div>
					</div>
					<Table scroll={{ x: 400 }} dataSource={tableData} columns={tableColumns} />
				</>
			)}
		</StyledMembersTable>
	);
};

export default MembersTable;

