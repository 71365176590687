export const getLocalUser = () => {
	try {
		const token = localStorage.getItem("creator_jwt_token");
		const split = token.split(".")[1];
		const formatted = JSON.parse(atob(split));
		return JSON.parse(atob(split));
	} catch (e) {
		return null;
	}
};

