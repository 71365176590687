// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// -------------------------------------------------------------------------------
// Component, selectors and others
// -------------------------------------------------------------------------------
import { Paragraph11 } from "themes/default/_typography";
import { PlanCard } from "components/Common/Cards/PlanCard";
import { BasicCard } from "components/Common/Cards/BasicCard";
import { LoadingMask } from "components/Common/LoadingMask";
import ContactSalesModal from "components/Modals/ContactSalesModal";
import { GearIcon } from "components/Icons";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { selectProducts } from "store/selectors";
import { updateSelectedProduct, updateCurrentMembershipDetails } from "store/slices";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { paymentPlans } from "helpers/constants";
import StyledPlanSelection from "./styles";
import {
	useStripeCreateSubscriptionToPlatformMutation,
	useStripeGetCustomerSubscriptionsQuery,
	useGetProductsQuery,
	useStripeCreateSetupIntentMutation,
	useGetDomainGymQuery,
	useStripeCreateSubscriptionPriceMutation,
	useStripeGetSubscriptionsForGymMutation,
	useStripeUpdateMembershipPricingMutation,
} from "store/api/api";
import AddPaymentMethodModal from "components/Modals/AddPaymentMethodModal";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const PlanSelection = ({ selectedColor, className, handleCompleteSubscribe, ...other }) => {
	const { gym } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const [clientSecret, setClientSecret] = useState(null);
	const [setupIntentId, setSetupIntentId] = useState(null);
	const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
	const [showContactSalesModal, setShowContactSalesModal] = useState(false);
	const { data, isLoading } = useGetProductsQuery(selectProducts);
	const domainGymResponse = useGetDomainGymQuery(gym);
	const [createSubscriptionToPlatformTrigger] = useStripeCreateSubscriptionToPlatformMutation();
	const getCustomerSubscriptionsResponse = useStripeGetCustomerSubscriptionsQuery({
		customerId: user.customer_id,
	});
	const [createSetupIntentTrigger, createSetupIntentResponse] = useStripeCreateSetupIntentMutation();
	const [createSubscriptionPriceTrigger] = useStripeCreateSubscriptionPriceMutation();
	const [updateMembershipPricingTrigger, updateMembershipPricingResponse] = useStripeUpdateMembershipPricingMutation();
	const [getSubscriptionsForGymTrigger] = useStripeGetSubscriptionsForGymMutation();

	const [subscribing, setSubscribing] = useState(false);

	const currentSubscription = getCustomerSubscriptionsResponse.data?.subscriptions?.[0]?.plan?.product?.name;

	useEffect(() => {
		if (updateMembershipPricingResponse.isSuccess) {
			const newMembershipDetails = {
				id: updateMembershipPricingResponse?.data?.product,
				product: updateMembershipPricingResponse?.data?.product,
				unit_amount: updateMembershipPricingResponse?.data?.unit_amount,
				type: updateMembershipPricingResponse?.data?.type,
				membersPay: updateMembershipPricingResponse?.data?.unit_amount / 100,
				default_price: updateMembershipPricingResponse?.data?.id,
			};
			dispatch(updateCurrentMembershipDetails({ currentMembershipDetails: newMembershipDetails }));
		}
	}, [dispatch, updateMembershipPricingResponse]);

	const createSetupIntent = async () => {
		try {
			await createSetupIntentTrigger({
				customerId: user.customer_id,
			});
		} catch (error) {
			console.debug("error Creating Setup Intent: ", error);
		}
	};

	useEffect(() => {
		if (createSetupIntentResponse.isSuccess) {
			setClientSecret(createSetupIntentResponse.data.client_secret);
			setSetupIntentId(createSetupIntentResponse.data.id);
			setShowPaymentMethodModal(true);
		} else if (createSetupIntentResponse.isError) {
			console.debug("error Creating Setup Intent: ", createSetupIntentResponse.error);
		}
	}, [createSetupIntentResponse]);

	const createSubscriptionToPlatform = async (product) => {
		try {
			await createSubscriptionToPlatformTrigger({
				accountId: user.connected_account_id,
				customerId: user.customer_id,
				productName: product.productName,
			});

			const gymProductResponse = await getSubscriptionsForGymTrigger({
				gymName: domainGymResponse?.data?.name,
			})
				.unwrap()
				.catch(() => null);

			const gymProductPrice =
				gymProductResponse && !isNaN(Number(gymProductResponse?.unit_amount))
					? Number(gymProductResponse.unit_amount)
					: null;

			// If there is no product for the gym, create one
			// If product exists but price below the minimum, update the price to the minimum
			// If product exists and price is above the minimum, use the existing product
			if (!gymProductResponse) {
				const priceSubscription = await createSubscriptionPriceTrigger({
					accountId: user.connected_account_id,
					productName: `Monthly: ${domainGymResponse?.data?.name}`,
					price: product.minMembersPrice * 100,
				}).unwrap();

				const currentMembershipDetails = {
					id: priceSubscription.id,
					product: priceSubscription.id,
					unit_amount: priceSubscription.default_price.unit_amount,
					type: priceSubscription.type,
					membersPay: priceSubscription.default_price.unit_amount / 100,
					default_price: priceSubscription.default_price.id,
				};

				dispatch(updateCurrentMembershipDetails({ currentMembershipDetails }));
			} else if (gymProductPrice < product.minMembersPrice * 100) {
				await updateMembershipPricingTrigger({
					productId: gymProductResponse.id,
					newPrice: product.minMembersPrice * 100,
				}).unwrap();
			} else {
				const currentMembershipDetails = {
					id: gymProductResponse.id,
					product: gymProductResponse.id,
					unit_amount: gymProductResponse.unit_amount,
					type: gymProductResponse.type,
					membersPay: gymProductResponse.unit_amount / 100,
					default_price: gymProductResponse.default_price,
				};

				dispatch(updateCurrentMembershipDetails({ currentMembershipDetails }));
			}

			if (handleCompleteSubscribe) {
				handleCompleteSubscribe?.();
				setSubscribing(false);
			} else {
				navigate(-1);
			}
		} catch (error) {
			console.debug("error Creating Subscription: ", error);
			setSubscribing(false);
			// Show Error Message Here
		}
	};

	const handleSelectPlan = (product) => {
		dispatch(updateSelectedProduct({ product }));

		if (product.type === "starter") {
			// Stripe Create Subscription to Platform Call Here
			setSubscribing(true);
			createSubscriptionToPlatform(product);
		} else {
			// Stripe Create Setup Intent Call Here
			createSetupIntent();
		}
	};

	return (
		<StyledPlanSelection className={`${className} sb-plan-selection`} {...other}>
			<div className="sb-plan-selection__plans-container">
				{isLoading || subscribing ? (
					<LoadingMask />
				) : (
					<>
						{data?.products?.map((product) =>
							paymentPlans[product.type] ? (
								<PlanCard
									isCurrentSubscription={currentSubscription === paymentPlans[product.type].productName}
									type={product.type}
									onClick={() =>
										handleSelectPlan({
											...product,
											productName: paymentPlans[product.type].productName,
											minMembersPrice: paymentPlans[product.type].minMembersPrice,
										})
									}
									key={paymentPlans[product.type].title}
									icon={paymentPlans[product.type].icon}
									title={paymentPlans[product.type].title}
									price={paymentPlans[product.type].price}
									priceId={paymentPlans[product.type].price_id}
									priceDescription={paymentPlans[product.type].priceDescription}
									description={paymentPlans[product.type].description}
									featuresList={paymentPlans[product.type].featuresList}
									priceFrequency={paymentPlans[product.type].priceFrequency}
								/>
							) : null
						)}
						<PlanCard
							type="elite"
							onClick={() => setShowContactSalesModal(true)}
							key={paymentPlans["elite"].title}
							icon={paymentPlans["elite"].icon}
							title={paymentPlans["elite"].title}
							price={paymentPlans["elite"].price}
							priceDescription={paymentPlans["elite"].priceDescription}
							description={paymentPlans["elite"].description}
							featuresList={paymentPlans["elite"].featuresList}
							priceFrequency={paymentPlans["elite"].priceFrequency}
						/>
					</>
				)}
			</div>
			<div className="sb-plan-selection__disclaimer">
				<BasicCard icon={<GearIcon />}>
					<Paragraph11>
						Pro and Studio PRO tiers are designed for gyms and studios and allow you to include SWEATBASE as part of
						your membership, or charge separately. You can decide to charge as much or as little as you wish. These
						tiers include a bundle of song plays. These plays do not roll over from month to month and your account will
						be charged at $.03 per song per user once the included plays are exceeded.
					</Paragraph11>
				</BasicCard>
			</div>
			<ContactSalesModal open={showContactSalesModal} setOpen={setShowContactSalesModal} />
			<AddPaymentMethodModal
				setupIntentId={setupIntentId}
				open={showPaymentMethodModal}
				setOpen={setShowPaymentMethodModal}
				clientSecret={clientSecret}
			/>
		</StyledPlanSelection>
	);
};

export default PlanSelection;
