import React from "react";

const FullLogoIcon = () => (
	<svg width="160" height="24" viewBox="0 0 160 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.68641 18.8465C3.37293 20.8703 6.06082 21.7525 8.66965 21.7525C11.8319 21.7525 15.363 20.0919 15.363 16.5633C15.363 10.2065 5.11215 10.6217 5.11215 7.50815C5.11215 5.69194 6.64056 5.14707 8.22167 5.14707C9.82913 5.14707 11.3048 5.76977 12.4907 6.80761C12.6224 6.93734 12.8069 6.93734 12.965 6.78166L14.5725 4.99139C14.7042 4.86167 14.7042 4.6541 14.5461 4.52437C12.8069 3.09734 10.5933 2.08545 8.32707 2.08545C5.00674 2.08545 2.00264 3.84977 2.00264 7.40437C2.00264 13.346 12.2535 12.9827 12.2535 16.4595C12.2535 18.1719 10.1717 18.6909 8.77506 18.6909C7.06219 18.6909 5.29661 18.2757 4.13713 16.9525C4.00537 16.7968 3.82091 16.7968 3.6628 16.9006L1.73912 18.3795C1.58101 18.5092 1.55465 18.6909 1.68641 18.8465Z"
			fill="white"
		/>
		<path
			d="M16.8039 3.14923L24.3405 21.026C24.3933 21.1557 24.5514 21.2595 24.7358 21.2595C24.8939 21.2595 25.052 21.1557 25.1047 21.026L29.0312 12.1265C29.1102 11.919 29.242 11.8411 29.321 11.8411C29.4264 11.8411 29.5319 11.9449 29.6109 12.1265L33.5373 21.026C33.59 21.1557 33.7481 21.2595 33.9326 21.2595C34.0907 21.2595 34.2488 21.1557 34.3015 21.026L35.4347 18.3276C35.5137 18.146 35.5137 17.8865 35.4347 17.7049L29.7163 5.04329C29.6636 4.91356 29.5055 4.80977 29.321 4.80977C29.1629 4.80977 28.9785 4.91356 28.9258 5.04329L25.1311 13.5017C25.0257 13.7092 24.9466 13.813 24.8412 13.813C24.7358 13.813 24.6041 13.7092 24.525 13.5017L20.1506 3.14923C20.0716 2.96761 19.8871 2.83788 19.6763 2.83788H17.0147C16.8039 2.83788 16.7249 2.96761 16.8039 3.14923ZM34.9603 12.1265L36.4624 15.3179C36.5414 15.4995 36.6469 15.4995 36.7259 15.3179L41.8382 3.14923C41.9172 2.96761 41.8382 2.83788 41.6273 2.83788H38.9922C38.7813 2.83788 38.5969 2.96761 38.5178 3.14923L34.9603 11.5038C34.8813 11.7114 34.8813 11.9449 34.9603 12.1265Z"
			fill="white"
		/>
		<path
			d="M44.5057 13.0346C44.5057 13.2422 44.6374 13.3719 44.8482 13.3719H56.1005C56.3113 13.3719 56.4431 13.2422 56.4431 13.0346V10.8033C56.4431 10.5957 56.3113 10.466 56.1005 10.466H44.8482C44.6374 10.466 44.5057 10.5957 44.5057 10.8033V13.0346ZM44.5057 18.4314V20.6627C44.5057 20.8703 44.6374 21 44.8482 21H56.1005C56.3113 21 56.4431 20.8703 56.4431 20.6627V18.4314C56.4431 18.2238 56.3113 18.0941 56.1005 18.0941H44.8482C44.6374 18.0941 44.5057 18.2238 44.5057 18.4314ZM44.8482 5.74383H56.1005C56.3113 5.74383 56.4431 5.6141 56.4431 5.40653V3.17518C56.4431 2.96761 56.3113 2.83788 56.1005 2.83788H44.8482C44.6374 2.83788 44.5057 2.96761 44.5057 3.17518V5.40653C44.5057 5.6141 44.6374 5.74383 44.8482 5.74383Z"
			fill="white"
		/>
		<path
			d="M59.3218 21H61.9306C62.1151 21 62.3259 20.8703 62.4049 20.6887L67.5172 9.7914C67.6226 9.58383 67.728 9.48004 67.8334 9.48004C67.9388 9.48004 68.0442 9.58383 68.1496 9.7914L70.5213 14.8768C70.7058 15.2919 70.5477 15.5514 70.0997 15.5514H67.4645C67.2537 15.5514 67.0692 15.6811 66.9902 15.8627L65.9097 18.146C65.8307 18.3276 65.9097 18.4573 66.1206 18.4573H71.4436C71.8916 18.4573 72.3132 18.7168 72.4977 19.106L73.2355 20.6887C73.3146 20.8703 73.5254 21 73.7362 21H76.3187C76.5295 21 76.6086 20.8703 76.5295 20.6887L68.2023 2.81194C68.1496 2.68221 67.9915 2.57842 67.8334 2.57842C67.6753 2.57842 67.4908 2.68221 67.4381 2.81194L59.111 20.6887C59.0319 20.8703 59.111 21 59.3218 21Z"
			fill="white"
		/>
		<path
			d="M76.1777 5.74383H90.6712C90.882 5.74383 91.0138 5.6141 91.0138 5.40653V3.17518C91.0138 2.96761 90.882 2.83788 90.6712 2.83788H76.1777C75.9669 2.83788 75.8351 2.96761 75.8351 3.17518V5.40653C75.8351 5.6141 75.9669 5.74383 76.1777 5.74383ZM81.817 20.6627C81.817 20.8703 81.9751 21 82.1596 21H84.6366C84.8474 21 84.9792 20.8703 84.9792 20.6627V8.59788C84.9792 8.39031 84.8474 8.26058 84.6366 8.26058H82.1596C81.9751 8.26058 81.817 8.39031 81.817 8.59788V20.6627Z"
			fill="white"
		/>
		<path
			d="M94.3193 21H101.856C107.917 21 109.208 14.1503 105.703 11.2444C105.361 10.959 105.308 10.5698 105.519 10.1806C107.047 7.35248 105.651 3.30491 101.118 2.86383C100.828 2.83788 100.38 2.83788 99.616 2.83788H94.3193C94.1085 2.83788 93.9767 2.96761 93.9767 3.17518V20.6627C93.9767 20.8703 94.1085 21 94.3193 21ZM97.1389 13.6054C97.1389 13.1644 97.4288 12.879 97.8768 12.879H101.856C105.203 12.879 105.203 18.0941 101.856 18.0941H97.8768C97.4288 18.0941 97.1389 17.8087 97.1389 17.3676V13.6054ZM97.1389 9.22058V6.44437C97.1389 6.00329 97.4288 5.71788 97.8768 5.71788H100.38C103.542 5.71788 103.701 9.58383 100.802 9.92112C100.538 9.94707 100.064 9.94707 99.3261 9.94707H97.8768C97.4288 9.94707 97.1389 9.66167 97.1389 9.22058Z"
			fill="white"
		/>
		<path
			d="M109.581 21H112.19C112.374 21 112.585 20.8703 112.664 20.6887L117.776 9.7914C117.882 9.58383 117.987 9.48004 118.092 9.48004C118.198 9.48004 118.303 9.58383 118.409 9.7914L120.78 14.8768C120.965 15.2919 120.807 15.5514 120.359 15.5514H117.723C117.513 15.5514 117.328 15.6811 117.249 15.8627L116.169 18.146C116.09 18.3276 116.169 18.4573 116.379 18.4573H121.703C122.151 18.4573 122.572 18.7168 122.757 19.106L123.494 20.6887C123.574 20.8703 123.784 21 123.995 21H126.578C126.788 21 126.867 20.8703 126.788 20.6887L118.461 2.81194C118.409 2.68221 118.25 2.57842 118.092 2.57842C117.934 2.57842 117.75 2.68221 117.697 2.81194L109.37 20.6887C109.291 20.8703 109.37 21 109.581 21Z"
			fill="white"
		/>
		<path
			d="M128.994 18.8465C130.68 20.8703 133.368 21.7525 135.977 21.7525C139.139 21.7525 142.67 20.0919 142.67 16.5633C142.67 10.3882 132.92 10.9849 132.446 7.24869C132.419 7.04112 132.288 6.9114 132.077 6.9114H129.679C129.468 6.9114 129.31 7.06707 129.31 7.24869C129.31 13.346 139.561 12.9568 139.561 16.4595C139.561 18.1719 137.479 18.6909 136.082 18.6909C134.369 18.6909 132.604 18.2757 131.444 16.9525C131.313 16.7968 131.128 16.7968 130.97 16.9006L129.046 18.3795C128.888 18.5092 128.862 18.6909 128.994 18.8465ZM132.235 3.17518L133.394 5.22491C133.5 5.40653 133.658 5.45842 133.869 5.38058C134.396 5.19896 135.002 5.14707 135.529 5.14707C137.136 5.14707 138.612 5.76977 139.798 6.80761C139.93 6.93734 140.114 6.93734 140.272 6.78166L141.88 4.99139C142.011 4.86167 142.011 4.6541 141.853 4.52437C140.114 3.09734 137.9 2.08545 135.634 2.08545C134.475 2.08545 133.342 2.31896 132.367 2.7341C132.182 2.81194 132.129 3.0195 132.235 3.17518Z"
			fill="white"
		/>
		<path
			d="M145.718 13.0346C145.718 13.2422 145.85 13.3719 146.061 13.3719H157.313C157.524 13.3719 157.656 13.2422 157.656 13.0346V10.8033C157.656 10.5957 157.524 10.466 157.313 10.466H146.061C145.85 10.466 145.718 10.5957 145.718 10.8033V13.0346ZM145.718 18.4314V20.6627C145.718 20.8703 145.85 21 146.061 21H157.313C157.524 21 157.656 20.8703 157.656 20.6627V18.4314C157.656 18.2238 157.524 18.0941 157.313 18.0941H146.061C145.85 18.0941 145.718 18.2238 145.718 18.4314ZM146.061 5.74383H157.313C157.524 5.74383 157.656 5.6141 157.656 5.40653V3.17518C157.656 2.96761 157.524 2.83788 157.313 2.83788H146.061C145.85 2.83788 145.718 2.96761 145.718 3.17518V5.40653C145.718 5.6141 145.85 5.74383 146.061 5.74383Z"
			fill="white"
		/>
	</svg>
);

export default FullLogoIcon;
