import React from "react";

const CopyIcon = (params) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.0002 9.66634V1.66634C11.0002 1.31272 10.8597 0.97358 10.6096 0.723532C10.3596 0.473484 10.0205 0.333008 9.66683 0.333008H1.66683C1.31321 0.333008 0.974069 0.473484 0.72402 0.723532C0.473972 0.97358 0.333496 1.31272 0.333496 1.66634V9.66634C0.333496 10.02 0.473972 10.3591 0.72402 10.6091C0.974069 10.8592 1.31321 10.9997 1.66683 10.9997H9.66683C10.0205 10.9997 10.3596 10.8592 10.6096 10.6091C10.8597 10.3591 11.0002 10.02 11.0002 9.66634ZM1.66683 1.66634H9.66683V9.66634H1.66683V1.66634ZM12.3335 2.99967V10.9997C12.3335 11.3533 12.193 11.6924 11.943 11.9425C11.6929 12.1925 11.3538 12.333 11.0002 12.333H3.00016C3.00016 12.6866 3.14064 13.0258 3.39069 13.2758C3.64074 13.5259 3.97987 13.6663 4.3335 13.6663H11.0002C11.7074 13.6663 12.3857 13.3854 12.8858 12.8853C13.3859 12.3852 13.6668 11.7069 13.6668 10.9997V4.33301C13.6668 3.97939 13.5264 3.64025 13.2763 3.3902C13.0263 3.14015 12.6871 2.99967 12.3335 2.99967Z"
			fill={params.color ? params.color : "#4F5661"}
		/>
	</svg>
);

export default CopyIcon;

