import {
	CONTACT_INFORMATION_FORM,
	CREATE_ACCOUNT_FORM_ID,
	SETUP_PROFILE_FORM_ID,
	YOUR_INFORMATION_FORM_ID,
	MEMBER_PAGES_FORM,
} from "./constants";
import uuidv4 from "utils/uuidv4";
import React from "react";

import NumberIcon from "components/UI/NumberIcon";
import { redirect, useNavigate } from "react-router";

export const paths = [
	{
		path: "/creator-creation",
		formID: CREATE_ACCOUNT_FORM_ID,
		id: uuidv4(),
		icon: <NumberIcon number="1" />,
		text: "Your Account",
		disabled: false,
	},
	{
		path: "/creator-creation/your-information",
		formID: YOUR_INFORMATION_FORM_ID,
		id: uuidv4(),
		icon: <NumberIcon number="2" />,
		text: "Your Information",
		disabled: false,
	},
	{
		path: "/creator-creation/setup-profile",
		formID: SETUP_PROFILE_FORM_ID,
		id: uuidv4(),
		icon: <NumberIcon number="3" />,
		text: "Your Profile",
		disabled: false,
	},
];

export const navigateBack = (history, path) => {
	// const index = paths.findIndex((p) => p.path === path);

	// if (index > 1) {
	// 	const prevPath = paths[index - 1];
	// 	if (prevPath?.path) return navigate(prevPath.path);
	// }

	return window.scrollTo(0, 0);
};

export const navigateForward = (path) => {
	const index = paths.findIndex((p) => p.path === path);

	if (index > -1) {
		if (index === paths.length - 1) {
			redirect("/dashboard");
		} else {
			const nextPath = paths[index + 1];
			if (nextPath?.path) redirect(nextPath.path);
		}
	}

	return window.scrollTo(0, 0);
};

export function sidePanelsOptionsDisabledMap(sidePanelOptions, user) {
	return sidePanelOptions.map((panelOption, i) => {
		if (i === 0 && user) {
			panelOption.disabled = true;
		}

		if (i >= 3) {
			panelOption.disabled = !user?.gym?.id;
		}

		if (!user && i !== 0) {
			panelOption.disabled = true;
		}

		return panelOption;
	});
}

export function getCurrentFormID(pathname) {
	const form = paths.find((path) => path.path === pathname);
	if (form) return form.formID;
}
