import styled from "styled-components";

const StyledSignUpForLiveModal = styled.div`
	.sb-schedule-live-modal {
		&__footer {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding: 16px;
			padding-bottom: 0px;
			margin-bottom: 0px;
		}

		&__body {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding-top: 24px;
			padding-bottom: 24px;
			align-items: flex-start;
			justify-content: flex-start;
			&__item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 12px;
				width: "100%";
				p {
					height: "100%";
					font-family: "DM Sans";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					letter-spacing: -0.08px;
					color: #000000;
					margin-bottom: 0px;
				}
			}
		}

		&__divider {
			width: "100%";
			height: 1px;
			background-color: #e3e5e7;
			margin: 16px;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		&__header {
			align-items: flex-start;
			justify-content: flex-start;
			padding: 8px;
			display: flex;
			flex-direction: column;
			gap: 8px;
			h1 {
				font-family: "DM Sans";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 25px;
				color: #000000;
			}

			h2 {
				font-family: "DM Sans";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #000000;
			}

			p {
				font-family: "DM Sans";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 135%;
				color: #4f5661;
			}
		}
	}
`;

export default StyledSignUpForLiveModal;

