import styled from "styled-components";

const StyledPlanSelection = styled.div`
	position: relative;
	padding: 40px;

	.sb-plan-selection {
		&__plans-container {
			display: flex;
			gap: 32px;
			justify-content: center;
			max-width: 1400px;
			margin: 0 auto;
			flex-wrap: wrap;
		}

		&__disclaimer {
			max-width: 1400px;
			margin: 40px auto 0 auto;
		}
	}
`;

export default StyledPlanSelection;
