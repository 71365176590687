import styled from "styled-components";

const StyledTwoColumnsLayout = styled.div`
	display: flex;
	gap: 0;
	width: 100vw;
	height: 100vh;

	.sb-two-columns-layout {
		&__left-column,
		&__right-column {
			min-height: 100vh;
			width: 50%;
		}

		&__left-column {
			position: relative;
			padding: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		&__right-column {
			background-image: ${({ rightColumnImage }) => `url(${rightColumnImage})`};
			background-position: 50% 50%;
			background-size: cover;
		}
	}
`;

export default StyledTwoColumnsLayout;
