// -----------------------------------------------------------------------------
// Importing libraries
// -----------------------------------------------------------------------------
import { call, all, spawn } from "redux-saga/effects";

// -----------------------------------------------------------------------------
// Importing all sagas
// -----------------------------------------------------------------------------
import { getDomainGymSaga } from "./domainGymSagas";
import { autoLoginSaga } from "./authSagas";
import { saveGymBrandingSaga, saveGymSubdomainSaga, searchGymSaga } from "./gymsSagas";
import { getGymMembersByDateSaga } from "./membersSagas";
import { getCategoriesSaga, deleteCategorySaga } from "./categoriesSagas";
import { deleteUserSaga, getCurrentUserSaga } from "./usersSagas";
import { createWorkoutSaga, editWorkoutSaga, updateSelectedWorkoutSaga, deleteWorkoutSaga } from "./workoutsSagas";
import { uploadAssetToS3Saga, uploadAssetToMuxSaga } from "./assetsSagas";
import { getGenresSaga } from "./fitSagas";
import {
	getProductsSaga,
	crateLeadSaga,
	createSubscriptionSaga,
	getMembershipsSaga,
	connectCreatorAccountSaga,
	detachPaymentMethodSaga,
	deleteCardSaga,
	createCreatorProductSaga,
	getCreatorProductSaga,
	getCreatorAccountSaga,
	getOneTimeLoginLinkSaga,
} from "./paymentsSagas";

// -----------------------------------------------------------------------------
// Root saga
// -----------------------------------------------------------------------------
function* rootSaga() {
	// This strategy maps our child sagas to spawned generators
	// (detaching them from the root parent) which start our sagas as
	// subtasks in a try block. Our saga will run until termination, and
	// then be automatically restarted. The catch block harmlessly handles
	// any error that may have been thrown by, and terminated, our saga.
	const sagas = [
		getDomainGymSaga,
		saveGymBrandingSaga,
		getCategoriesSaga,
		deleteCategorySaga,
		saveGymSubdomainSaga,
		getProductsSaga,
		crateLeadSaga,
		createSubscriptionSaga,
		getMembershipsSaga,
		connectCreatorAccountSaga,
		detachPaymentMethodSaga,
		deleteCardSaga,
		createCreatorProductSaga,
		deleteWorkoutSaga,
		getCreatorProductSaga,
		getCreatorAccountSaga,
		getOneTimeLoginLinkSaga,
		deleteUserSaga,
		getCurrentUserSaga,
		updateSelectedWorkoutSaga,
		getGymMembersByDateSaga,
		createWorkoutSaga,
		uploadAssetToS3Saga,
		uploadAssetToMuxSaga,
		getGenresSaga,
		editWorkoutSaga,
		autoLoginSaga,
		searchGymSaga,
	];

	yield all(
		sagas.map((saga) =>
			spawn(function* () {
				while (true) {
					try {
						yield call(saga);
						break;
					} catch (e) {
						console.error(e);
					}
				}
			})
		)
	);
}

export default rootSaga;
