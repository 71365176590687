import React from "react";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import dumbellIconSVG from "../../../images/dumbellIcon.svg";
import Card from "../../../components/UI/Card";
import Banner from "../../../components/UI/ClientPortal/Banner";
import shareIcon from "../../../images/share.svg";

function Activity({ history }) {
	return (
		<div>
			<Banner title="Activity" />
			<Row gutter={100}>
				<Col span={12}>
					<Card
						centerContent
						large
						icon={shareIcon}
						header="Chest & Tris Resistance"
						p="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
					/>
				</Col>
				<Col span={12}>
					<h1>
						<img style={{ filter: "invert(100%)" }} src={dumbellIconSVG} alt="workouts" /> Workouts Completed
					</h1>
				</Col>
			</Row>
		</div>
	);
}

export default Activity;
