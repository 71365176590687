export function denominators(decimal) {
	if (decimal % 1 === 0) {
		return { denominator: 1, numerator: decimal };
	}

	const tolerance = 1.0e-6;
	let numerator = 1,
		h2 = 0,
		denominator = 0,
		k2 = 1,
		b = decimal;

	do {
		const a = Math.floor(b);
		let aux = numerator;
		numerator = a * numerator + h2;
		h2 = aux;
		aux = denominator;
		denominator = a * denominator + k2;
		k2 = aux;
		b = 1 / (b - a);
	} while (Math.abs(decimal - numerator / denominator) > decimal * tolerance);

	return { denominator, numerator };
}

export function decimalToFraction(decimal) {
	const { denominator, numerator } = denominators(decimal);
	return `${numerator} / ${denominator}`;
}

export function minimumPixelSize(decimal) {
	const { denominator, numerator } = denominators(decimal);
	return {
		denominator: denominator * 15,
		numerator: numerator * 15,
	};
}

export function decodeString(str = "") {
	try {
		const output = decodeURIComponent(str);
		return output || "";
	} catch (error) {
		return str;
	}
}

export function encodeString(str = "") {
	try {
		const output = str;
		return output;
	} catch (error) {
		return str;
	}
}

