// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Paragraph23 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import { hexToRGBA } from "helpers/generic";
import StyledStackedBarChart from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------

const formatter = (value) => `$ ${value}`;

const StackedBarChart = ({ data, bars, formatted }) => {
	return (
		<StyledStackedBarChart className="sb-stacked-bar-chart">
			<ResponsiveContainer width="100%" height="100%">
				<BarChart width={500} height={300} data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis tickFormatter={formatted ? formatter : null} />
					<Tooltip cursor={{ fill: "none" }} />
					<Legend
						height={32}
						content={({ payload }) => (
							<ul className="sb-stacked-bar-chart__legend">
								{payload.map((entry, index) => (
									<li
										className="sb-stacked-bar-chart__legend__item"
										key={`item-${index}`}
										style={{ backgroundColor: hexToRGBA(entry.color, 0.12) }}
									>
										<div
											className="sb-stacked-bar-chart__legend__item__circle"
											style={{ backgroundColor: entry.color }}
										/>
										<Paragraph23>{entry.value}</Paragraph23>
									</li>
								))}
							</ul>
						)}
					/>
					{bars.map((bar) => (
						<Bar
							key={bar.name}
							dataKey={bar.name}
							stackId="stacked"
							barSize={8}
							fill={bar.backgroundColor}
							radius={[100, 100, 100, 100]}
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
		</StyledStackedBarChart>
	);
};

export default StackedBarChart;
