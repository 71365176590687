import React from "react";
import { Switch, Tooltip } from "antd";

import "./index.scss";

function Toggle({ updateToggles, name, initial, text, tooltipTitle = "" }) {
	const onChange = (checked) => {
		updateToggles((prev) => ({ ...prev, [name]: checked }));
	};

	return (
		<div className="toggle-container">
			<h2>{text}</h2>
			<Tooltip title={tooltipTitle}>
				<Switch defaultChecked={!!initial} onChange={onChange} />
			</Tooltip>
		</div>
	);
}

export default Toggle;
