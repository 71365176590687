import React from "react";

const StarIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M15.9998 23.0267L24.2398 28L22.0532 18.6267L29.3332 12.32L19.7465 11.5067L15.9998 2.66666L12.2532 11.5067L2.6665 12.32L9.9465 18.6267L7.75984 28L15.9998 23.0267Z"
			fill="currentColor"
		/>
	</svg>
);

export default StarIcon;
