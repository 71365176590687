// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";
// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { LiveIcon } from "components/Icons";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledReleaseScheduleForm from "./styles";
import { FormTimeSelect } from "components/Common/Form/FormTimeSelect";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import { FormTimezoneSelect } from "components/Common/Form/FormTimezoneSelect";
import { useGetDomainGymQuery } from "store/api/api";
import moment from "moment-timezone";
import { useParams } from "react-router";
import { defaultTimezone } from "helpers/constants";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ReleaseScheduleForm = ({ nextStep, previousStep, values }) => {
	const [form] = Form.useForm();
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const [currentDatetime, setCurrentDatetime] = useState(null);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const getPayload = () => {
		const values = form.getFieldsValue();
		const { workoutDate, workoutTime, workoutTimezone } = values;
		if (!workoutDate || !workoutTime || !workoutTimezone) {
			return {
				gym_id: domainGymId,
				timezone: workoutTimezone,
				currently_streaming: false,
			};
		}
		const day = moment(workoutDate).startOf("day").format("MM/DD/YYYY");
		const time = moment(workoutTime).format("HH:mm");
		const dayTime = moment(day + " " + time, "MM/DD/YYYY HH:mm");
		const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
		return {
			gym_id: domainGymId,
			workoutId: selectedWorkout.id,
			start_time: iso,
			timezone: workoutTimezone,
			time_zone: workoutTimezone,
			currently_streaming: false,
		};
	};
	const onFinish = async () => {
		setIsSubmitDisabled(true);
		const payload = getPayload();
		nextStep(payload);
	};

	const onPreviousStep = () => {
		setIsSubmitDisabled(false);
		const payload = getPayload();
		previousStep(payload);
	};

	return (
		<StyledReleaseScheduleForm>
			<div className="sb-release-schedule-form">
				<Form
					layout="vertical"
					className="sb-release-schedule-form__form"
					onFinish={onFinish}
					form={form}
					initialValues={{
						workoutDate: values.start_time ? moment(values.start_time) : undefined,
						workoutTime: values.start_time ? moment(values.start_time) : undefined,
						workoutTimezone: values.timezone || defaultTimezone,
					}}
				>
					<FormDateSelect
						name="workoutDate"
						label="Workout Date"
						rules={[
							{ required: true, message: "This field is required" },
							() => ({
								validator(_, value) {
									const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
									const now = moment.tz(timezone).startOf("day");
									const selectedDate = moment(value).tz(timezone).startOf("day");

									if (selectedDate.isBefore(now)) {
										return Promise.reject(new Error("The selected date cannot be in the past."));
									}
									return Promise.resolve();
								},
							}),
						]}
						disabledDate={(currentDate) => {
							const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
							const now = moment.tz(timezone).startOf("day");
							const current = currentDate && currentDate.tz(timezone).startOf("day");

							return current && current.isBefore(now);
						}}
						onOpenChange={(open) => {
							if (!open) {
								const workoutDate = form.getFieldValue("workoutDate");
								const workoutTime = form.getFieldValue("workoutTime");

								if (moment(workoutDate).isValid()) {
									setCurrentDatetime(moment(workoutDate).format("YYYY-MM-DD"));
								}

								if (workoutTime) {
									form.validateFields(["workoutTime"]);
								}
							}
						}}
					/>
					<div className="sb-release-form-input-group">
						<FormTimeSelect
							name="workoutTime"
							label="Time"
							form={form}
							rules={[
								{ required: true, message: "This field is required" },
								() => ({
									validator(_, value) {
										const workoutDate = form.getFieldValue("workoutDate");
										const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
										const now = moment.tz(timezone);

										if (workoutDate) {
											const selectedDateTime = moment.tz(
												`${moment(workoutDate).format("YYYY-MM-DD")} ${moment(value).format("HH:mm")}`,
												timezone
											);

											if (selectedDateTime.isBefore(now)) {
												return Promise.reject(new Error("The selected time cannot be in the past."));
											}
										}

										return Promise.resolve();
									},
								}),
							]}
							disabledTime={() => {
								const workoutDate = form.getFieldValue("workoutDate") || moment().startOf("day");
								const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;

								if (workoutDate) {
									const now = moment.tz(timezone);
									const selectedDate = moment(workoutDate).tz(timezone).startOf("day");

									if (selectedDate.isSame(now, "day")) {
										return {
											disabledHours: () => [...Array(now.hour()).keys()],
											disabledMinutes: (selectedHour) => {
												if (selectedHour === now.hour()) {
													return [...Array(now.minute()).keys()];
												}

												return [];
											},
										};
									}
								}

								return {
									disabledHours: () => [],
									disabledMinutes: () => [],
								};
							}}
							onOpenChange={(open) => {
								if (!open) {
									const workoutDate = form.getFieldValue("workoutDate");

									if (workoutDate) {
										form.validateFields(["workoutDate"]);
									}
								}
							}}
						/>
						<FormTimezoneSelect
							name="workoutTimezone"
							rules={[{ required: true, message: "This field is required" }]}
							label="Timezone"
							currentDatetime={currentDatetime}
							onChange={(newTimezone) => {
								const workoutDate = form.getFieldValue("workoutDate");
								const workoutTime = form.getFieldValue("workoutTime");

								if (workoutDate) {
									const formattedDate = moment(workoutDate).tz(newTimezone, true).startOf("day");
									form.setFieldsValue({
										workoutDate: formattedDate,
									});
								}

								if (workoutDate) {
									form.validateFields(["workoutDate"]);
								}
								if (workoutTime) {
									form.validateFields(["workoutTime"]);
								}
							}}
						/>
					</div>

					<div className="sb-release-schedule-form__notice">
						<LiveIcon color="#FF865C" />
						<Paragraph2>
							Live workouts require you to log in and start the workout at the selected time and date.
						</Paragraph2>
					</div>
					<div className="sb-release-schedule-form__actions">
						<Button
							onClick={onPreviousStep}
							className="sb-release-schedule-form__form__button secondary-filled"
							chevronLeft
							uppercase
						>
							<Paragraph2>Back</Paragraph2>
						</Button>
						<Button
							type="submit"
							disabled={isSubmitDisabled}
							className="sb-release-schedule-form__form__button primary-filled"
							chevronRight
							uppercase
						>
							<Paragraph2>Schedule</Paragraph2>
						</Button>
					</div>
				</Form>
			</div>
		</StyledReleaseScheduleForm>
	);
};

export default ReleaseScheduleForm;

