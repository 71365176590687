// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -------------------------------------------------------------------------------
// Component and others
// -------------------------------------------------------------------------------
import { Heading5 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledSelectedColorCard from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Button = ({ selectedColor, className, ...other }) => {
	return (
		<StyledSelectedColorCard selectedColor={selectedColor} className={`${className} sb-selected-color-card`} {...other}>
			<Heading5>{selectedColor || "No Selection"}</Heading5>
		</StyledSelectedColorCard>
	);
};

export default Button;
