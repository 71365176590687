import styled from "styled-components";

const StyledCreateWorkoutModal = styled.div`
	.sb-create-workout-modal {
		&__header {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 16px;
			margin-bottom: 32px;

			&__steps {
				margin: 24px auto 8px auto;
			}
		}
	}
`;

export default StyledCreateWorkoutModal;
