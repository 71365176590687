import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const fitSlice = createSlice({
	name: "fit",
	initialState,
	reducers,
});

const { actions, reducer } = fitSlice;

export const { updateFitErrors, updateGenresLoading, updateGenres, getGenres } = actions;

export default reducer;
