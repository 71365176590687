import React from "react";
import videojs from "video.js";

const VideoPlayer = (props) => {
	const videoRef = React.useRef(null);
	const playerRef = React.useRef(null);
	const { options, onReady } = props;

	React.useEffect(() => {
		if (!playerRef.current) {
			const videoElement = videoRef.current;
			if (!videoElement) return;

			options.sources = options.sources.map((s) => {
				if ((s.type === "video/quicktime" || s.type === "video/mov") && !s.src?.includes(".mp4")) {
					return { type: "video/mp4", src: `${s.src}#.mp4` };
				} else if (s.src?.includes("stream.mux.com")) {
					return { ...s, type: "application/x-mpegURL" };
				} else {
					return s;
				}
			});

			const player = (playerRef.current = videojs(videoElement, options, () => {
				onReady && onReady(player);
			}));
		} else {
			const sources = options.sources.map((s) => {
				if ((s.type === "video/quicktime" || s.type === "video/mov") && !s.src?.includes(".mp4")) {
					return { type: "video/mp4", src: `${s.src}#.mp4` };
				} else if (s.src?.includes("stream.mux.com")) {
					return { ...s, type: "application/x-mpegURL" };
				} else {
					return s;
				}
			});
			const player = playerRef.current;

			player.autoplay(false);
			player.src(sources);
		}
	}, [options, onReady]);

	React.useEffect(() => {
		return () => {
			if (playerRef.current) {
				playerRef.current.dispose();
				playerRef.current = null;
			}
		};
	}, []);

	return (
		<div data-vjs-player style={{ ...props.styleConfig, position: "relative" }}>
			<video ref={videoRef} className="video-js vjs-big-play-centered" />
		</div>
	);
};

export default VideoPlayer;
