import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const gymsSlice = createSlice({
	name: "gyms",
	initialState,
	reducers,
});

const { actions, reducer } = gymsSlice;

export const { saveGymBranding, saveGymSubdomain, updateGymsErrors, updateGymSaving, updateGymsList, searchGym } =
	actions;
export default reducer;
