import React from "react";
import PropTypes from "prop-types";

import Menu from "components/Menu";

import Header from "./Header";

import "./SidePanel.scss";

function SidePanel(props) {
	const { options, defaultSelectedKeys, onClick } = props;

	return (
		<div className="side-panel">
			<Header />
			<Menu onClick={onClick} options={options} defaultSelectedKeys={defaultSelectedKeys} />
		</div>
	);
}

SidePanel.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			icon: PropTypes.any,
		})
	),
	defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
	defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
};

export default SidePanel;
