import React from "react";
import DumbellIcon from "../../images/dumbellIcon.svg";
import MembersIcon from "../../images/MembersIcon.svg";
import EarningsIcon from "../../images/Earnings.svg";
import SettingsIcon from "../../images/settings.svg";
import AnalyticsIcon from "../../images/AnalyticsIcon.svg";
import { StopFilled } from "@ant-design/icons";

import { HomeIcon } from "components/Icons";

export const paths = [
	{
		id: "home",
		icon: <HomeIcon className="dashboard-side-menu-icon" />,
		text: "Home",
		disabled: false,
	},
	{
		id: "workouts",
		icon: <img className="dashboard-side-menu-icon" src={DumbellIcon} alt="workout" />,
		text: "Workout Library",
		disabled: false,
	},
	{
		id: "members",
		icon: <img className="dashboard-side-menu-icon" src={MembersIcon} alt="members" />,
		text: "Members",
		disabled: false,
	},
	{
		id: "earnings",
		icon: <img className="dashboard-side-menu-icon" src={EarningsIcon} alt="earnings" />,
		text: "Earnings",
		disabled: false,
	},
	{
		id: "analytics",
		icon: <img className="dashboard-side-menu-icon" src={AnalyticsIcon} alt="analytics" />,
		text: "Analytics",
		disabled: false,
	},
	{
		id: "pricing-plan",
		icon: (
			<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M17 2H14V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H5C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3V7C0 8.06087 0.421427 9.07828 1.17157 9.82843C1.92172 10.5786 2.93913 11 4 11H4.14C4.36114 11.8565 4.85993 12.6155 5.55836 13.1583C6.25679 13.7011 7.11544 13.9971 8 14V18H5.5C5.36739 18 5.24021 18.0527 5.14645 18.1464C5.05268 18.2402 5 18.3674 5 18.5V19.5C5 19.6326 5.05268 19.7598 5.14645 19.8536C5.24021 19.9473 5.36739 20 5.5 20H12.5C12.6326 20 12.7598 19.9473 12.8536 19.8536C12.9473 19.7598 13 19.6326 13 19.5V18.5C13 18.3674 12.9473 18.2402 12.8536 18.1464C12.7598 18.0527 12.6326 18 12.5 18H10V14C10.8846 13.9971 11.7432 13.7011 12.4416 13.1583C13.1401 12.6155 13.6389 11.8565 13.86 11H14C15.0609 11 16.0783 10.5786 16.8284 9.82843C17.5786 9.07828 18 8.06087 18 7V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2ZM2 7V4H4V9C3.46957 9 2.96086 8.78929 2.58579 8.41421C2.21071 8.03914 2 7.53043 2 7ZM16 7C16 7.53043 15.7893 8.03914 15.4142 8.41421C15.0391 8.78929 14.5304 9 14 9V4H16V7Z"
					fill="white"
				/>
			</svg>
		),
		text: "Pricing Plan",
		disabled: false,
	},
	{
		id: "consumer",
		icon: (
			<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM18 14H2V4H18V14ZM4.5 12H8.5C8.63261 12 8.75979 11.9473 8.85355 11.8536C8.94732 11.7598 9 11.6326 9 11.5V6.5C9 6.36739 8.94732 6.24021 8.85355 6.14645C8.75979 6.05268 8.63261 6 8.5 6H4.5C4.36739 6 4.24021 6.05268 4.14645 6.14645C4.05268 6.24021 4 6.36739 4 6.5V11.5C4 11.6326 4.05268 11.7598 4.14645 11.8536C4.24021 11.9473 4.36739 12 4.5 12ZM11.5 8H15.5C15.6326 8 15.7598 7.94732 15.8536 7.85355C15.9473 7.75979 16 7.63261 16 7.5V6.5C16 6.36739 15.9473 6.24021 15.8536 6.14645C15.7598 6.05268 15.6326 6 15.5 6H11.5C11.3674 6 11.2402 6.05268 11.1464 6.14645C11.0527 6.24021 11 6.36739 11 6.5V7.5C11 7.63261 11.0527 7.75979 11.1464 7.85355C11.2402 7.94732 11.3674 8 11.5 8ZM11.5 12H13.5C13.6326 12 13.7598 11.9473 13.8536 11.8536C13.9473 11.7598 14 11.6326 14 11.5V10.5C14 10.3674 13.9473 10.2402 13.8536 10.1464C13.7598 10.0527 13.6326 10 13.5 10H11.5C11.3674 10 11.2402 10.0527 11.1464 10.1464C11.0527 10.2402 11 10.3674 11 10.5V11.5C11 11.6326 11.0527 11.7598 11.1464 11.8536C11.2402 11.9473 11.3674 12 11.5 12Z"
					fill="white"
				/>
			</svg>
		),
		text: "Member Preview",
		disabled: false,
	},
	{
		id: "settings",
		icon: <img className="dashboard-side-menu-icon" src={SettingsIcon} alt="settings" />,
		text: "Gym Settings",
		disabled: false,
	},
	{
		id: "logout",
		icon: <StopFilled className="dashboard-side-menu-icon" style={{ color: "white", transform: "translateY(0px)" }} />,
		text: "Logout",
		disabled: false,
	},
];

export const noSBPaymentsPaths = ["pricing-plan", "earnings"];
