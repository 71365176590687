// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Select, Form } from "antd";
import moment from "moment-timezone";
import { useTimezoneSelect } from "../Timezone";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormInput from "../FormSelect/styles";
import { defaultTimezone } from "helpers/constants";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormTimezoneSelect = ({ name, label, rules, className, dark, disabled, currentDatetime, ...rest }) => {
	const { Option } = Select;
	const timezones = {
		"America/Los_Angeles": "Pacific Time",
		"America/New_York": "Eastern Time",
		"America/Chicago": "Central Time",
		"America/Phoenix": "Arizona Time",
		"America/Denver": "Mountain Time",
		"America/Anchorage": "Alaska Time",
		"Pacific/Honolulu": "Hawaii Time",
		"America/Halifax": "Atlantic Standard Time",
	};

	const { options } = useTimezoneSelect({
		labelStyle: "offsetHidden_and_abbrev",
		timezones,
		currentDatetime,
	});

	return (
		<StyledFormInput className={dark ? "sb-form-select__dark" : `${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules} required={false}>
				<Select placeholder={moment.tz(defaultTimezone).format("z")} disabled={disabled} {...rest}>
					{options.map((o, i) => (
						<Option key={`form-select-${name}-option-${i}`} value={o.value}>
							{o.label}
						</Option>
					))}
				</Select>
			</Form.Item>
		</StyledFormInput>
	);
};

export default FormTimezoneSelect;

