import styled from "styled-components";

const StyledStackedBarChart = styled.div`
	height: 100%;

	.sb-stacked-bar-chart {
		&__legend {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
			padding-left: 60px;
			list-style: none;
			margin-bottom: 0;

			&__item {
				display: flex;
				align-items: center;
				gap: 8px;
				border-radius: 100px;
				padding: 4px 8px;

				&__circle {
					width: 8px;
					height: 8px;
					border-radius: 50%;
				}
			}
		}
	}

	.recharts-legend-wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
`;

export default StyledStackedBarChart;
