import styled from "styled-components";

export const Heading2 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 40px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading3 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading4 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading5 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 100%;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Heading6 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading7 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 40px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading8 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: -0.4px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading9 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.4px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Heading10 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 32px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading11 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 40px;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Heading12 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 40px;
	letter-spacing: -0.4px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Heading13 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 32px;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Heading14 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Paragraph1 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph2 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.2px;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph3 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.2px;
	color: ${({ theme }) => theme.colors.burntSienna};
`;

export const Paragraph4 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: -0.2px;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph5 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.2px;
	color: ${({ theme }) => theme.colors.white};
`;

export const Paragraph6 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph7 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: italic;
	font-weight: 400;
	font-size: 8px;
	line-height: 120%;
	letter-spacing: -0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph8 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph9 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 2px;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Paragraph10 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph11 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph12 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph13 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Paragraph14 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph15 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph16 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph17 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
	color: ${({ theme }) => theme.colors.raven};
`;

export const Paragraph18 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
	color: ${({ theme }) => theme.colors.coral};
`;

export const Paragraph19 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph20 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 18px;
	letter-spacing: -0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.coral};
`;

export const Paragraph21 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph22 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph23 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph24 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: -0.4px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph25 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	margin: 0;
	color: ${({ theme }) => theme.colors.iron};
`;

export const Paragraph26 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph27 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 1px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph28 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: -0.4px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph29 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: -0.4px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph30 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Paragraph31 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	color: ${({ theme }) => theme.colors.white};
`;

export const Paragraph32 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 2px;
	margin: 0;
	color: ${({ theme }) => theme.colors.coral};
`;

export const Label1 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Label2 = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Tag = styled.p`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;

export const Span1 = styled.span`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	margin: 0;
	opacity: 0.7;
	color: ${({ theme }) => theme.colors.black};
`;

export const Span2 = styled.span`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	margin: 0;
	opacity: 0.7;
	color: ${({ theme }) => theme.colors.white};
`;

export const Span3 = styled.span`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 17px;
	margin: 0;
	color: ${({ theme }) => theme.colors.burntSienna};
`;

export const Span4 = styled.span`
	font-family: "DM Sans", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 13px;
	font-style: italic;
	text-transform: uppercase;
	margin: 0;
	color: ${({ theme }) => theme.colors.trout};
`;
