import styled from "styled-components";

const StyledCircleSelection = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.raven};
	mask: radial-gradient(farthest-side, transparent calc(100% - 7px), #fff calc(100% - 7px + 1px));

	&.sb-circle-selection {
		&--selected {
			background-color: ${({ theme }) => theme.colors.coral};
		}
	}
`;

export default StyledCircleSelection;
