import React from "react";
import { UploadOutlined } from "@ant-design/icons";

function Uploader({ handleUploaderClick, preImage, type, title, height, width }) {
	return (
		<div
			className={`initial-uploader ${type === "video" || title ? "initial-uploader-video" : ""}`}
			onClick={handleUploaderClick}
			style={{ height: height, minHeight: height, width: width }}
		>
			{preImage ? (
				<img src={preImage} alt="pre" />
			) : (
				<div
					style={{
						height: height,
						minHeight: height,
						width: width,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						gap: 10,
					}}
				>
					<UploadOutlined style={{ fontSize: 40 }} />{" "}
					{(type === "video" || title) && <span>{title || "Upload Video"}</span>}
				</div>
			)}
		</div>
	);
}

export default Uploader;
