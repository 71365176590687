import React from "react";
import "./index.scss";
import { useRecoilValue } from "recoil";
import { navbarAtom } from "./atoms";

function Navbar() {
	const navbarState = useRecoilValue(navbarAtom);

	const getName = () => {
		switch (navbarState) {
			case "home":
				return "Home";
			case "workouts":
				return "Workouts";
			case "members":
				return "Members";
			case "earnings":
				return "Earnings";
			case "analytics":
				return "Analytics";
			case "consumer":
				return "Consumer View";
			case "pricing-plan":
				return "Payments & Pricing";
			case "settings":
				return "Settings";
			default:
				return "Home";
		}
	};

	return (
		<div className="navbar">
			<h1>{getName()}</h1>
		</div>
	);
}

export default Navbar;
