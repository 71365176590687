// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Components, utils and Styles
// -----------------------------------------------------------------------------
import { Heading4, Paragraph6, Paragraph7 } from "themes/default/_typography";
import { convertFalsyToUndefined } from "helpers/generic";
import ImageUpload from "components/ImageUpload";
import StyledImageInputCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ImageInputCard = ({ title, subtitle, description, imageUrl, onSaveImage, sizeLimit, onCancel }) => {
	return (
		<StyledImageInputCard>
			<Card hoverable className="sb-image-input-card">
				<div className="sb-image-input-card__left">
					<Heading4 className="sb-image-input-card__left__title">{title}</Heading4>
					<Paragraph6 className="sb-image-input-card__left__subtitle">{subtitle}</Paragraph6>
					<Paragraph7 className="sb-image-input-card__left__description">{description}</Paragraph7>
				</div>
				<div className="sb-image-input-card__right">
					<ImageUpload
						aspectRatio={1}
						dimensions={{ width: "200px", height: "200px" }}
						preImage={convertFalsyToUndefined(imageUrl)}
						onSave={onSaveImage}
						sizeLimit={sizeLimit}
						onCancel={onCancel}
					/>
				</div>
			</Card>
		</StyledImageInputCard>
	);
};

export default ImageInputCard;

