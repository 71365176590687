// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import SetupProfileForm from "./SetupProfileForm";
import SetupBranding from "./SetupBranding";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../globalAtoms";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const SetupProfile = () => {
	const user = useRecoilValue(userAtom);
	const [hexValue, updateHexValue] = useState("");

	const renderUserAssociations = () => {
		if (user && user.userAssociations && user.userAssociations.length > 0) {
			return (
				<>
					<div className="sb-creator-creation__inner__header">
						<p className="sb-creator-creation__inner__header__text">BRANDING</p>
					</div>
					<SetupBranding updateHexValue={(v) => updateHexValue(v)} hexValue={hexValue} />
				</>
			);
		}
	};

	return (
		<div className="sb-creator-creation">
			<div className="sb-creator-creation__inner">
				<div className="sb-creator-creation__inner__title">
					<h1 className="sb-creator-creation__inner__title__title">Let's Setup Your Profile</h1>
					<p className="sb-creator-creation__inner__title__subtitle">
						This will be used on your public creator profile and visible to users at sign up.
					</p>
				</div>
				<div className="sb-creator-creation__inner__header">
					<p className="sb-creator-creation__inner__header__text">DETAILS</p>
				</div>
				<SetupProfileForm hexValue={hexValue} />
				{renderUserAssociations()}
			</div>
		</div>
	);
};

export default SetupProfile;
