import styled from "styled-components";

const StyledBasicCard = styled.div`
	.sb-basic-card {
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 5px;

		.ant-card-body {
			display: flex;
			gap: 10px;
			padding: 18px;
		}

		&__icon {
		}

		&__body {
			&__text {
				margin: 0;
			}
		}
	}
`;

export default StyledBasicCard;
