// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { Layout } from "antd";

// -----------------------------------------------------------------------------
// Components and helpers

const { Footer } = Layout;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Terms = (props) => {
	//Set Page Title
	useEffect(() => {
		// Update the document title using the browser API
		document.title = `SWEATBASE | Terms & Conditions`;
	});
	const renderPrivacyPage = () => {
		return (
			<>
				<div className="sb-landing">
					<div className="sb-landing" style={{ height: "100vh" }}>
						<iframe src={"/tos.html"} width="100%" height="100%"></iframe>
					</div>{" "}
				</div>
				<Footer className="sb-landing__footer">
					<a className="privacy-link" href="/privacy" target="_blank" rel="noreferrer">
						Privacy
					</a>
					<a className="terms-link" href="/tos" target="_blank" rel="noreferrer">
						Terms
					</a>
				</Footer>
			</>
		);
	};

	return renderPrivacyPage();
};

export default Terms;

