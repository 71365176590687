import styled from "styled-components";

const StyledAccountCard = styled.div`
	.sb-account-card {
		max-width: 800px;
		margin: 16px auto;

		.ant-card-body {
			background: ${({ theme }) => theme.colors.white};
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
			border-radius: 12px;
			padding: 24px 30px 24px 24px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			cursor: pointer;
		}

		&__icon {
			background: ${({ theme }) => theme.colors.athensGray};
			border-radius: 50%;
			width: 56px;
			height: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;

			&.card-custom-color {
				color: ${({ theme }) => theme.colors.coral};
			}
		}

		&__body {
			&__header {
				margin: 4px 0;

				&.card-custom-color {
					color: ${({ theme }) => theme.colors.coral};
				}
			}

			&__text {
				opacity: 0.5;
				margin: 4px 0;
			}
		}

		&__arrow {
			opacity: 0.2;
			border-top: 2px solid $black;
			border-left: 2px solid $black;
			transform: rotate(135deg);
			width: 20px;
			height: 20px;
			margin-left: auto;
		}

		@media (max-width: ${({ theme }) => theme.responsive.mobile}) {
			flex-direction: column;
			padding: 16px;

			&__icon {
				margin: 0;
			}

			&__body {
				margin-top: 16px;

				&__header {
					text-align: center;
				}

				&__text {
					text-align: center;
				}
			}

			&__arrow {
				display: none;
			}
		}
	}
`;

export default StyledAccountCard;
