// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledTwoColumnsLayout from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const TwoColumnsLayout = ({ leftColumn, rightColumnImage }) => {
	return (
		<StyledTwoColumnsLayout className="sb-two-columns-layout" rightColumnImage={rightColumnImage}>
			<div className="sb-two-columns-layout__left-column">{leftColumn}</div>
			<div className="sb-two-columns-layout__right-column" />
		</StyledTwoColumnsLayout>
	);
};

export default TwoColumnsLayout;
