// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback } from "react";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Components and Styles
// -----------------------------------------------------------------------------
import { Heading4, Paragraph14, Paragraph15 } from "themes/default/_typography";
import { CircleSelection } from "components/Common/CircleSelection";
import { bem } from "helpers/Bem";
import StyledMembershipSelectionCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const MembershipSelectionCard = ({ onClick, readOnly, selected, icon, title, subtitle, description }) => {
	const onClickHandler = useCallback(() => !readOnly && onClick(), [readOnly, onClick]);

	return (
		<StyledMembershipSelectionCard>
			<Card
				hoverable={!readOnly}
				onClick={onClickHandler}
				className={bem`sb-membership-selection-card--${{ selected }}`}
			>
				<div className="sb-membership-selection-card__circle-container">
					<CircleSelection selected={selected} />
				</div>
				<div className="sb-membership-selection-card__icon-container">{icon}</div>
				<div className="sb-membership-selection-card__title">
					<Heading4>{title}</Heading4>
				</div>
				<div className="sb-membership-selection-card__subtitle">
					<Paragraph14>{subtitle}</Paragraph14>
				</div>
				{description && (
					<div className="sb-membership-selection-card__description">
						<Paragraph15 dangerouslySetInnerHTML={{ __html: description }} />
					</div>
				)}
			</Card>
		</StyledMembershipSelectionCard>
	);
};

// dangerouslySetInnerHTML={{ __html: currentTrack }

export default MembershipSelectionCard;
