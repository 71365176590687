import React from "react";

const CheckIcon = (params) => (
	<svg width="11" height="8" viewBox="0 0 11 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M9.86927 0.612773C9.80252 0.545477 9.72311 0.492061 9.63561 0.45561C9.54812 0.419158 9.45427 0.400391 9.35949 0.400391C9.2647 0.400391 9.17086 0.419158 9.08336 0.45561C8.99587 0.492061 8.91646 0.545477 8.84971 0.612773L3.50062 5.96904L1.25328 3.71453C1.18398 3.64758 1.10217 3.59494 1.01253 3.55961C0.92288 3.52429 0.827153 3.50696 0.730812 3.50863C0.63447 3.51029 0.539401 3.53092 0.45103 3.56933C0.36266 3.60774 0.282721 3.66318 0.215775 3.73248C0.14883 3.80178 0.096191 3.88359 0.0608626 3.97324C0.0255343 4.06288 0.00820861 4.15861 0.00987542 4.25495C0.0115422 4.35129 0.0321688 4.44636 0.070577 4.53473C0.108985 4.6231 0.164423 4.70304 0.233725 4.76999L2.99084 7.5271C3.05759 7.5944 3.137 7.64781 3.22449 7.68426C3.31199 7.72072 3.40584 7.73948 3.50062 7.73948C3.5954 7.73948 3.68925 7.72072 3.77675 7.68426C3.86424 7.64781 3.94365 7.5944 4.0104 7.5271L9.86927 1.66823C9.94215 1.601 10.0003 1.51939 10.0401 1.42857C10.0799 1.33774 10.1004 1.23966 10.1004 1.1405C10.1004 1.04135 10.0799 0.943263 10.0401 0.852437C10.0003 0.76161 9.94215 0.680008 9.86927 0.612773Z"
			fill="currentColor"
		/>
	</svg>
);

export default CheckIcon;
