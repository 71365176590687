import React from "react";

const ComputerIcon = (params) => (
	<svg width="35" height="75" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.83333 3.74992H32.0833V0.833252H5.83333C4.22917 0.833252 2.91667 2.14575 2.91667 3.74992V19.7916H0V24.1666H20.4167V19.7916H5.83333V3.74992ZM33.5417 6.66658H24.7917C23.9896 6.66658 23.3333 7.32283 23.3333 8.12492V22.7083C23.3333 23.5103 23.9896 24.1666 24.7917 24.1666H33.5417C34.3438 24.1666 35 23.5103 35 22.7083V8.12492C35 7.32283 34.3438 6.66658 33.5417 6.66658ZM32.0833 19.7916H26.25V9.58325H32.0833V19.7916Z"
			fill={params?.color ? params.color : "#F2994A"}
		/>
	</svg>
);

export default ComputerIcon;

