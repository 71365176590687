export const USER_TYPES = {
	CONSUMER: "consumer",
	ADMIN: "admin",
	TRAINER: "trainer",
};

export const isUserAdmin = (user_type) => user_type === USER_TYPES.ADMIN;

export const isUserCreator = (user_type) => {
	if (user_type === USER_TYPES.TRAINER) {
		return true;
	} else if (user_type === USER_TYPES.ADMIN) {
		return true;
	} else {
		return false;
	}
};
