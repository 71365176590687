// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Collapse, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Component, selectors and others
// -------------------------------------------------------------------------------
import {
	Heading4,
	Heading6,
	Paragraph14,
	Paragraph16,
	Paragraph17,
	Paragraph19,
	Paragraph20,
	Paragraph8,
} from "themes/default/_typography";
import { LoadingMask } from "components/Common/LoadingMask";
import { MembershipSelectionCard } from "components/Common/Cards/MembershipSelectionCard";
import { CalendarIcon, VideoVerticalIcon } from "components/Icons";
import { Button } from "components/Common/Button";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembershipSelection from "./styles";
import { paymentPlans } from "helpers/constants";

// -------------------------------------------------------------------------------
// Actions and selectors
// -------------------------------------------------------------------------------
import { updateSelectedMembership, updateSelectedProduct, updateCurrentMembershipDetails } from "store/slices";
import { selectCurrentMembershipDetails } from "store/selectors";
import { FormInputNumber } from "components/Common/Form/FormInputNumber";
import {
	useStripeCreateSubscriptionPriceMutation,
	useGetCreatorMembershipsQuery,
	useGetDomainGymQuery,
	useStripeUpdateMembershipPricingMutation,
} from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const MembershipSelection = ({ className, product, planDetails, currentPricing, setCurrentPricing }) => {
	const success = () => {
		message.open({
			type: "success",
			duration: 7,
			content: "Your Membership Pricing Was Successfully Updated",
		});
	};

	const { Panel } = Collapse;
	const dispatch = useDispatch();
	const currentMembershipDetails = useSelector(selectCurrentMembershipDetails);
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const [form] = Form.useForm();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const [createSubscriptionPriceTrigger, createSubscriptionPriceResponse] = useStripeCreateSubscriptionPriceMutation();
	const [updateMembershipPricingTrigger, updateMembershipPricingResponse] = useStripeUpdateMembershipPricingMutation();

	const { isLoading } = useGetCreatorMembershipsQuery();

	const membersPayRules = [];
	const [switchingPlan, setSwitchingPlan] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isFormInvalid, setIsFormInvalid] = useState(true);
	const [paymentData, setPaymentData] = useState({
		membersPay: 0,
		ownerCut: planDetails.share * 0,
	});
	const [selectedMembershipIndex, setSelectedMembershipIndex] = useState("monthly");

	useEffect(() => {
		if (currentMembershipDetails) {
			const membersPay = currentMembershipDetails.membersPay;
			setPaymentData({ membersPay, ownerCut: planDetails.share * membersPay });
			form.resetFields();
		}
	}, [currentMembershipDetails, planDetails.share]);

	useEffect(() => {
		if (currentPricing > 0) {
			dispatch(updateSelectedMembership({ membership: "monthly" }));
			setSelectedMembershipIndex("monthly");
		} else {
			setSelectedMembershipIndex("free");
			dispatch(updateSelectedMembership({ membership: "free" }));
		}
	}, [dispatch, currentPricing, switchingPlan]);

	useEffect(() => {
		if (createSubscriptionPriceResponse.isSuccess) {
			const newMembershipDetails = {
				id: createSubscriptionPriceResponse?.data?.id,
				product: createSubscriptionPriceResponse?.data?.id,
				unit_amount: createSubscriptionPriceResponse?.data?.default_price?.unit_amount,
				type: createSubscriptionPriceResponse?.data?.type,
				membersPay: createSubscriptionPriceResponse?.data?.default_price?.unit_amount / 100,
				default_price: createSubscriptionPriceResponse?.data?.default_price?.id,
			};
			dispatch(updateCurrentMembershipDetails({ currentMembershipDetails: newMembershipDetails }));
			setCurrentPricing(createSubscriptionPriceResponse?.data?.default_price?.unit_amount);
		}
	}, [dispatch, createSubscriptionPriceResponse]);

	useEffect(() => {
		if (updateMembershipPricingResponse.isSuccess) {
			const newMembershipDetails = {
				id: updateMembershipPricingResponse?.data?.product,
				product: updateMembershipPricingResponse?.data?.product,
				unit_amount: updateMembershipPricingResponse?.data?.unit_amount,
				type: updateMembershipPricingResponse?.data?.type,
				membersPay: updateMembershipPricingResponse?.data?.unit_amount / 100,
				default_price: updateMembershipPricingResponse?.data?.id,
			};
			dispatch(updateCurrentMembershipDetails({ currentMembershipDetails: newMembershipDetails }));
			setCurrentPricing(updateMembershipPricingResponse?.data?.unit_amount);
		}
	}, [dispatch, updateMembershipPricingResponse]);

	useEffect(() => {
		if (currentPricing > 0 && switchingPlan) {
			form.setFieldValue("membersPay", parseFloat(currentPricing / 100).toFixed(2));
		}
	}, [currentPricing, switchingPlan]);

	const onSaveNewMembershipPrice = async () => {
		if (planDetails.title === "Starter" && paymentData.membersPay < 9.99) {
			message.open({
				type: "error",
				duration: 7,
				content: "Min price is $9.99 on Starter Plan",
			});
			return;
		} else {
			setIsSaving(true);
			if (domainGymResponse.data?.id && product?.type) {
				if (currentMembershipDetails?.id) {
					// If the user is switching plans, we need to update the product
					try {
						await updateMembershipPricingTrigger({
							productId: currentMembershipDetails?.id,
							newPrice: selectedMembershipIndex === "free" ? 0 : (paymentData.membersPay * 100).toFixed(0),
						}).unwrap();
						setIsSaving(false);
						setSwitchingPlan(false);
						success();
					} catch (error) {
						console.error("update subscription ERROR: ", error);
						setIsSaving(false);
						setSwitchingPlan(false);
					}
				} else {
					// otherwise we need to create a new product
					try {
						const productName = `Monthly: ${domainGymResponse.data?.name}`;

						await createSubscriptionPriceTrigger({
							accountId: user.connected_account_id,
							productName,
							price: selectedMembershipIndex === "free" ? 0 : (paymentData.membersPay * 100).toFixed(0),
						}).unwrap();
						success();
						setIsSaving(false);
						setSwitchingPlan(false);
					} catch (error) {
						console.error("new subscription ERROR: ", error);
						setIsSaving(false);
						setSwitchingPlan(false);
					}
				}
			}

			setIsSaving(false);
			setSwitchingPlan(false);
		}
	};

	const onValuesChange = useCallback(
		({ membersPay }) => {
			setPaymentData({
				membersPay,
				ownerCut: membersPay * planDetails.share,
			});
		},
		[planDetails.share]
	);

	const onCancel = () => {
		const membersPay = currentMembershipDetails?.membersPay ?? 0;
		setPaymentData({ membersPay, ownerCut: planDetails?.share * membersPay });
		setSwitchingPlan(false);
		form.resetFields();
	};

	const handleMembershipSelection = useCallback(
		(membership) => {
			setSelectedMembershipIndex(membership);
			dispatch(updateSelectedMembership({ membership }));
		},
		[dispatch]
	);

	useEffect(() => {
		form.validateFields();

		if (selectedMembershipIndex !== "free" && paymentData.membersPay <= 0) {
			setIsFormInvalid(true);
		} else {
			setIsFormInvalid(false);
		}
	}, [selectedMembershipIndex, paymentData]);

	const validateMinValueForStarter = (_, value) => {
		if (value < 9.99) {
			return Promise.reject();
		}

		return Promise.resolve();
	};

	const validateMinValueForNonFreePlans = (_, value) => {
		if (value <= 0) {
			return Promise.reject();
		}

		return Promise.resolve();
	};

	if (selectedMembershipIndex !== "free") {
		membersPayRules.push({ required: true, message: "Field Required" });
	}

	if (planDetails.title === "Starter") {
		membersPayRules.push({ validator: validateMinValueForStarter, message: "Min price is $9.99 on Starter Plan" });
	} else {
		membersPayRules.push({
			validator: validateMinValueForNonFreePlans,
			message: "You must set the member price above $0 or select the Free plan.",
		});
	}

	const handleSwitchPlan = (tier) => {
		dispatch(
			updateSelectedProduct({
				product: {
					type: tier,
					name: paymentPlans[tier].title,
					price: paymentPlans[tier].price,
				},
			})
		);
	};

	return (
		<StyledMembershipSelection className={`${className} sb-membership-selection`}>
			<Form
				onValuesChange={onValuesChange}
				layout="vertical"
				form={form}
				initialValues={{
					membersPay: currentPricing > 0 && switchingPlan ? parseFloat(currentPricing / 100).toFixed(2) : 0,
				}}
			>
				{switchingPlan && (
					<div className="sb-membership-selection__cards">
						<MembershipSelectionCard
							selected={selectedMembershipIndex === "free"}
							icon={<VideoVerticalIcon />}
							title="Free"
							readOnly={planDetails.title === "Starter"}
							subtitle="All your workouts are free for members."
							description="Free content for your members is only available in <b>Pro</b> or <b>Studio Pro</b>."
							onClick={() => handleMembershipSelection("free")}
						/>
						<MembershipSelectionCard
							selected={selectedMembershipIndex === "monthly"}
							icon={<CalendarIcon />}
							title="Monthly"
							subtitle="Members pay a monthly fee for your paid workouts. 3 free workouts are required."
							onClick={() => handleMembershipSelection("monthly")}
						/>
					</div>
				)}
				<div className="sb-membership-selection__details">
					<div className="sb-membership-selection__details__up">
						<div className="sb-membership-selection__details__up__left">
							<Heading4>Membership Price</Heading4>
							<Paragraph14>
								Paying this fee unlocks all workouts and collections for 30 days. If member doesn’t renew, they lose
								access to your paid content.
							</Paragraph14>
						</div>
						<div className="sb-membership-selection__details__up__right">
							<Paragraph14>Your members pay</Paragraph14>
							{!switchingPlan && <Paragraph20>${parseFloat(paymentData.membersPay).toFixed(2) || 0}</Paragraph20>}
							{currentPricing > 0 && switchingPlan && (
								<FormInputNumber name="membersPay" type="money" addonRight={`/month`} rules={membersPayRules} />
							)}
							{switchingPlan && (currentPricing === 0 || currentPricing === undefined) && (
								<FormInputNumber
									disabled={!switchingPlan}
									name="membersPay"
									type="money"
									addonRight={`/month`}
									rules={membersPayRules}
								/>
							)}
						</div>
					</div>
					<div className="sb-membership-selection__details__down">
						<div className="sb-membership-selection__details__down__left">
							<div className="sb-membership-selection__details__down__left__icon">{planDetails.icon}</div>
							<div className="sb-membership-selection__details__down__left__text">
								<Collapse
									collapsible="diabled"
									ghost
									className="sb-membership-selection__details__down__left__collapse"
								>
									<Panel
										collapsible="disabled"
										showArrow={false}
										header={
											<>
												<Paragraph16>{planDetails.title} Plan</Paragraph16>
												<Paragraph17>Keep {planDetails.share * 100}% of sales</Paragraph17>
											</>
										}
										key="1"
									>
										<Paragraph14>
											You are currently on the <b>{planDetails.title} Plan</b>. Switching to a different plan will
											change your revenue share and pricing.
										</Paragraph14>
										<Paragraph14>
											<b>Are you sure you want to switch plans?</b>
										</Paragraph14>
										{isLoading ? (
											<LoadingMask />
										) : (
											<div
												style={{
													display: "flex",
													flexWrap: "wrap",
												}}
											>
												{["starter", "pro", "prostudio", "elite"].map((tier) =>
													paymentPlans[tier] ? (
														<Card
															onClick={() => {
																if (tier !== "elite") handleSwitchPlan(tier);
															}}
															hoverable
															key={paymentPlans[tier].title}
															style={{
																flexBasis: "50%",
																textAlign: "center",
																backgroundColor:
																	paymentPlans[product.type].title === paymentPlans[tier].title ? "coral" : null,
															}}
														>
															<Heading6>{paymentPlans[tier].title}</Heading6>
															{tier !== "elite" && <Paragraph8>{paymentPlans[tier].share * 100}% of sales</Paragraph8>}
															{tier !== "elite" && <Paragraph8>{paymentPlans[tier].priceDescription}</Paragraph8>}
															<div
																style={{
																	alignItems: "center",
																	width: "100%",
																	textAlign: "center",
																	borderRadius: 20,
																}}
															>
																<div>{paymentPlans[tier].icon}</div>
																{paymentPlans[tier].price}
															</div>
														</Card>
													) : null
												)}
											</div>
										)}
									</Panel>
								</Collapse>
							</div>
						</div>
						<div className="sb-membership-selection__details__down__right">
							<Paragraph19>You take home</Paragraph19>
							<Paragraph20>${Math.round((paymentData.ownerCut + Number.EPSILON) * 100) / 100 || 0}</Paragraph20>
						</div>
					</div>
					{selectedMembershipIndex === "free" && <div className="sb-membership-selection__details__mask" />}
				</div>

				<div className="sb-membership-selection__navigation">
					{switchingPlan && (
						<Button className="secondary-outlined" onClick={onCancel} uppercase>
							Cancel
						</Button>
					)}
					{switchingPlan ? (
						<Button className="primary-filled" onClick={onSaveNewMembershipPrice} disabled={isFormInvalid} uppercase>
							Save Member Plan
						</Button>
					) : (
						<Button className="primary-filled" onClick={() => setSwitchingPlan(true)} uppercase>
							Set New Price
						</Button>
					)}

					{isSaving && <LoadingMask />}
				</div>
			</Form>
			{isLoading && <LoadingMask />}
		</StyledMembershipSelection>
	);
};

export default MembershipSelection;

