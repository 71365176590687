// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback } from "react";
import { Card } from "antd";
// import { useDispatch } from "react-redux";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
import { Heading4, Paragraph14 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
// import { detachPaymentMethod } from "store/slices";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { PencilIcon, TrashIcon, VisaIcon } from "components/Icons";
import StyledPaymentMethodCard from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const PaymentMethodCard = ({ className, paymentMethod, ...other }) => {
	// const dispatch = useDispatch();

	const handleDetachPaymentMethod = useCallback(() => {
		// TODO: this will be harder than expected
		// if (paymentMethod.id) {
		// 	dispatch(detachPaymentMethod({ paymentMethodId: paymentMethod.id }));
		// }
	}, []);

	return (
		<StyledPaymentMethodCard className={`${className} sb-payment-method-card`} {...other}>
			<Card hoverable>
				<div className="sb-payment-method-card__inner">
					<div className="sb-payment-method-card__inner__left">
						<div className="sb-payment-method-card__inner__left__icon">
							<VisaIcon />
						</div>
						<div className="sb-payment-method-card__inner__left__data">
							<Heading4>**** **** **** {paymentMethod?.card.last4 || 1234}</Heading4>
							<Paragraph14>
								Expires {paymentMethod.card.exp_month || 12}/{paymentMethod.card.exp_year || 2021}
							</Paragraph14>
						</div>
					</div>
					<div className="sb-payment-method-card__inner__right">
						<div className="sb-payment-method-card__inner__right__action">
							<PencilIcon />
						</div>
						<div className="sb-payment-method-card__inner__right__action">
							<TrashIcon onClick={handleDetachPaymentMethod} />
						</div>
					</div>
				</div>
			</Card>
		</StyledPaymentMethodCard>
	);
};

export default PaymentMethodCard;

