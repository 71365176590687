import styled from "styled-components";

const StyledStripeForm = styled.div`
	width: 100%;

	.sb-button {
		display: block;
		margin: 40px auto 0 auto;
	}

	.subscription-pricing {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 20px 0;
	}
`;

export default StyledStripeForm;

