import React from "react";
import ValidationError from "../ValidationError";
import { Select } from "antd";

import "./index.scss";

function SelectField({ meta, options, placeholder, input, ...rest }) {
	return (
		<div className="select-field-wrapper">
			<Select placeholder={placeholder} size="large" {...input} {...rest}>
				{options.map((option, i) => {
					return (
						<Select.Option key={i} value={option.value}>
							{option.text}
						</Select.Option>
					);
				})}
			</Select>
			<ValidationError meta={meta} />
		</div>
	);
}

export default SelectField;
