import styled from "styled-components";
import loginImage from "images/login-image.png";

const StyledGymSearch = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	.sb-button__inner {
		padding: 0 10px;
	}
	.sb-button {
		min-width: 100px;
	}
	.sb-gym-search {
		&__actions {
			&__find-gym-button {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				border: none;
				gap: 12px;
				color: ${({ theme }) => theme.colors.coral};
				cursor: pointer;
				text-transform: uppercase;
			}
		}

		&__search {
			padding: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: calc(100vh - 217px);
			background: no-repeat center url(${loginImage});
			background-size: cover;

			&__inner {
				margin-top: 110px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 40px;

				&__header {
					display: flex;
					flex-direction: column;
					gap: 32px;
					text-align: center;

					&__input {
						border-radius: 100px;
						width: 610px;
						padding: 2px;
						background: ${({ theme }) => theme.colors.white};

						.sb-form-input {
							height: 36px;

							&:first-child {
								border-right: 2px solid rgba(0, 0, 0, 0.1);

								.ant-form-item {
									width: 100%;
								}
							}
						}

						.ant-form-inline {
							flex-wrap: nowrap;
							display: flex;
							align-items: center;
							justify-content: center;

							.ant-form-item {
								margin: 0;

								.ant-input {
									border: none;

									&:focus {
										border: none;
										outline: none;
										box-shadow: none;
									}
								}
							}
						}
					}
				}

				&__result {
					display: flex;
					flex-direction: column;
					gap: 8px;
					max-width: 606px;
					width: 100%;
					overflow: auto;
					max-height: calc(100vh - 440px);
					padding-right: 10px;

					.sb-information-card--transparent,
					.sb-information-card__body__header {
						color: ${({ theme }) => theme.colors.white};
					}

					.sb-information-card__body__text {
						color: ${({ theme }) => theme.colors.white};
						opacity: 0.75;
					}
				}
			}
		}
	}
`;

export default StyledGymSearch;

