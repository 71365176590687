// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "antd/lib/modal";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectCurrentUser, selectDomainGymId } from "store/selectors";
import { deleteUser } from "store/slices";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph3, Heading4 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import useLogout from "utils/useLogout";
import StyledDeleteUserModal from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const DeleteUserModal = ({ open, setOpen }) => {
	const { gym } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const logoutHook = useLogout(navigate, gym);
	const currentUser = useSelector(selectCurrentUser);
	const domainGymId = useSelector(selectDomainGymId);

	const handleDeleteUser = useCallback(() => {
		if (currentUser) {
			dispatch(
				deleteUser({
					user_id: currentUser.id,
					gym_id: domainGymId,
				})
			);

			return logoutHook.logout();
		}
	}, [dispatch, domainGymId, currentUser, logoutHook]);

	const handleCloseModal = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Modal title="Confirm" open={open} onCancel={handleCloseModal} footer={null} maskClosable={false}>
			<StyledDeleteUserModal className="sb-delete-user-modal">
				<div className="sb-delete-user-modal__text">
					<Heading4>Please confirm you want to delete this account. This can't be undone</Heading4>
				</div>
				<div className="sb-delete-user-modal__footer">
					<Button className="primary-filled" onClick={handleCloseModal}>
						<Paragraph3>Cancel</Paragraph3>
					</Button>
					<Button className="primary-outlined primary-outlined--delete" onClick={handleDeleteUser}>
						<Paragraph3>Delete</Paragraph3>
					</Button>
				</div>
			</StyledDeleteUserModal>
		</Modal>
	);
};

export default DeleteUserModal;

