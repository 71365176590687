import React from "react";

const CrossfitIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M25.3337 9.33317C25.3337 4.17317 21.1603 2.6665 16.0003 2.6665C10.8403 2.6665 6.66699 4.17317 6.66699 9.33317C6.6677 11.5281 7.44194 13.6525 8.85366 15.3332C8.03867 16.3056 7.43052 17.434 7.06636 18.6495C6.7022 19.8649 6.58969 21.1418 6.7357 22.4022C6.8817 23.6626 7.28316 24.88 7.91554 25.98C8.54791 27.08 9.39793 28.0395 10.4137 28.7998C10.8716 29.1433 11.4279 29.3303 12.0003 29.3332H20.0003C20.5727 29.3303 21.1291 29.1433 21.587 28.7998C22.6027 28.0395 23.4527 27.08 24.0851 25.98C24.7175 24.88 25.119 23.6626 25.265 22.4022C25.411 21.1418 25.2984 19.8649 24.9343 18.6495C24.5701 17.434 23.962 16.3056 23.147 15.3332C24.5587 13.6525 25.3329 11.5281 25.3337 9.33317ZM16.0003 5.33317C22.667 5.33317 22.667 7.99984 22.667 9.33317C22.6652 10.8693 22.133 12.3576 21.1603 13.5465C19.6308 12.5317 17.8359 11.9904 16.0003 11.9904C14.1647 11.9904 12.3699 12.5317 10.8403 13.5465C9.8677 12.3576 9.33548 10.8693 9.33366 9.33317C9.33366 7.99984 9.33366 5.33317 16.0003 5.33317ZM16.0003 25.3332C15.2092 25.3332 14.4358 25.0986 13.778 24.659C13.1202 24.2195 12.6076 23.5948 12.3048 22.8639C12.0021 22.133 11.9228 21.3287 12.0772 20.5528C12.2315 19.7769 12.6125 19.0642 13.1719 18.5047C13.7313 17.9453 14.444 17.5644 15.22 17.41C15.9959 17.2557 16.8002 17.3349 17.5311 17.6377C18.262 17.9404 18.8867 18.4531 19.3262 19.1109C19.7657 19.7687 20.0003 20.542 20.0003 21.3332C20.0003 22.394 19.5789 23.4115 18.8288 24.1616C18.0786 24.9117 17.0612 25.3332 16.0003 25.3332Z"
			fill="currentColor"
		/>
	</svg>
);

export default CrossfitIcon;
