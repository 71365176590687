// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { GenreSelector } from "components/Common/GenreSelector";
import { Heading4, Paragraph2 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledWorkoutMusicForm from "./styles";
import { useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutMusicForm = ({ nextStep, live, previousStep, values, loading }) => {
	const [selectedGenre, setSelectedGenre] = useState({
		title: values?.musicGenre,
		id: values?.playlistId,
	});
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getPayload = () => {
		let payload;
		if (selectedGenre) {
			const { title, id } = selectedGenre;
			payload = {
				workoutId: selectedWorkout?.id,
				gym_id: domainGymId,
				musicGenre: title,
				playlistId: id,
			};
		}
		return payload;
	};

	const onSave = () => {
		const payload = getPayload();
		nextStep(payload);
	};

	const onPrevious = () => {
		const payload = getPayload();
		previousStep(payload);
	};

	return (
		<StyledWorkoutMusicForm>
			<div className="sb-workout-music-form">
				<div className="sb-workout-music-form__title">
					<Heading4>Choose a Playlist</Heading4>
				</div>
				<GenreSelector initialSelectedGenreId={values.playlistId} setSelectedGenre={setSelectedGenre} />
				<div className="sb-workout-music-form__actions">
					<Button
						type="button"
						onClick={onPrevious}
						className="sb-release-schedule-form__form__button secondary-filled"
						chevronLeft
						uppercase
					>
						<Paragraph2>Previous</Paragraph2>
					</Button>
					<Button
						type="button"
						className="primary-filled"
						saveIcon
						uppercase
						onClick={onSave}
						loading={loading}
						disabled={loading}
					>
						<Paragraph2>{live ? "Next" : "Save"}</Paragraph2>
					</Button>
				</div>
			</div>
		</StyledWorkoutMusicForm>
	);
};

export default WorkoutMusicForm;

