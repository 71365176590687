// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useRecoilValue } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { userAtom } from "../../../globalAtoms";
import defaultHeader from "../../../images/default-header.jpeg";
import userIcon from "../../../images/user-icon.png";
import { useNavigate } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function ProfileBanner({ history, title, picture }) {
	const user = useRecoilValue(userAtom);
	const headerImage = user?.pages?.[0]?.header_image;
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};

	return (
		<div
			className="sb-profile-banner-container"
			style={{
				backgroundImage: `url("${headerImage ? headerImage : defaultHeader}")`,
			}}
		>
			<div className="sb-profile-banner-container__inner">
				<div className="sb-profile-banner-container__inner__gym-profile">
					<div className="sb-profile-banner-container__inner__gym-profile__back" onClick={goBack} />
				</div>
				<div className="sb-profile-banner-container__inner__welcome">
					<h1 className="sb-profile-banner-container__inner__welcome__title">{title}</h1>
				</div>
			</div>
			{picture && <img className="sb-profile-banner-container__profile-icon" src={userIcon} alt="user" />}
		</div>
	);
}

export default ProfileBanner;
