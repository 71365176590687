// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { bem } from "helpers/Bem";
import StyledCircleSelection from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const CircleSelection = ({ selected, onClick }) => {
	return <StyledCircleSelection onClick={onClick} className={bem`sb-circle-selection--${{ selected }}`} />;
};

export default CircleSelection;
