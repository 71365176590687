import styled from "styled-components";

const StyledSteps = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	.sb-steps {
		&__step {
			width: 64px;
			height: 4px;
			margin: 0 4px;
			border-radius: 2px;
			background: ${({ theme }) => theme.colors.athensGray};

			&--active {
				background: ${({ theme }) => theme.colors.coral};
			}
		}
	}
`;

export default StyledSteps;
