// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import ProfileBanner from "components/UI/ProfileBanner";

// -----------------------------------------------------------------------------
// Styles and helpers
// -----------------------------------------------------------------------------
import StyledAccountSettings from "./styles";
import AccountSettingsForm from "./AccountSettingsForm";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AccountSettings = () => {
	return (
		<StyledAccountSettings>
			<div className="sb-account-settings">
				<ProfileBanner title="Account Settings" picture={true} />
				<div className="sb-account-settings__body">
					<div className="sb-account-settings__body__inner">
						<AccountSettingsForm />
					</div>
				</div>
			</div>
		</StyledAccountSettings>
	);
};

export default AccountSettings;

