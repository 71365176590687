import styled from "styled-components";

const StyledDonutChart = styled.div`
	.sb-donut-chart {
	}

	.recharts-pie-labels {
		.recharts-pie-label-line {
			display: none;
		}

		text {
			font-family: "DM Sans", sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: -0.4px;
			fill: ${({ theme }) => theme.colors.black};
		}

		circle {
			fill: ${({ theme }) => theme.colors.white};
			filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.3));
		}
	}
`;

export default StyledDonutChart;
