import { atom } from "recoil";

export const creatorCreationFlowState = atom({
	key: "creatorCreationFlow",
	default: {
		currentFormIndex: null,
		email: null,
		password: null,
		confirmPassword: null,
		firstName: null,
		lastName: null,
		phoneNumber: null,
		jobTitle: null,
		company: null,
		description: null,
		fitnessPhilosophy: null,
		businessEmailAddress: null,
		businessPhoneNumber: null,
		gymAddress: null,
		gymAddress2: null,
		city: null,
		state: null,
		zipCode: null,
		canContinue: false,
		passedSignUp: false,
		saving: false,
		pin: null,
		is_franchise: 0,
	},
});
