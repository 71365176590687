import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const paymentsSlice = createSlice({
	name: "payments",
	initialState,
	reducers,
});

const { actions, reducer } = paymentsSlice;

export const {
	updateCurrentMembershipDetails,
	updateProducts,
	getProducts,
	updatePaymentsErrors,
	updateProductsLoading,
	createLead,
	updateSelectedProduct,
	createSubscription,
	updateSelectedMembership,
	getMemberships,
	updateCreatorMemberships,
	updateCreatorMembershipsLoading,
	updateMemberMemberships,
	updateMemberMembershipsLoading,
	connectCreatorAccount,
	detachPaymentMethod,
	deleteCard,
	createCreatorProduct,
	getCreatorProduct,
	updateCreatorProduct,
	getCreatorAccount,
	updateCreatorAccount,
	getOneTimeLoginLink,
	updateOneTimeLoginLink,
} = actions;
export default reducer;

