// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Tag } from "themes/default/_typography";
import StyledTags from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Tags = ({ tags, className, ...other }) => {
	return (
		<StyledTags className="sb-tags" {...other}>
			{tags.map((tag, i) => (
				<div key={`tag-${i}`} className="sb-tags__tag">
					<Tag>{tag}</Tag>
				</div>
			))}
		</StyledTags>
	);
};

Tags.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string),
	className: PropTypes.string,
};

export default Tags;
