import React from "react";

const ScheduledLiveStreamIcon = (props) => (
	<svg
		width={`${props.width ?? 11}`}
		height={`${props.height ?? 10}`}
		viewBox={`0 0 11 10`}
		fill={props?.fill ?? "#FF865C"}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.58496 0C2.8238 0 0.584961 2.23884 0.584961 5C0.584961 7.76116 2.8238 10 5.58496 10C8.34612 10 10.585 7.76116 10.585 5C10.585 2.23884 8.34612 0 5.58496 0ZM5.58496 9.15179C3.29255 9.15179 1.43318 7.29241 1.43318 5C1.43318 2.70759 3.29255 0.848214 5.58496 0.848214C7.87737 0.848214 9.73675 2.70759 9.73675 5C9.73675 7.29241 7.87737 9.15179 5.58496 9.15179Z"
			fill={props?.fill ?? "#FF865C"}
		/>
		<path
			d="M7.53482 6.4119L5.94331 5.26123V2.49895C5.94331 2.44985 5.90313 2.40967 5.85402 2.40967H5.31719C5.26808 2.40967 5.22791 2.44985 5.22791 2.49895V5.57261C5.22791 5.60163 5.2413 5.62842 5.26474 5.64516L7.11072 6.99114C7.1509 7.02016 7.2067 7.01123 7.23572 6.97217L7.55491 6.5369C7.58393 6.49561 7.575 6.4398 7.53482 6.4119Z"
			fill={props?.fill ?? "#FF865C"}
		/>
	</svg>
);

export default ScheduledLiveStreamIcon;

