export const variables = {
	// ---------------------------------------------------------------------------
	// Colors
	// ---------------------------------------------------------------------------
	colors: {
		white: "#ffffff",
		black: "#000000",
		coral: "#FF865C",
		trout: "#4F5661",
		iron: "#E3E5E7",
		burntSienna: "#EB5757",
		shark: "#282B2E",
		athensGray: "#f7f7f8",
		raven: "#787D86",
		porcelain: "#f6f7f8",
		jungleGreen: "#27ae60",
		forgetMeNot: "#fff2ee",
	},

	// ---------------------------------------------------------------------------
	// Responsive rules
	// ---------------------------------------------------------------------------
	responsive: {
		highres: "1440px",
		desktop: "1200px",
		tablet: "992px",
		mobile: "768px",
	},
};
