// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { Alert, notification } from "antd";
import { useSelector } from "react-redux";
import { validateSubdomain } from "helpers/Form/commonFormValidations";
import InputField from "components/UI/Form/InputField";
import { Field, Form } from "react-final-form";
// -----------------------------------------------------------------------------
// Actions and selectors
// -----------------------------------------------------------------------------
import { selectGymSaving } from "store/selectors";

// -----------------------------------------------------------------------------
// Components, utils and Styles
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { Paragraph2 } from "themes/default/_typography";
import { getClientHost } from "helpers/constants";
import StyledSubdomainForm from "./styles";
import { useGetDomainGymQuery, useUpdateGymSubdomainMutation } from "store/api/api";
import { useNavigate, useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const SubdomainForm = () => {
	const gymSaving = useSelector(selectGymSaving);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const gymData = domainGymResponse?.data || {};
	const clientHost = getClientHost();

	const [updateGymTrigger, update] = useUpdateGymSubdomainMutation();
	const [newSubdomain, setNewSubDomain] = useState("");
	const navigate = useNavigate();

	const handleSaveSubdomain = async ({ subdomain: newSubdomain }) => {
		if (newSubdomain && newSubdomain?.trim() !== "" && newSubdomain?.trim() !== gym) {
			const formattedSubdomain = newSubdomain.replace(/[^a-z0-9]/gi, "").toLowerCase();
			const { data, error } = await updateGymTrigger({
				oldSubdomain: gym,
				newSubdomain: formattedSubdomain,
				gymId: gymData?.id,
			});
			if (data) {
				notification.success({
					message: "Subdomain Updated",
					description: "Your subdomain has been updated successfully",
				});
				setNewSubDomain("");
				return navigate(`/${formattedSubdomain}/dashboard/settings`);
			}
			if (error.status === 403) {
				return notification.error({
					message: "Subdomain Error",
					description: "The requested subdomain is already taken",
				});
			}
			if (error) {
				notification.error({
					message: "Something Went Wrong Updating Your Subdomain",
					description: "Pease Try again alter or message support@sweatbase.com",
				});
			}
		}
	};

	return (
		<Form id="SubdomainForm" initialValues={{ current: `${clientHost}/${gym}` }} onSubmit={handleSaveSubdomain}>
			{({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<StyledSubdomainForm className="sb-subdomain-form">
							<Alert
								className="sb-subdomain-form__alert"
								message="Caution"
								description="This field will be used as your subdomain name, please choose carefully"
								type="warning"
								showIcon
							/>
							<label className="sb-subdomain-form__label">Current Subdomain</label>
							<Field component="input" name="current" style={{ marginBottom: "20px" }}>
								{({ input, meta }) => <InputField disabled type="text" input={input} meta={meta} />}
							</Field>
							<label className="sb-subdomain-form__label">New Subdomain</label>
							<Field
								component="input"
								name="subdomain"
								validate={(value) => validateSubdomain(value || gym)}
								style={{ marginBottom: "20px" }}
							>
								{({ input, meta }) => (
									<>
										<InputField
											type="text"
											input={{
												...input,
												onChange: (event) => {
													input.onChange?.(event);
													setNewSubDomain(event.target.value);
												},
											}}
											meta={{
												...meta,
												error: newSubdomain === gym ? "New subdomain should not be same as current" : meta.error,
											}}
										/>
									</>
								)}
							</Field>
							<label className="sb-subdomain-form__label">Preview New Subdomain</label>
							<Field component="input" name="current" style={{ marginBottom: "20px" }}>
								{({ input, meta, ...rest }) => {
									return (
										<InputField
											disabled
											type="text"
											input={{ ...input, value: `${clientHost}/${newSubdomain}` }}
											meta={meta}
										/>
									);
								}}
							</Field>
							<Button loading={update.isLoading} className="primary-filled" saveIcon uppercase>
								<Paragraph2>Save Changes</Paragraph2>
							</Button>
							{gymSaving && <LoadingMask />}
						</StyledSubdomainForm>
					</form>
				);
			}}
		</Form>
	);
};

export default SubdomainForm;
