import React from "react";

const KeyIcon = (params) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M14.9244 13.4107C12.0735 11.7618 8.37685 11.9954 5.73332 14.2586C2.3808 17.1288 1.98884 22.186 4.85906 25.5385C7.72927 28.891 12.7864 29.283 16.1389 26.4128C18.7825 24.1495 19.5829 20.533 18.393 17.4621L22.7988 13.69L26.2674 17.7414L30.3188 14.2729L26.8502 10.2215L28.8759 8.48722L25.4074 4.43584L14.9244 13.4107ZM12.6704 22.3614C11.5563 23.3152 9.86429 23.1841 8.91044 22.0699C7.95659 20.9558 8.08773 19.2638 9.20186 18.31C10.316 17.3561 12.008 17.4873 12.9618 18.6014C13.9157 19.7155 13.7845 21.4075 12.6704 22.3614Z"
			fill="currentColor"
		/>
	</svg>
);

export default KeyIcon;
