import styled from "styled-components";

const StyledDividerHeader = styled.div`
	.sb-divider-header {
		&__inner {
			background: ${({ theme }) => theme.colors.black};
			margin-bottom: 40px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 8px 16px;
			position: relative;
			width: calc(100% - 32px);

			&--uppercase {
				text-transform: uppercase;
			}

			&:before {
				content: "";
				width: 10px;
				height: 32px;
				position: absolute;
				bottom: 0;
				right: -28px;
				background: ${({ theme }) => theme.colors.black};
				transform: skew(-14deg);
			}

			&:after {
				content: "";
				width: 16px;
				height: 32px;
				position: absolute;
				bottom: 0;
				right: -12px;
				background: ${({ theme }) => theme.colors.black};
				transform: skew(-14deg);
			}
		}
	}
`;

export default StyledDividerHeader;
