// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import ContactInformationForm from "./ContactInformationForm";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function ContactInformation() {
	return (
		<div className="sb-creator-creation">
			<div className="sb-creator-creation__inner">
				<div className="sb-creator-creation__inner__title">
					<h1 className="sb-creator-creation__inner__title__title">How Can Members Reach You?</h1>
					<p className="sb-creator-creation__inner__title__subtitle">
						Provide your public contact information for your users.
					</p>
				</div>
				<div className="sb-creator-creation__inner__header">
					<p className="sb-creator-creation__inner__header__text">CONTACT</p>
				</div>
				<ContactInformationForm />
			</div>
		</div>
	);
}

export default ContactInformation;
