import styled from "styled-components";

const StyledFormSelect = styled.div`
	width: 100%;

	&.sb-form-select {
		.ant-select-selector {
			background: ${({ theme }) => theme.colors.white};
			border-radius: 100px;
			height: 46px;
		}

		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
			background: ${({ theme }) => theme.colors.white};
			border: 1px solid ${({ theme }) => theme.colors.iron};
			border-radius: 100px;
			height: 46px;
		}

		.ant-select-selection-item,
		.ant-select-selection-placeholder {
			padding: 8px 6px;
		}

		.ant-form-item-label {
			label {
				font-family: "DM Sans", sans-serif;
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				color: ${({ theme }) => theme.colors.trout};
			}
		}

		.ant-form-item-explain-error {
			padding-left: 12px;
		}

		&__dark {
			.ant-select-selector {
				background: ${({ theme }) => theme.colors.shark};
				border: 1px solid ${({ theme }) => theme.colors.shark};
				border-radius: 100px;
				height: 46px;
				color: ${({ theme }) => theme.colors.white};
			}

			.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
				background: ${({ theme }) => theme.colors.white};
				border: null;
				border-radius: 100px;
				height: 46px;
			}

			.ant-select-selection-item,
			.ant-select-selection-placeholder {
				padding: 8px 6px;
			}

			.ant-form-item-label {
				label {
					font-family: "DM Sans", sans-serif;
					font-style: normal;
					font-weight: 700;
					font-size: 12px;
					line-height: 16px;
					text-transform: uppercase;
					color: ${({ theme }) => theme.colors.white};
				}
			}

			.ant-form-item-explain-error {
				padding-left: 12px;
			}
		}
	}
`;

export default StyledFormSelect;

