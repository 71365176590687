// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Components and Styles
// -----------------------------------------------------------------------------
import { Heading4, Paragraph4 } from "themes/default/_typography";
import StyledAccountCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AccountCard = ({ onClick, icon, header, text, customColor }) => {
	return (
		<StyledAccountCard>
			<Card hoverable onClick={onClick} className="sb-account-card">
				<div className={`sb-account-card__icon ${customColor ? "card-custom-color" : ""}`}>{icon}</div>
				<div className="sb-account-card__body">
					{header && (
						<Heading4 className={`sb-account-card__body__header ${customColor ? "card-custom-color" : ""}`}>
							{header}
						</Heading4>
					)}
					{text && <Paragraph4 className="sb-account-card__body__text">{text}</Paragraph4>}
				</div>
				<div className="sb-account-card__arrow" />
			</Card>
		</StyledAccountCard>
	);
};

export default AccountCard;
