import React from "react";

const PlusIcon = (params) => (
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M11.8333 5.58335V6.41669C11.8333 6.52719 11.7894 6.63318 11.7113 6.71132C11.6331 6.78946 11.5272 6.83335 11.4167 6.83335H6.83332V11.4167C6.83332 11.5272 6.78943 11.6332 6.71129 11.7113C6.63315 11.7895 6.52716 11.8334 6.41666 11.8334H5.58332C5.47282 11.8334 5.36684 11.7895 5.2887 11.7113C5.21056 11.6332 5.16666 11.5272 5.16666 11.4167V6.83335H0.583323C0.472816 6.83335 0.366836 6.78946 0.288695 6.71132C0.210555 6.63318 0.166656 6.52719 0.166656 6.41669V5.58335C0.166656 5.47285 0.210555 5.36687 0.288695 5.28873C0.366836 5.21059 0.472816 5.16669 0.583323 5.16669H5.16666V0.583354C5.16666 0.472847 5.21056 0.366866 5.2887 0.288726C5.36684 0.210586 5.47282 0.166687 5.58332 0.166687H6.41666C6.52716 0.166687 6.63315 0.210586 6.71129 0.288726C6.78943 0.366866 6.83332 0.472847 6.83332 0.583354V5.16669H11.4167C11.5272 5.16669 11.6331 5.21059 11.7113 5.28873C11.7894 5.36687 11.8333 5.47285 11.8333 5.58335Z"
			fill="currentColor"
		/>
	</svg>
);

export default PlusIcon;
