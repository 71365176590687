// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Heading3 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledEarningsTable from "./styles";
import moment from "moment";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EarningsTable = (props) => {
	return (
		<StyledEarningsTable className="sb-earnings-table">
			<Heading3>Payouts</Heading3>
			<table className="sb-earnings-table__card">
				<thead>
					<tr>
						<th>Date</th>
						<th>Amount</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{props?.transfers?.length > 0 ? (
						props.transfers.map((transfer) => (
							<tr key={transfer.id}>
								<td>{moment.unix(transfer.arrival_date).format("MMM Do YYYY")}</td>
								<td>${(transfer.amount / 100).toFixed(2)}</td>
								<td>{transfer.status}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="3" style={{ textAlign: "center", padding: "50px" }}></td>
						</tr>
					)}
				</tbody>
			</table>
		</StyledEarningsTable>
	);
};

export default EarningsTable;

