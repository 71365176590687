// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot, useRecoilSnapshot } from "recoil";
import { Provider } from "react-redux";

// -------------------------------------------------------------------------------
// Utils
// -------------------------------------------------------------------------------
import "./instrument";
import reportWebVitals from "./reportWebVitals";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import App from "./App";

// -----------------------------------------------------------------------------
// Importing styles, themes, store and assets
// -----------------------------------------------------------------------------
import store from "store";
import { theme } from "./themes";
import "rc-dropdown/assets/index.css";
import "scss/index.scss";
import "./antd-overrides.scss";

// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------
const Theme = theme.Default;

function DebugObserver() {
	const snapshot = useRecoilSnapshot();
	React.useEffect(() => {
		for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
			console.debug(node.key, snapshot.getLoadable(node));
		}
	}, [snapshot]);

	return null;
}
// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
ReactDOM.render(
	<RecoilRoot>
		<DebugObserver />
		<Provider store={store}>
			<Theme>
				<App />
			</Theme>
		</Provider>
	</RecoilRoot>,
	document.getElementById("root")
);

reportWebVitals();
