import styled from "styled-components";

const StyledLoadingMask = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(200, 200, 200, 0.8);
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default StyledLoadingMask;
