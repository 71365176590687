// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useNavigate, useParams } from "react-router";
import message from "antd/lib/message";
import { Form, Button } from "antd";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import Logo from "../../images/logo.svg";
import { useConfirmResetPasswordMutation } from "store/api/api";
import InputField from "components/UI/Form/InputField";
import { verifyPassword } from "helpers/Form/commonFormValidations";
import "./styles.scss";
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function ResetPassword() {
	const navigate = useNavigate();
	const { userId, token, subdomain } = useParams();

	const [confirmResetPasswordTrigger] = useConfirmResetPasswordMutation();
	const [form] = Form.useForm();

	const [password, updatePassword] = React.useState("");
	const [passConfirm, updatePassConfirm] = React.useState("");

	const handleChange = (e) => {
		updatePassword(e?.target?.value);
	};

	const submit = async () => {
		if (password === passConfirm && !verifyPassword(password)) {
			try {
				await confirmResetPasswordTrigger({ password, token, userId }).unwrap();
				message.success("Password reset successfully! You will be redirected to the login page shortly.");

				setTimeout(() => {
					navigate(`/login/${subdomain}`);
				}, 3000);
			} catch (error) {
				notification.error({
					message: "An unknown error occurred",
				});
				console.error("An unknown error occurred", error);
			}
		}
	};

	return (
		<div className="sb-login">
			<div className="sb-login__container">
				<div className="sb-login__container__form-container">
					<div className="sb-login__container__form-container__header">
						<img className="sb-login__container__form-container__header__image" src={Logo} alt="logo" />
						<h1 className="sb-login__container__form-container__header__title">Reset Password</h1>
					</div>
					<div className="sb-login__container__form-container__form">
						<div className="sb-signup-form">
							<Form name="basic" layout="vertical" autoComplete="off" form={form}>
								<Form.Item
									label="Password"
									name="password"
									hasFeedback
									required={false}
									rules={[
										{
											required: true,
											message: "",
											pattern: /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,50}$/,
										},
										() => ({
											validator(_, value) {
												if (!value || !verifyPassword(value)) {
													return Promise.resolve();
												}
												return Promise.reject(new Error(verifyPassword(value)));
											},
										}),
									]}
								>
									<InputField value={password} onChange={handleChange} placeholder="password" type="password" />
								</Form.Item>

								<Form.Item
									label="Password Confirmation"
									name="password-confirmation"
									hasFeedback
									required={false}
									validateStatus={
										form.getFieldValue("password-confirmation") &&
										form.getFieldValue("password") !== form.getFieldValue("password-confirmation")
											? "error"
											: undefined
									}
									help={
										form.getFieldValue("password-confirmation") &&
										form.getFieldValue("password") !== form.getFieldValue("password-confirmation")
											? "The two passwords that you entered do not match!"
											: ""
									}
									rules={[
										{
											required: true,
											message: "Please confirm your new password!",
										},
									]}
								>
									<div className="input-field-wrapper">
										<InputField
											type="password"
											value={passConfirm}
											onChange={(e) => updatePassConfirm(e?.target?.value)}
											placeholder="confirm password"
										/>
									</div>
								</Form.Item>

								<Form.Item wrapperCol={{ offset: 8, span: 16 }} required={false}>
									<Button type="primary" htmlType="button" onClick={submit}>
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
				<div className="sb-login__container__image-container">
					<div className="sb-login__container__image-container__inner">
						<div className="sb-login__container__image-container__inner__image" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
