import { createSelector } from "@reduxjs/toolkit";
import selectDomainWorkouts from "./selectDomainWorkouts";

export default createSelector(selectDomainWorkouts, (workouts) => {
	const fullList = workouts.map(({ categories }) => categories);
	const fullListMerged = [].concat.apply([], fullList);
	const finalList = fullListMerged.reduce((prev, curr) => {
		if (!prev.some((e) => e.category_id === curr.category_id)) {
			prev.push(curr);
		}

		return prev;
	}, []);

	return finalList;
});
