import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const workoutsSlice = createSlice({
	name: "workouts",
	initialState,
	reducers,
});

const { actions, reducer } = workoutsSlice;

export const {
	createWorkout,
	editWorkout,
	deleteWorkout,
	updateSelectedWorkout,
	updateWorkoutsErrors,
	updateWorkoutsLoading,
	updateSelectedMuxVideoUrl,
} = actions;
export default reducer;
