// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { Label1 } from "themes/default/_typography";
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";
import { updateMembersWorkoutsSelectedCategories } from "store/slices";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { bem } from "helpers/Bem";
import StyledCategoriesFilter from "./styles";
import { useGetCategoriesQuery, useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CategoriesFilter = () => {
	const dispatch = useDispatch();
	//const categories = useSelector(selectDomainWorkoutsCategories);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });
	const categories = categoriesResponse.data?.categories || [];
	const selectedCategories = useSelector(selectMembersWorkoutsSelectedCategories);

	const handleCategoryClick = (category_id) => {
		if (!selectedCategories.includes(category_id)) {
			if (category_id === 0) {
				dispatch(updateMembersWorkoutsSelectedCategories([0]));
			} else {
				if (selectedCategories.includes(0)) {
					dispatch(updateMembersWorkoutsSelectedCategories([category_id]));
				} else {
					dispatch(updateMembersWorkoutsSelectedCategories([...selectedCategories, category_id]));
				}
			}
		} else if (category_id !== 0) {
			const newCategories = [...selectedCategories];

			newCategories.splice(
				selectedCategories.findIndex((c) => c === category_id),
				1
			);

			dispatch(updateMembersWorkoutsSelectedCategories(newCategories.length > 0 ? newCategories : [0]));
		}
	};

	return (
		<StyledCategoriesFilter>
			<div className="sb-categories-filter">
				<div
					className={bem`sb-categories-filter__category--${{
						active: selectedCategories.includes(0),
					}}`}
					onClick={() => handleCategoryClick(0)}
				>
					<Label1>All</Label1>
				</div>
				{categories.map((category) => {
					const active = selectedCategories.includes(category.id);

					return (
						<div
							key={category.id}
							className={bem`sb-categories-filter__category--${{ active }}`}
							onClick={() => handleCategoryClick(category.id)}
						>
							<Label1>{category.name}</Label1>
						</div>
					);
				})}
			</div>
		</StyledCategoriesFilter>
	);
};

export default CategoriesFilter;
