import styled from "styled-components";

const StyledWorkoutDetailsForm = styled.div`
	padding: 16px 32px;

	.sb-workout-details-form {
		&__form {
			margin: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 24px;

			&__field {
				width: 100%;

				&__label {
					&__main {
						margin-right: 6px;
					}
				}

				#workoutEquipment {
					color: ${({ theme }) => theme.colors.coral};
				}
			}

			&__activation-schedule {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&__label {
					&__main {
						margin-right: 6px;
					}
				}
			}

			&__actions {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 16px;
			}
		}
	}
`;

export default StyledWorkoutDetailsForm;
