import styled from "styled-components";

const StyledFormInput = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	.sb-form-input {
	}

	input {
		background: ${({ theme }) => theme.colors.white};
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 100px;
		height: 46px;
		padding: 16px;
	}

	.ant-form-item {
		width: 100%;
	}

	.ant-form-item-explain-error {
		padding-left: 12px;
		background: ${({ theme }) => theme.colors.forgetMeNot};
		width: fit-content;
		margin-top: 8px;
		padding: 5px 10px;
		border-radius: 100px;
		font-family: "DM Sans", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 17px;
		color: ${({ theme }) => theme.colors.burntSienna};
	}
`;

export default StyledFormInput;

