// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback } from "react";
import { Card } from "antd";
// import { useDispatch } from "react-redux";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
import { Heading4, Paragraph14 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
// import { detachBankAccount } from "store/slices";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { PencilIcon, TrashIcon, VisaIcon } from "components/Icons";
import StyledBankAccountCard from "./styles";
import { EditFilled } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const BankAccountCard = ({ className, bankAccount, onEdit, ...other }) => {
	return (
		<StyledBankAccountCard className={`${className} sb-bank-account-card`} {...other}>
			<Card hoverable>
				<div className="sb-bank-account-card__inner">
					<div className="sb-bank-account-card__inner__left">
						<div className="sb-bank-account-card__inner__left__data">
							<Heading4>{bankAccount?.bank_name || "Bank Name"}</Heading4>
							<Paragraph14>Routing Number: {bankAccount?.routing_number || "123456789"}</Paragraph14>
						</div>
					</div>
					<div className="sb-bank-account-card__inner__left">
						<div
							style={{
								display: "flex",
								height: "32px",
								width: "32px",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: "50%",

								backgroundColor: "#F4F4F6",
							}}
							onClick={onEdit}
						>
							<EditFilled style={{ color: "#4F5661" }} />
						</div>
					</div>
					{/*Make this Switch for Default Active Or Not */}
				</div>
			</Card>
		</StyledBankAccountCard>
	);
};

export default BankAccountCard;

