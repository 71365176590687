// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph3, Heading3 } from "themes/default/_typography";
import { PersonPlusIcon, KeyIcon, DumbellIcon, ShareIcon, StarIcon } from "components/Icons";
import { AccountCard } from "components/Common/Cards/AccountCard";
import { DeleteUserModal } from "components/Modals/DeleteUserModal";
import ProfileBanner from "components/UI/ProfileBanner";

// -----------------------------------------------------------------------------
// Styles and helpers
// -----------------------------------------------------------------------------
import useLogout from "utils/useLogout";
import StyledProfile from "./styles";
import { useNavigate, useParams } from "react-router";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Profile = () => {
	const { gym } = useParams();
	const navigate = useNavigate();
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const logoutHook = useLogout(navigate, gym);

	const handleLogout = useCallback(() => {
		return logoutHook.logout();
	}, [logoutHook]);

	const handleAccountSettingsClick = useCallback(() => {
		navigate(`../account-settings`);
	}, [navigate]);

	const handleGymClick = useCallback(() => {
		navigate(`../my-gym`);
	}, [navigate]);

	const handleMembershipClick = useCallback(() => {
		navigate(`../consumer-membership`);
	}, [navigate]);

	return (
		<StyledProfile>
			<div className="sb-profile-container">
				<ProfileBanner title="My Profile" picture={true} />
				<div className="sb-profile-container__body">
					<Heading3 className="sb-profile-container__body__title">Account</Heading3>
					<AccountCard
						onClick={handleAccountSettingsClick}
						customColor={false}
						icon={<KeyIcon />}
						header={"Account Settings"}
						text={"Manage email, phone number, profile information"}
					/>
					<AccountCard
						onClick={handleGymClick}
						customColor={false}
						icon={<DumbellIcon />}
						header={"My Gym"}
						text={"About your gym, address, gym philosophy"}
					/>
					<AccountCard
						onClick={handleMembershipClick}
						customColor={false}
						icon={<StarIcon />}
						header={"Membership"}
						text={"Edit payment information, payment dates, receipts"}
					/>
					{/* <h1 className="sb-profile-container__body__title">Share the app</h1>
					<AccountCard
						onClick={() => {}}
						customColor={true}
						icon={<PersonPlusIcon />}
						header={"Send a Guest Pass"}
						text={"Invite a friend to join you on a workout"}
					/>
					<AccountCard
						onClick={() => {}}
						customColor={false}
						icon={<ShareIcon />}
						header={"Share the App"}
						text={"Share the app with friends"}
					/> */}
					<Button
						className="sb-profile-container__body__logout primary-outlined--delete"
						onClick={() => setShowConfirmDeleteModal(true)}
						uppercase
					>
						<Paragraph3>Delete Account</Paragraph3>
					</Button>
					<Button className="sb-profile-container__body__logout primary-outlined" onClick={handleLogout} uppercase>
						<Paragraph3>Log out</Paragraph3>
					</Button>
				</div>
			</div>
			<DeleteUserModal open={showConfirmDeleteModal} setOpen={setShowConfirmDeleteModal} />
		</StyledProfile>
	);
};

export default Profile;
