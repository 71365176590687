// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Heading8, Paragraph24 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import { formatNumber } from "helpers/generic";
import StyledLegendCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const LegendCard = ({ amount, label, color }) => {
	return (
		<StyledLegendCard>
			<Card hoverable className="sb-legend-card">
				<div className="sb-legend-card__indicator">
					<div className="sb-legend-card__indicator__circle" style={{ backgroundColor: color }} />
				</div>
				<div className="sb-legend-card__data">
					<Heading8>{formatNumber(amount)}</Heading8>
					<Paragraph24>{label}</Paragraph24>
				</div>
			</Card>
		</StyledLegendCard>
	);
};

export default LegendCard;
