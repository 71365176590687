// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Paragraph13 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledDividerHeader from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const DividerHeader = ({ text, uppercase, style }) => {
	return (
		<StyledDividerHeader className="sb-divider-header">
			<div
				className={`sb-divider-header__inner ${uppercase ? "sb-divider-header__inner--uppercase" : ""}`}
				style={style}
			>
				<Paragraph13>{text}</Paragraph13>
			</div>
		</StyledDividerHeader>
	);
};

export default DividerHeader;

