import styled from "styled-components";

const StyledAccountSettings = styled.div`
	.sb-account-settings {
		&__body {
			background: ${({ theme }) => theme.colors.athensGray};
			width: 100%;
			min-height: calc(100vh - 400px);
			border-top-left-radius: 35px;
			border-top-right-radius: 35px;
			margin-top: -80px;
			padding: 68px 64px;

			&__inner {
				max-width: 800px;
				margin: 50px auto 20px auto;
			}
		}
	}
`;

export default StyledAccountSettings;

