import styled from "styled-components";

const StyledSearchInput = styled.div`
	width: 100%;

	.sb-search-input {
		&__input {
			height: 40px;
			border: 1px solid ${({ theme }) => theme.colors.iron};
			border-radius: 100px;
			padding: 0 16px;

			.ant-input-prefix {
				margin-right: 8px;
			}
		}
	}
`;

export default StyledSearchInput;
