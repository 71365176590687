import styled from "styled-components";

const StyledHeader = styled.div`
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background: transparent;
	display: flex;
	justify-content: space-between;
	padding: 30px;

	.sb-header {
		&__logo-section {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

export default StyledHeader;
