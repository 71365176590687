// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Card, Divider } from "antd";

// -------------------------------------------------------------------------------
// Component and others
// -------------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { CheckIcon } from "components/Icons";
import { Heading2, Heading6, Heading7, Paragraph8, Paragraph9, Paragraph10, Span1 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledPlanCard from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const PlanCard = ({
	isCurrentSubscription,
	icon,
	type,
	title,
	description,
	price,
	price_id,
	priceFrequency,
	priceDescription,
	featuresList,
	onClick,
	className,
	...other
}) => {
	return (
		<StyledPlanCard className={`${className} sb-plan-card`} {...other}>
			<Card hoverable={!isCurrentSubscription}>
				<div className="sb-plan-card__icon">{icon}</div>
				<div className="sb-plan-card__title">
					<Heading6>{title}</Heading6>
				</div>
				<div className="sb-plan-card__description">
					<Paragraph8>{description}</Paragraph8>
				</div>
				<Divider />
				<div className="sb-plan-card__price">
					{price.toLowerCase() === "free" ? (
						<Heading7>{price}</Heading7>
					) : (
						<>
							<Heading2>{price}</Heading2>
							{priceFrequency && <Span1>/{priceFrequency}</Span1>}
						</>
					)}
				</div>
				<div className="sb-plan-card__price-description">
					<Paragraph8>{priceDescription}</Paragraph8>
				</div>
				{isCurrentSubscription && (
					<Button className="primary-outlined-color " disabled uppercase onClick={onClick}>
						<CheckIcon style={{ color: "#ff865c" }} />
						<Paragraph9 style={{ color: "#ff865c" }}>Selected</Paragraph9>
					</Button>
				)}
				{!isCurrentSubscription && (
					<Button className="primary-filled--branding-primary-color" uppercase onClick={onClick}>
						<Paragraph9>{type === "elite" ? "Contact Us" : "Choose plan"}</Paragraph9>
					</Button>
				)}

				<Divider />
				<div className="sb-plan-card__list">
					{featuresList.map((feature) => (
						<div key={feature} className="sb-plan-card__list__item">
							<div className="sb-plan-card__list__item__icon">
								<CheckIcon />
							</div>
							<Paragraph10>{feature}</Paragraph10>
						</div>
					))}
				</div>
			</Card>
		</StyledPlanCard>
	);
};

export default PlanCard;

