import React from "react";
import VideoPreviewContainer from "./VideoPreviewContainer";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Ajax from "../../../utils/api";
import ajaxRoutes from "../../../utils/ajaxRoutes";

import "./index.scss";
import { Tooltip } from "antd";
import { decodeString } from "utils";

function VideoPreview({ record, updateFileSelected, updateIsShowingVideoPlayer }) {
	const handleVideoPreviewClick = async () => {
		if (!record?.playback_id) {
			return;
		}
		if (!record.video_url) {
			const videoUrlResponse = await Ajax.call(ajaxRoutes.GET_MUX_VIDEO_URL(record?.playback_id));
			const { url } = videoUrlResponse;
			updateFileSelected(url);
		} else {
			updateFileSelected(record.video_url);
		}

		updateIsShowingVideoPlayer(true);
	};
	const toolTipTitle = !record?.playback_id ? "" : record.duration > 0 ? "Open Video" : "Video is still processing";

	return (
		<div
			style={{ cursor: record.duration > 0 ? "pointer" : null }}
			onClick={record.duration > 0 ? handleVideoPreviewClick : null}
		>
			<Tooltip title={toolTipTitle}>
				<Row>
					<Col>
						<VideoPreviewContainer record={record} />
					</Col>
					<Col>
						<h2 className="video-preview-title">{record ? decodeString(record.name) : ""}</h2>
					</Col>
				</Row>
			</Tooltip>
		</div>
	);
}

export default VideoPreview;
