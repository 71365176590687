import styled from "styled-components";

const StyledCalendarCard = styled.div`
	flex: 1;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 24px;

	.sb-calendar-card {
		&__inner {
			border-radius: 24px;
			&__divider {
				width: "100%";
				height: 1px;
				background-color: #e3e5e7;
				margin: 32px;
				margin-top: 0px;
				margin-bottom: 0px;
			}

			&__scheduled-list {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 16px;
				padding: 32px;
				padding-top: 8px;
				&__item {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					background-color: #f4f4f6;
					border-radius: 4px;
					align-items: center;
					width: 100%;
					padding: 12px;
					flex-wrap: wrap;

					&__left {
						display: flex;
						flex-direction: column;
						gap: 8px;

						h1 {
							font-family: "DM Sans";
							font-style: normal;
							font-weight: 700;
							font-size: 18px;
							line-height: 100%;
							color: #000000;
							margin-bottom: 0;
						}

						p {
							font-family: "DM Sans";
							font-style: normal;
							font-weight: 400;
							font-size: 12px;
							line-height: 20px;
							/* identical to box height, or 167% */

							text-align: left;
							letter-spacing: -0.24px;

							color: #000000;

							opacity: 0.5;
							margin-bottom: 0;
						}

						h6 {
							font-family: "DM Sans";
							font-style: normal;
							font-weight: 700;
							font-size: 12px;
							line-height: 20px;
							color: #219653;
							margin-bottom: 0;
						}

						&__subtitle-container {
							display: flex;
							flex-direction: row;
							gap: 8px;
							align-items: center;
						}

						&__tags-container {
							display: flex;
							flex-direction: row;
							gap: 8px;
							align-items: center;
							flex-wrap: wrap;

							&__tag {
								border-radius: 12px;
								padding: 4px 8px;
								font-weight: 500;
								font-size: 11px;
								line-height: 12px;
								color: #4f5661;
								background-color: #e3e5e7;
							}
						}
					}

					&__right {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						justify-content: space-between;
						gap: 16px;
						h1 {
							font-family: "DM Sans";
							font-style: normal;
							font-weight: 700;
							font-size: 18px;
							line-height: 100%;
							color: #000000;
							margin-bottom: 0;
						}

						p {
							color: #eb5757;
							font-style: italic;
							margin-bottom: 0;
						}

						&__in-progress {
							display: flex;
							flex-direction: row;
							padding: 4px 8px;
							gap: 8px;
							align-items: center;
							justify-content: center;
							border-radius: 100px;
							background: rgba(0, 0, 0, 0.5);
							p {
								font-family: "DM Sans";
								font-style: normal;
								font-weight: 700;
								font-size: 10px;

								color: #ffffff;
							}
						}
					}
				}
			}

			&__scheduled-header {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
				padding: 32px;
				padding-bottom: 0px;

				&__subtitle {
					padding: 16px;
					padding-left: 32px;
					padding-bottom: 0px;
					p {
						font-family: "DM Sans";
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: #787d86;
						margin-bottom: 0;
					}
				}

				&__text-container {
					display: flex;
					justify-content: center;
					align-items: center;

					p {
						font-family: "DM Sans";
						font-style: normal;
						font-weight: 400;
						font-size: 24px;
						line-height: 24px;
						margin-bottom: 0;
						color: #000000;
					}
				}
			}

			&__header-section {
				padding: 32px;
				display: flex;
				flex-direction: row;
				gap: 8px;
				display: flex;
				justify-content: space-between;

				&__left {
					display: flex;
					gap: 8px;

					h1 {
						font-style: normal;
						font-weight: 700;
						font-size: 32px;
						line-height: 32px;
					}

					h2 {
						font-style: normal;
						font-weight: 400;
						font-size: 32px;
						line-height: 32px;
					}
				}

				&__right {
					display: flex;
					gap: 8px;

					&__button {
						width: 48px;
						height: 48px;
						border-radius: 8px;
						border: none;
						color: #787d86;
						background-color: #ffffff;
					}
				}

				&__categories {
				}
			}

			&__week-section {
				padding: 32px;
				display: flex;
				flex-direction: column;
				gap: 35px;
				display: flex;
				justify-content: space-between;

				&__days {
					display: flex;
					flex-direction: row;
					gap: 8px;
					display: flex;
					justify-content: space-between;
					&__day {
						width: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				h1 {
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 20px;
				}

				h2 {
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 20px;
				}
			}
		}
	}

	.ant-card-body {
		padding: 0;
	}

	.ant-card-body {
		padding: 0;
	}
`;

export default StyledCalendarCard;

