import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const assetsSlice = createSlice({
	name: "assets",
	initialState,
	reducers,
});

const { actions, reducer } = assetsSlice;

export const {
	updateAssetsErrors,
	updateAssetsLoading,
	uploadAssetToS3,
	uploadAssetToMux,
	updateAssetUploadProgress,
	updateAssetUploadId,
} = actions;
export default reducer;
