// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import MemberPagesForm from "./MemberPagesForm";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
const MemberPages = (props) => {
	return (
		<div className="sb-creator-creation">
			<div className="sb-creator-creation__inner">
				<div className="sb-creator-creation__inner__title">
					<h1 className="sb-creator-creation__inner__title__title">Your Member Pages</h1>
					<p className="sb-creator-creation__inner__title__subtitle">
						This is a preview of your personalized SWEATBASE website where your members will come to sign up and
						subscribe.
					</p>
				</div>
				<MemberPagesForm source={props.source} />
			</div>
		</div>
	);
};

export default MemberPages;
