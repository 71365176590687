// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Styles and helpers
// -------------------------------------------------------------------------------
import StyledSteps from "./styles";
import { bem } from "helpers/Bem";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Steps = ({ steps, className }) => {
	return (
		<StyledSteps className={`sb-steps ${className}`}>
			{steps.map(({ active }, key) => (
				<div
					key={`sb-steps-${key}`}
					className={bem`sb-steps__step--${{
						active,
					}}`}
				/>
			))}
		</StyledSteps>
	);
};

export default Steps;
