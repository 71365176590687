import { atom } from "recoil";

export const userAtom = atom({
	key: "user",
	default: null,
});

export const categoriesAtom = atom({
	key: "categories",
	default: [],
});

export const initializingAtom = atom({
	key: "initializing",
	default: true,
});

export const uploadProgressAtom = atom({
	key: "upload",
	default: -1,
});
