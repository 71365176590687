// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Select, Form } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Label2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Styles
// -------------------------------------------------------------------------------
import StyledFormTagSelect from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const { Option } = Select;

const FormTagSelect = ({ name, label, placeholder, rules, options, className, prefix, postfix, ...rest }) => {
	return (
		<StyledFormTagSelect className={`${className} sb-form-tag-select`}>
			<Form.Item name={name} label={<Label2>{label}</Label2>} rules={rules}>
				<Select mode="tags" allowClear placeholder={placeholder} {...rest}>
					{options.map((opt) => (
						<Option className="sb-form-tag-select__option" key={`${opt.id}-${opt.value}`} value={opt.value}>
							{prefix}
							{opt.value}
							{postfix}
						</Option>
					))}
				</Select>
			</Form.Item>
		</StyledFormTagSelect>
	);
};

export default FormTagSelect;

