import React from "react";

import "./logo.scss";

function Logo() {
	return (
		<svg
			className="logo"
			width="100%"
			height="100%"
			viewBox="0 0 699 222"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="-80" width="894" height="222" fill="black" />
			<path
				d="M120.12 131.36C125.24 137.6 133.4 140.32 141.32 140.32C150.92 140.32 161.64 135.2 161.64 124.32C161.64 104.72 130.52 106 130.52 96.3999C130.52 90.7999 135.16 89.1199 139.96 89.1199C144.84 89.1199 149.32 91.0399 152.92 94.2399C153.32 94.6399 153.88 94.6399 154.36 94.1599L159.24 88.6399C159.64 88.2399 159.64 87.5999 159.16 87.1999C153.88 82.7999 147.16 79.6799 140.28 79.6799C130.2 79.6799 121.08 85.1199 121.08 96.0799C121.08 114.4 152.2 113.28 152.2 124C152.2 129.28 145.88 130.88 141.64 130.88C136.44 130.88 131.08 129.6 127.56 125.52C127.16 125.04 126.6 125.04 126.12 125.36L120.28 129.92C119.8 130.32 119.72 130.88 120.12 131.36Z"
				fill="white"
			/>
			<path
				d="M166.014 82.9599L188.894 138.08C189.054 138.48 189.534 138.8 190.094 138.8C190.574 138.8 191.054 138.48 191.214 138.08L203.134 110.64C203.374 110 203.774 109.76 204.014 109.76C204.334 109.76 204.654 110.08 204.894 110.64L216.814 138.08C216.974 138.48 217.454 138.8 218.014 138.8C218.494 138.8 218.974 138.48 219.134 138.08L222.574 129.76C222.814 129.2 222.814 128.4 222.574 127.84L205.214 88.7999C205.054 88.3999 204.574 88.0799 204.014 88.0799C203.534 88.0799 202.974 88.3999 202.814 88.7999L191.294 114.88C190.974 115.52 190.734 115.84 190.414 115.84C190.094 115.84 189.694 115.52 189.454 114.88L176.174 82.9599C175.934 82.3999 175.374 81.9999 174.734 81.9999H166.654C166.014 81.9999 165.774 82.3999 166.014 82.9599ZM221.134 110.64L225.694 120.48C225.934 121.04 226.254 121.04 226.494 120.48L242.014 82.9599C242.254 82.3999 242.014 81.9999 241.374 81.9999H233.374C232.734 81.9999 232.174 82.3999 231.934 82.9599L221.134 108.72C220.894 109.36 220.894 110.08 221.134 110.64Z"
				fill="white"
			/>
			<path
				d="M250.113 113.44C250.113 114.08 250.512 114.48 251.152 114.48H285.312C285.952 114.48 286.353 114.08 286.353 113.44V106.56C286.353 105.92 285.952 105.52 285.312 105.52H251.152C250.512 105.52 250.113 105.92 250.113 106.56V113.44ZM250.113 130.08V136.96C250.113 137.6 250.512 138 251.152 138H285.312C285.952 138 286.353 137.6 286.353 136.96V130.08C286.353 129.44 285.952 129.04 285.312 129.04H251.152C250.512 129.04 250.113 129.44 250.113 130.08ZM251.152 90.9599H285.312C285.952 90.9599 286.353 90.5599 286.353 89.9199V83.0399C286.353 82.3999 285.952 81.9999 285.312 81.9999H251.152C250.512 81.9999 250.113 82.3999 250.113 83.0399V89.9199C250.113 90.5599 250.512 90.9599 251.152 90.9599Z"
				fill="white"
			/>
			<path
				d="M295.092 138H303.012C303.572 138 304.212 137.6 304.452 137.04L319.972 103.44C320.292 102.8 320.612 102.48 320.932 102.48C321.252 102.48 321.572 102.8 321.892 103.44L329.092 119.12C329.652 120.4 329.172 121.2 327.812 121.2H319.812C319.172 121.2 318.612 121.6 318.372 122.16L315.092 129.2C314.852 129.76 315.092 130.16 315.732 130.16H331.892C333.252 130.16 334.532 130.96 335.092 132.16L337.332 137.04C337.572 137.6 338.212 138 338.852 138H346.692C347.332 138 347.572 137.6 347.332 137.04L322.052 81.9199C321.892 81.5199 321.412 81.1999 320.932 81.1999C320.452 81.1999 319.892 81.5199 319.732 81.9199L294.452 137.04C294.212 137.6 294.452 138 295.092 138Z"
				fill="white"
			/>
			<path
				d="M346.264 90.9599H390.264C390.904 90.9599 391.304 90.5599 391.304 89.9199V83.0399C391.304 82.3999 390.904 81.9999 390.264 81.9999H346.264C345.624 81.9999 345.224 82.3999 345.224 83.0399V89.9199C345.224 90.5599 345.624 90.9599 346.264 90.9599ZM363.384 136.96C363.384 137.6 363.864 138 364.424 138H371.944C372.584 138 372.984 137.6 372.984 136.96V99.7599C372.984 99.1199 372.584 98.7199 371.944 98.7199H364.424C363.864 98.7199 363.384 99.1199 363.384 99.7599V136.96Z"
				fill="white"
			/>
			<path
				d="M401.339 138H424.219C442.619 138 446.539 116.88 435.899 107.92C434.859 107.04 434.699 105.84 435.339 104.64C439.979 95.9199 435.739 83.4399 421.979 82.0799C421.099 81.9999 419.739 81.9999 417.419 81.9999H401.339C400.699 81.9999 400.299 82.3999 400.299 83.0399V136.96C400.299 137.6 400.699 138 401.339 138ZM409.899 115.2C409.899 113.84 410.779 112.96 412.139 112.96H424.219C434.379 112.96 434.379 129.04 424.219 129.04H412.139C410.779 129.04 409.899 128.16 409.899 126.8V115.2ZM409.899 101.68V93.1199C409.899 91.7599 410.779 90.8799 412.139 90.8799H419.739C429.339 90.8799 429.819 102.8 421.019 103.84C420.219 103.92 418.779 103.92 416.539 103.92H412.139C410.779 103.92 409.899 103.04 409.899 101.68Z"
				fill="white"
			/>
			<path
				d="M447.67 138H455.59C456.15 138 456.79 137.6 457.03 137.04L472.55 103.44C472.87 102.8 473.19 102.48 473.51 102.48C473.83 102.48 474.15 102.8 474.47 103.44L481.67 119.12C482.23 120.4 481.75 121.2 480.39 121.2H472.39C471.75 121.2 471.19 121.6 470.95 122.16L467.67 129.2C467.43 129.76 467.67 130.16 468.31 130.16H484.47C485.83 130.16 487.11 130.96 487.67 132.16L489.91 137.04C490.15 137.6 490.79 138 491.43 138H499.27C499.91 138 500.15 137.6 499.91 137.04L474.63 81.9199C474.47 81.5199 473.99 81.1999 473.51 81.1999C473.03 81.1999 472.47 81.5199 472.31 81.9199L447.03 137.04C446.79 137.6 447.03 138 447.67 138Z"
				fill="white"
			/>
			<path
				d="M506.604 131.36C511.724 137.6 519.884 140.32 527.804 140.32C537.404 140.32 548.124 135.2 548.124 124.32C548.124 105.28 518.524 107.12 517.084 95.5999C517.004 94.9599 516.604 94.5599 515.964 94.5599H508.684C508.044 94.5599 507.564 95.0399 507.564 95.5999C507.564 114.4 538.684 113.2 538.684 124C538.684 129.28 532.364 130.88 528.124 130.88C522.924 130.88 517.564 129.6 514.044 125.52C513.644 125.04 513.084 125.04 512.604 125.36L506.764 129.92C506.284 130.32 506.204 130.88 506.604 131.36ZM516.444 83.0399L519.964 89.3599C520.284 89.9199 520.764 90.0799 521.404 89.8399C523.004 89.2799 524.844 89.1199 526.444 89.1199C531.324 89.1199 535.804 91.0399 539.404 94.2399C539.804 94.6399 540.364 94.6399 540.844 94.1599L545.724 88.6399C546.124 88.2399 546.124 87.5999 545.644 87.1999C540.364 82.7999 533.644 79.6799 526.764 79.6799C523.244 79.6799 519.804 80.3999 516.844 81.6799C516.284 81.9199 516.124 82.5599 516.444 83.0399Z"
				fill="white"
			/>
			<path
				d="M557.378 113.44C557.378 114.08 557.778 114.48 558.418 114.48H592.578C593.218 114.48 593.618 114.08 593.618 113.44V106.56C593.618 105.92 593.218 105.52 592.578 105.52H558.418C557.778 105.52 557.378 105.92 557.378 106.56V113.44ZM557.378 130.08V136.96C557.378 137.6 557.778 138 558.418 138H592.578C593.218 138 593.618 137.6 593.618 136.96V130.08C593.618 129.44 593.218 129.04 592.578 129.04H558.418C557.778 129.04 557.378 129.44 557.378 130.08ZM558.418 90.9599H592.578C593.218 90.9599 593.618 90.5599 593.618 89.9199V83.0399C593.618 82.3999 593.218 81.9999 592.578 81.9999H558.418C557.778 81.9999 557.378 82.3999 557.378 83.0399V89.9199C557.378 90.5599 557.778 90.9599 558.418 90.9599Z"
				fill="white"
			/>
		</svg>
	);
}

export default Logo;
