import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const membersSlice = createSlice({
	name: "members",
	initialState,
	reducers,
});

const { actions, reducer } = membersSlice;

export const { updateMembersErrors, updateMembersLoading, updateMembers, getGymMembersByDate } = actions;
export default reducer;
