import styled from "styled-components";

const StyledPaymentMethodCard = styled.div`
	width: 100%;

	.sb-payment-method-card {
		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&__left {
				display: flex;
				gap: 24px;
				align-items: center;

				&__icon {
					display: flex;
					align-items: center;

					svg {
						width: 40px;
						height: auto;
					}
				}

				&__data {
					display: flex;
					flex-direction: column;
					gap: 8px;

					p {
						margin: 0;
					}
				}
			}

			&__right {
				display: flex;
				gap: 16px;

				&__action {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: ${({ theme }) => theme.colors.trout};
					background-color: ${({ theme }) => theme.colors.athensGray};

					svg {
						width: 16px;
					}
				}
			}
		}
	}

	.ant-card {
		border: 1px solid ${({ theme }) => theme.colors.iron};
		border-radius: 5px;

		.ant-card-body {
			padding: 16px 20px;
		}
	}
`;

export default StyledPaymentMethodCard;
