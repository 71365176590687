import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers,
});

const { actions, reducer } = uiSlice;

export const { getImageModalOpen, updateImageModalOpen, updateImageCropAspectRatio } = actions;

export default reducer;

