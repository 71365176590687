// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React from "react";
import { Card, Alert, Progress } from "antd";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectAssetUploadProgress } from "store/selectors";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const UploadProgress = () => {
	const uploading = useSelector(selectAssetUploadProgress);

	if (uploading < 0) {
		return null;
	}

	return (
		<div>
			{uploading >= 0 && (
				<Card title="Video uploading" className="progress-card">
					<Alert message="Don't close this site or refresh the page until is done" banner />
					<Progress type="circle" percent={Math.round(uploading)} width={80} />
				</Card>
			)}
		</div>
	);
};

export default UploadProgress;
