import React from "react";

import "./index.scss";
import VideoPlayer from "../UI/VideoPlayer/VideoPlayer";

function FileAdded({ fileAdded, imageUploadedStyle, type }) {
	let styles = {};

	if (imageUploadedStyle) {
		styles = imageUploadedStyle;
	}

	if (type === "video") {
		const videoJsOptions = {
			autoplay: true,
			controls: true,
			errorDisplay: false,
			sources: [
				{
					src: fileAdded.file,
					type: fileAdded.original.type,
				},
			],
		};

		const videoPlayerStyle = {
			objectFit: "cover",
			margin: "0 auto",
			height: 200,
			width: "100%",
		};

		return <VideoPlayer styleConfig={videoPlayerStyle} options={videoJsOptions} />;
	} else {
		return <img style={styles} className="image-uploader-file-added" src={fileAdded} alt="upload" />;
	}
}

export default FileAdded;
