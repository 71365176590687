import React from "react";

const DownloadIcon = (params) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M15.8337 15.4167V16.25C15.8337 16.3605 15.7898 16.4665 15.7116 16.5446C15.6335 16.6228 15.5275 16.6667 15.417 16.6667H4.58366C4.47315 16.6667 4.36717 16.6228 4.28903 16.5446C4.21089 16.4665 4.16699 16.3605 4.16699 16.25V15.4167C4.16699 15.3062 4.21089 15.2002 4.28903 15.122C4.36717 15.0439 4.47315 15 4.58366 15H15.417C15.5275 15 15.6335 15.0439 15.7116 15.122C15.7898 15.2002 15.8337 15.3062 15.8337 15.4167ZM9.70866 13.2083C9.74607 13.2482 9.79125 13.28 9.84141 13.3017C9.89158 13.3234 9.94566 13.3346 10.0003 13.3346C10.055 13.3346 10.1091 13.3234 10.1592 13.3017C10.2094 13.28 10.2546 13.2482 10.292 13.2083L15.117 8.38333C15.156 8.3446 15.187 8.29851 15.2082 8.24774C15.2294 8.19697 15.2402 8.1425 15.2402 8.0875C15.2402 8.0325 15.2294 7.97803 15.2082 7.92726C15.187 7.87648 15.156 7.8304 15.117 7.79167L14.9503 7.625C14.9115 7.58474 14.8649 7.55287 14.8133 7.53137C14.7617 7.50987 14.7062 7.49919 14.6503 7.5H12.5003V2.91667C12.5003 2.80616 12.4564 2.70018 12.3783 2.62204C12.3001 2.5439 12.1942 2.5 12.0837 2.5H7.91699C7.80649 2.5 7.7005 2.5439 7.62236 2.62204C7.54422 2.70018 7.50033 2.80616 7.50033 2.91667V7.5H5.35033C5.29443 7.49919 5.23896 7.50987 5.18735 7.53137C5.13575 7.55287 5.08911 7.58474 5.05033 7.625L4.88366 7.79167C4.84461 7.8304 4.81361 7.87648 4.79245 7.92726C4.7713 7.97803 4.76041 8.0325 4.76041 8.0875C4.76041 8.1425 4.7713 8.19697 4.79245 8.24774C4.81361 8.29851 4.84461 8.3446 4.88366 8.38333L9.70866 13.2083Z"
			fill="currentColor"
		/>
	</svg>
);

export default DownloadIcon;
