import React from "react";
import checkIcon from "../../../images/check-icon-white.svg";

function LandingPagePreview({ type, gym, pageData, aspectRatio }) {
	const checklistTexts = [
		"On Demand Workouts",
		"Live Workouts",
		"Access workouts from any device on the SWEATBASE App",
	];

	const ChecklistItem = ({ text }) => {
		return (
			<div style={{ display: "flex", alignItems: "center", gap: "6px", padding: "4px", minHeight: "18px" }}>
				<div
					style={{
						backgroundColor: gym?.brand_color1 ? gym?.brand_color1 : "black",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						borderRadius: "100px",
						minWidth: "16px",
						width: "16px",
						height: "16px",
					}}
				>
					<img src={checkIcon} alt="check" style={{ fontSize: "6px", maxWidth: "8px" }} />
				</div>
				<span style={{ fontSize: "6px", color: "white" }}>{text}</span>
			</div>
		);
	};

	const renderChecklist = () => {
		return checklistTexts.map((text, i) => <ChecklistItem key={i} text={text} />);
	};

	return (
		<div
			style={{
				position: "relative" /* This makes the child absolute positioning relative to this container */,
				width: "100%" /* Adjust as necessary */,
				height: "60%",
				backgroundImage: `url(${pageData?.header_image})` /* Your background image */,
				backgroundRepeat: "no-repeat",
				backgroundColor: pageData?.header_image ? undefined : "#1d2023",
				backgroundSize: "cover",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: "0",
					left: "0",
					width: "100%",
					height: "100%",
					pointerEvents: "none",
				}}
			></div>
			<div
				style={{
					position: "relative" /* Ensures it is above the overlay */,
					zIndex: "1" /* Higher than the overlay's z-index */,
					color: "white" /* Choose text color that contrasts well with the dark background */,
					padding: "20px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							alignSelf: "flex-end",
							padding: "4px",
							paddingRight: "8px",
						}}
					>
						<span style={{ color: "white", fontSize: "8px" }}>LOGIN</span>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "start",
								padding: "4px",
								color: "white",
							}}
						>
							<span style={{ fontSize: "18px", fontWeight: 700 }}>{pageData?.header_text}</span>
							<span style={{ fontSize: "10px" }}>{pageData?.sub_text}</span>
						</div>
						<div
							style={{
								backgroundColor: "#1d2023",
								border: "1px solid #1d2023",
								boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
								borderRadius: "5px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								padding: "8px",
								maxWidth: "180px",
							}}
						>
							<div>
								<h1
									className="sb-landing__header__inner__body__right__card__head__title"
									style={{ fontSize: "10px", fontWeight: 700 }}
								>
									Membership
								</h1>
								<p
									className="sb-landing__header__inner__body__right__card__head__subtitle"
									style={{ fontSize: "8px", paddingRight: "8px", paddingLeft: "8px", marginBottom: "0px" }}
								>
									Access our digital content powered by popular music to keep you sweating from home, when traveling, or
									when you just don't have time for class.
								</p>
							</div>
							<div style={{ height: ".25px", width: "100%", margin: "6px", backgroundColor: "#383838" }}></div>

							<div
								style={{
									backgroundColor: gym?.brand_color1 ? gym?.brand_color1 : "black",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									padding: "4px",
									borderRadius: "100px",
									width: "90%",
								}}
							>
								<span style={{ color: "white", fontSize: "8px", fontWeight: 700 }}>LETS GET STARTED</span>
							</div>
							<div style={{ padding: "4px" }}>{renderChecklist()}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LandingPagePreview;
