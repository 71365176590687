import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const domainGymSlice = createSlice({
	name: "domainGym",
	initialState,
	reducers,
});

const { actions, reducer } = domainGymSlice;

export const { updateDomainGym, getDomainGym, saveDomainGym, updateDomainGymErrors, updateDomainWorkouts } = actions;
export default reducer;
