import { DiamondIcon, FlashIcon, CrossfitIcon, HotIcon } from "components/Icons";

export const paymentLevels = {
	"SWEATBASE STARTER": {
		icon: <HotIcon />,
		title: "Starter",
		description:
			"A revenue sharing option for startups and creators. Keep sixty percent of all revenues and we will handle all of the music rights for your content.",
		priceDescription: "Free Up to 1,000 subscribers",
		price: "Free",
		share: 0.6,
		freeSubs: 1000,
	},
	"SWEATBASE PRO": {
		icon: <CrossfitIcon />,
		title: "Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 100 subscribers per month.",
		priceDescription: "Up to 100 subscribers",
		price: "$99.99",
		share: 0.85,
		freeSubs: 100,
		priceFrequency: "month",
	},
	"SWEATBASE PRO STUDIO": {
		icon: <FlashIcon />,
		title: "Pro Studio",
		description: "Offer digital workouts for free or charge your members. Good for up to 300 subscribers per month.",
		priceDescription: "Up to 300 subscribers",
		price: "$299.99",
		share: 0.9,
		freeSubs: 300,
	},
};

export const paymentPlans = {
	starter: {
		icon: <HotIcon />,
		title: "Starter",
		description:
			"A revenue sharing option for startups and creators. Keep sixty percent of all revenues and we will handle all of the music rights for your content.",
		priceDescription: "Free Up to 1,000 subscribers",
		price: "Free",
		share: 0.6,
		freeSubs: 1000,
		featuresList: [
			"Min user price of $9.99/mo",
			"On Demand Workouts",
			"Live Workouts",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"Keep 60% of Sales",
		],
		productName: "SWEATBASE STARTER",
		minMembersPrice: 9.99,
	},
	pro: {
		icon: <CrossfitIcon />,
		title: "Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 100 subscribers per month.",
		priceDescription: "Up to 100 subscribers",
		price: "$99.99",
		share: 0.85,
		freeSubs: 100,
		priceFrequency: "month",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"3000 song plays included",
			"Additional plays at $0.03 per song play",
			"Keep 85% of Sales",
		],
		productName: "SWEATBASE PRO",
		minMembersPrice: 0,
	},
	prostudio: {
		icon: <FlashIcon />,
		title: "Studio Pro",
		description: "Offer digital workouts for free or charge your members. Good for up to 300 subscribers per month.",
		priceDescription: "Up to 300 subscribers",
		price: "$299.99",
		share: 0.9,
		freeSubs: 300,
		priceFrequency: "month",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"10,000 song plays included",
			"Additional plays at $0.03 per song play",
			"Access to PRO Playlists",
			"Keep 90% of Sales",
		],
		productName: "SWEATBASE PRO STUDIO",
		minMembersPrice: 0,
	},
	elite: {
		icon: <DiamondIcon />,
		title: "Elite",
		description:
			"Our highest level of service for 300+ subscribers per month or brands who wish to integrate SWEATBASE APIs into their native application.",
		priceDescription: "For a customized plan",
		price: "Contact Us",
		featuresList: [
			"You determine the price",
			"On Demand + Live",
			"Subscriber Analytics",
			"Cross Platform Support",
			"Vanity URL",
			"Access to PRO Playlists",
			"Unlimited plays",
			"API Integration",
		],
		minMembersPrice: 0,
	},
};

export const getApiHost = () => {
	return process.env.REACT_APP_API;
};

// We should not be doing this, we will remove since there's another PR that fixes some other parts that use this.
export const getClientHost = () => {
	return window.location.origin;
};

const s3BucketName = "sb2-sweatbase-production";

export const faqImages = [
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/livestreamStep1.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/livestream2.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/livestream3.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/livestream4.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/livestream5.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/obs1.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/obsSettings.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/obsStreamTab.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/obsCustom.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/obsServerKey.png`,
	`https://s3.amazonaws.com/${s3BucketName}/assets/FAQ/obsStartStream.png`,
];

export const maxImageAssetSize = 3 * 1024 * 1024; // 3MB

export const imageSizeLimitError = {
	message: "Max Image Size is 3MB",
	description: "Limit the size of your image",
};

export const defaultTimezone = "America/New_York";

export const durationOptions = [
	{
		value: 30 * 60,
		id: 30,
		label: "30 Minutes",
	},
	{
		value: 45 * 60,
		id: 45,
		label: "45 Minutes",
	},
	{
		value: 60 * 60,
		id: 60,
		label: "60 Minutes",
	},
	{
		value: 90 * 60,
		id: 90,
		label: "90 Minutes",
	},
	{
		value: 120 * 60,
		id: 120,
		label: "120 Minutes",
	},
];
