import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import "./styles.scss";

const OfflineBanner = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	function setOffline() {
		// Function to update the online status
		const updateOnlineStatus = () => {
			setIsOnline(navigator.onLine);
			document.body.style.pointerEvents = navigator.onLine ? "auto" : "none";
			const dialogs = document.getElementsByClassName("ant-modal-content");
			for (let item of dialogs) {
				item.style.pointerEvents = navigator.onLine ? "auto" : "none";
			}
		};

		// Add event listeners for online and offline events
		window.addEventListener("online", updateOnlineStatus);
		window.addEventListener("offline", updateOnlineStatus);

		// Cleanup the event listeners when the component unmounts
		return () => {
			window.removeEventListener("online", updateOnlineStatus);
			window.removeEventListener("offline", updateOnlineStatus);
		};
	}

	useEffect(() => {
		const offline = setOffline();
		return offline;
	}, []);

	if (isOnline) {
		return null;
	}

	return (
		<Alert
			type="error"
			message="You are currently offline. Some features may not be available."
			className="error-alert"
			banner
			style={{ position: "fixed", top: 0, zIndex: 999999, left: 0, width: "100%" }}
			showIcon={false}
		/>
	);
};

export default OfflineBanner;

