import React from "react";

const CloseIcon = (params) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...params}>
		<path
			d="M17.8499 16.4401C17.8967 16.4866 17.9339 16.5419 17.9593 16.6028C17.9847 16.6637 17.9978 16.7291 17.9978 16.7951C17.9978 16.8611 17.9847 16.9265 17.9593 16.9874C17.9339 17.0483 17.8967 17.1036 17.8499 17.1501L17.1499 17.8501C17.1034 17.897 17.0481 17.9342 16.9871 17.9595C16.9262 17.9849 16.8609 17.998 16.7949 17.998C16.7288 17.998 16.6635 17.9849 16.6026 17.9595C16.5416 17.9342 16.4863 17.897 16.4399 17.8501L11.9999 13.4101L7.55985 17.8501C7.51337 17.897 7.45807 17.9342 7.39714 17.9595C7.33621 17.9849 7.27086 17.998 7.20485 17.998C7.13885 17.998 7.07349 17.9849 7.01256 17.9595C6.95163 17.9342 6.89633 17.897 6.84985 17.8501L6.14985 17.1501C6.10299 17.1036 6.06579 17.0483 6.04041 16.9874C6.01502 16.9265 6.00195 16.8611 6.00195 16.7951C6.00195 16.7291 6.01502 16.6637 6.04041 16.6028C6.06579 16.5419 6.10299 16.4866 6.14985 16.4401L10.5899 12.0001L6.14985 7.5601C6.10299 7.51361 6.06579 7.45831 6.04041 7.39738C6.01502 7.33645 6.00195 7.2711 6.00195 7.2051C6.00195 7.13909 6.01502 7.07374 6.04041 7.01281C6.06579 6.95188 6.10299 6.89658 6.14985 6.8501L6.84985 6.1501C6.89633 6.10323 6.95163 6.06604 7.01256 6.04065C7.07349 6.01527 7.13885 6.0022 7.20485 6.0022C7.27086 6.0022 7.33621 6.01527 7.39714 6.04065C7.45807 6.06604 7.51337 6.10323 7.55985 6.1501L11.9999 10.5901L16.4399 6.1501C16.4863 6.10323 16.5416 6.06604 16.6026 6.04065C16.6635 6.01527 16.7288 6.0022 16.7949 6.0022C16.8609 6.0022 16.9262 6.01527 16.9871 6.04065C17.0481 6.06604 17.1034 6.10323 17.1499 6.1501L17.8499 6.8501C17.8967 6.89658 17.9339 6.95188 17.9593 7.01281C17.9847 7.07374 17.9978 7.13909 17.9978 7.2051C17.9978 7.2711 17.9847 7.33645 17.9593 7.39738C17.9339 7.45831 17.8967 7.51361 17.8499 7.5601L13.4099 12.0001L17.8499 16.4401Z"
			fill="currentColor"
		/>
	</svg>
);

export default CloseIcon;
