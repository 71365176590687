import { createSlice } from "@reduxjs/toolkit";
import * as reducers from "store/reducers";
import initialState from "./initialState";

const filtersSlice = createSlice({
	name: "filters",
	initialState,
	reducers,
});

const { actions, reducer } = filtersSlice;

export const { updateMembersWorkoutsSelectedCategories, updateMembersWorkoutsSearchTerm } = actions;
export default reducer;
