// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// -------------------------------------------------------------------------------
// Component and others
// -------------------------------------------------------------------------------
import { Paragraph2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledPaymentIntentCard from "./styles";
import { Button } from "components/Common/Button";
import { CheckIcon } from "components/Icons";
import { paymentPlans } from "helpers/constants";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const PaymentIntentCard = ({ icon, className, plan, freeSubs, planDetails, pricing, ...other }) => {
	const { gym } = useParams();
	const navigate = useNavigate();
	const features = paymentPlans[planDetails.title.replace(/\s+/g, "").toLowerCase()].featuresList;
	return (
		<StyledPaymentIntentCard className={`${className} sb-payment-intent-card`} {...other}>
			<div className="sb-payment-intent-card__plan">
				<div className="sb-payment-intent-card__plan__left">
					<div className="sb-payment-intent-card__plan__left__icon">{icon}</div>
					<div className="sb-payment-intent-card__plan__left__title">{plan}</div>
					<div className="sb-payment-intent-card__plan__left__subtitle">Up to {freeSubs} subscribers</div>
					<div className="sb-payment-intent-card__plan__left__disclaimer">*Overages apply</div>
					<Button
						className="primary-outlined"
						onClick={() => navigate(`/${gym}/dashboard/select-plan`)}
						style={{ width: "50%", marginRight: "24px", marginLeft: "24px", marginBottom: "24px" }}
					>
						<Paragraph2>Change Plan</Paragraph2>
					</Button>
				</div>

				<div className="sb-payment-intent-card__inner">
					<div className="sb-payment-intent-card__inner__header">
						<div className="sb-payment-intent-card__inner__header__title">Plan Features</div>
						<div className="sb-payment-intent-card__inner__header__price">
							<div className="sb-payment-intent-card__inner__header__price">${pricing}</div>
							<div className="sb-payment-intent-card__inner__header__price__sub">/month</div>
						</div>
					</div>
					<div className="sb-payment-intent-card__inner__header__divider" />
					<div className="sb-payment-intent-card__inner__header__info">
						{features.map((item, index) => {
							return (
								<div key={index} className="sb-payment-intent-card__inner__header__info__row">
									<div className="sb-payment-intent-card__inner__header__info__row__title">
										<div
											style={{
												display: "flex",
												padding: 5,
												width: "20px",
												height: "20px",
												borderRadius: "50%",
												background: "rgba(255, 134, 92, .2)",
												alignItems: "center",
												justifyContent: "center",
												marginRight: "8px",
											}}
										>
											<CheckIcon style={{ color: "#FF865C" }} />
										</div>
										{item}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</StyledPaymentIntentCard>
	);
};

export default PaymentIntentCard;

