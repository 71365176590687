// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useCallback } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledSearchInput from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const SearchInput = ({ placeholder, onChange, onEnter }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchTermChange = useCallback(
		(event) => {
			setSearchTerm(event?.target?.value || "");

			if (typeof onChange === "function") {
				onChange(event?.target?.value || "");
			}
		},
		[onChange]
	);

	const handleEnterKey = useCallback(() => {
		onEnter(searchTerm);
	}, [onEnter, searchTerm]);

	return (
		<StyledSearchInput className="sb-search-input">
			<Input
				className="sb-search-input__input"
				placeholder={placeholder}
				value={searchTerm}
				onChange={handleSearchTermChange}
				onPressEnter={handleEnterKey}
				prefix={<SearchOutlined />}
			/>
		</StyledSearchInput>
	);
};

export default SearchInput;

