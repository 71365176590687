import styled from "styled-components";

const StyledDeleteUserModal = styled.div`
	.sb-delete-user-modal {
		&__text {
			margin-bottom: 32px;
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 16px;
		}
	}
`;

export default StyledDeleteUserModal;
