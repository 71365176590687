// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import React from "react";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMainLayout from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MainLayout = ({ children, actionsSection }) => {
	return (
		<StyledMainLayout className="sb-main-layout">
			<Header actionsSection={actionsSection} />
			{children}
			<Footer />
		</StyledMainLayout>
	);
};

export default MainLayout;
