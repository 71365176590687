import React from "react";
import ValidationError from "../ValidationError";
import { Input } from "antd";

import "./index.scss";
import countries from "./countries";

const options = countries.map((country) => (
	<option key={country.code} value={country.dial_code}>
		{country.flag} {country.code} {country.dial_code}
	</option>
));

const { Password } = Input;
function InputField({
	input,
	meta,
	value: inputValue,
	type,
	textarea,
	placeholder,
	disabled,
	style,
	onKeyUp,
	phone,
	...others
}) {
	const value = (input?.value === "undefined" || !input?.value ? "" : input.value) || inputValue;
	if (type === "password") {
		return (
			<div className="input-field-wrapper">
				<Password
					onKeyUp={onKeyUp}
					disabled={disabled}
					placeholder={placeholder}
					className="input-field"
					value={value}
					{...input}
					{...others}
				/>
				<ValidationError meta={meta} />
			</div>
		);
	}
	return (
		<div className="input-field-wrapper">
			{textarea ? (
				<textarea
					onKeyUp={onKeyUp}
					disabled={disabled}
					placeholder={placeholder}
					className="input-field text-area"
					value={value}
					{...input}
					{...others}
				/>
			) : phone ? (
				<div style={{ display: "flex", gap: "8px" }}>
					<select
						defaultValue="+1"
						style={{
							height: "46px",
							borderRadius: "100px",
							background: "#ffffff",
							boxSizing: "border-box",
							border: "1px solid #e3e5e7",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: "8px",
							margin: "0",
						}}
					>
						{options}
					</select>
					<input
						onKeyUp={onKeyUp}
						disabled={disabled}
						placeholder={placeholder}
						className="input-field"
						type={type}
						value={value}
						{...input}
						{...others}
					/>
				</div>
			) : (
				<input
					onKeyUp={onKeyUp}
					disabled={disabled}
					placeholder={placeholder}
					className="input-field"
					type={type}
					value={value}
					{...input}
					{...others}
				/>
			)}

			<ValidationError meta={meta} />
		</div>
	);
}

export default InputField;
